/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount(".with-hidden-options", function () {
  const $container = $(this)

  const functions = {
    updateRadio() {
      const $moreOptions = $(this).closest("label.radio").next()
      const { checked } = $(this)[0]

      $container.children(".card").slideUp()

      if (checked) {
        return $moreOptions.slideDown()
      }
    },

    updateCheckbox() {
      const $moreOptions = $(this).closest("label.checkbox").next()
      const { checked } = $(this)[0]

      $container.children(".card").slideUp()

      if (checked) {
        return $moreOptions.slideDown()
      }
    },

    setRadio() {
      const $moreOptions = $(this).closest("label.radio").next()
      const { checked } = $(this)[0]

      $container.children(".card").hide()

      if (checked) {
        return $moreOptions.show()
      }
    },

    setCheckbox() {
      const $moreOptions = $(this).closest("label.checkbox").next()
      const { checked } = $(this)[0]

      $container.children(".card").hide()

      if (checked) {
        return $moreOptions.show()
      }
    },
  }

  $container.children("label.radio").children("input[type=radio]:checked").each(functions.setRadio)
  $container
    .children("label.radio")
    .children("input[type=radio]")
    .on("change", functions.updateRadio)

  $container
    .children("label.checkbox")
    .children("input[type=checkbox]:checked")
    .each(functions.setCheckbox)
  return $container
    .children("label.checkbox")
    .children("input[type=checkbox]")
    .on("change", functions.updateCheckbox)
})
