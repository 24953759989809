/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount("#profile-tabs .is-sortable", function () {
  const $sortable = $(this)
  return $sortable.sortable({
    update() {
      const order = $sortable.sortable("toArray", { attribute: "data-sort" })

      const options = {
        url: $sortable.parent().data("pjax-url"),
        container: "#profile-tabs",
        fragment: "#profile-tabs",
        push: false,
        scrollTo: false,
        data: {
          order,
        },
        type: "PUT",
      }

      $.pjax(options)
      return ($.pjax.xhr = null)
    },
  })
})
