/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount(Modal.selector("assign"), function () {
  return $(this).on("modal:save", function (_e, modal) {
    const $upperModalLink = $("#confirm-assign-deletion")
    if ($upperModalLink.length === 0) {
      return modal.close()
    } else {
      modal.$el.find("[data-action=close]").click(modal.clickEvent)
      return $upperModalLink.trigger("click")
    }
  })
})
