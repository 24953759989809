const $ = window.jQuery
const Modal = window.Modal
const Pjax = window.Pjax

$.onmount(Modal.selector("company-documents-browse"), function storeCDL() {
  $("[data-action=upload-document]").each(function uploadCD() {
    $(this).change(() => {
      $(this).closest("form").submit()
      $(this).trigger("personDocument:uploaded")
    })
    $(this).on("personDocument:uploaded", () => window.currentModal.close())
  })
})
