/* global $ App NProgress */

/**
This file varies from the v2-stubs version in a number of ways. Here, org chart
data is not referenced. Noteworthy differences have been noted in the comments
in this file, but other subtle differences may exist.
*/

import { createElement } from "react"
import { createRoot } from "react-dom/client"

import { UrlHelper } from "v2/react/utils/urls"

import { PanelSidebar } from "./panel_sidebar"

$(document).ready(initAppPreferences)

function initAppPreferences() {
  window.SuccPlanPanelApp = {
    endpoint: function (path) {
      path = _.isArray(path) ? path.join("/") : path

      return "/api/app/v1/" + path + ".json"
    },

    Preferences: {
      update: function (preferences) {
        return $.ajax({
          contentType: "application/json",
          data: JSON.stringify({ settings: preferences }),
          dataType: "json",
          type: "PUT",
          url: App.endpoint(["people", window.gon.current_person_id, "settings"]),
        })
      },
    },
  }
}

function enableNProgress() {
  $(document).on("pjax:send.nprogress", NProgress.start)
}

function onProfilePanelLinkClicked(e) {
  e.preventDefault()

  const personId = $(this).data("person-id")
  if (personId) {
    window.location.href = UrlHelper.personProfilePath(personId)
  }
}

$.onmount(".succession-plan-panel", function initSuccessionPlanPanel() {
  $(".succession-plan-panel").on("pjax:complete", enableNProgress)
})

$.onmount(
  ".succession-compare",
  // This is different than in the orgchart/v2-stubs js. Several action and
  // behaviors are specific to the org chart.
  function initSuccessionPlanSummary() {
    $("[data-action=profile-link-clicked]").click(onProfilePanelLinkClicked)
  },
)

$.onmount(".succession-compare [data-action=set-hire-date]", function initSetHireActions() {
  const positionId = $(".succession-plan-panel").data("position-id")
  this.setAttribute(
    "href",
    this.href +
      "?reload=succession-plan-tab-content&reload_url=/succession_plan_panel/" +
      positionId +
      "/compare",
  )
})

$.onmount("#compare-settings .is-sortable", function initCompareSettingsSortable() {
  const $sortable = $(this)
  $sortable.sortable({
    update: function updateCompareRowOrder() {
      const order = $sortable.sortable("toArray", { attribute: "data-val" })
      $("#person_settings_succession_plan_panel_compare_rows_order").val(order)
    },
  })
})

$.onmount("#compare-settings", function initCompareSettingsModalOverlay() {
  const modalRoot = $(".modal-overlay")
  // This logic is specific to v2 views, where we need to account for opening
  // this modal in a sidebar panel, which may be overlay a prior modal. (E.g.
  // the list of plans in the Succession Plan Insights).
  modalRoot.addClass("z-above-modal-modal")
})

// This is different than in the orgchart/v2-stubs js. This is only needed in
// this v2 version, since in the v2-stubs modal.js `reloadTargetContainer`
// is used much differently than in the v2 modal.js file. See:
// app/views/v2/succession_plan_panel/modals/compare_settings.html.slim
$.onmount("[data-modal=compare-settings]", function (data) {
  const selector = (data || {}).selector
  $(selector).on("modal:save", function (e, modal) {
    modal.reloadTargetContainer()
  })
})
