const $ = window.jQuery
const Keyboard = window.Keyboard

// hiring participants
function removeParticipant(event) {
  event.currentTarget.parentElement.remove()
}

$.onmount("#job_participants", function () {
  $(this).on("autocomplete:selectSuggestion", (obj, $selected) => {
    if (!$selected.length) {
      return
    }
    const participantId = $selected.data("id")
    const participantFullName = $selected.find(".list-group-item-title").text().trim()
    const newRow = `<div class='list-group-item' id=${participantId}>
        <input name='job[job_participant_ids][]' type='hidden' value=${participantId}>
        <div>
          ${participantFullName}
          </div>
        <a class='delete' data-action="delete-participant" data-employee-id=${participantId} href="#">
          <i aria-hidden='true' class='far fa-times'></i>
        </a>
      </div>`

    $(".hiring-participants.list-group").append(newRow)
    $("#job_participants").val("")

    $(".hiring-participants.list-group")
      .find(".list-group-item:last")
      .find("a[data-action='delete-participant']")
      .on("click", removeParticipant)
  })
})

$.onmount("[data-action='delete-participant']", function () {
  $(this).click((event) => removeParticipant(event))
})

// hiring workflow
function updateStageTitle(stagePositionElement, index) {
  const newPositionTitle = stagePositionElement.text().replace(/\d+/, index + 1)

  stagePositionElement.text(newPositionTitle)
  stagePositionElement
    .parent()
    .find("input:hidden[name='job[job_stages][][stage_num]']")
    .val(index + 1)
}

function getEditButton(event, action) {
  switch (action) {
    case "new-stage":
      return $(".small-actions:last").find("a[data-action=edit-stage]")
    case "edit-stage":
      return $(event.currentTarget.offsetParent).find("a[data-action=edit-stage]")
    case "save-stage":
      return $(event.currentTarget.offsetParent).find("a[data-action=edit-stage]")
    default:
      return null
  }
}

function resolveDisplayEditButton(button, direction) {
  if (direction === "hide") {
    return button.hide()
  }

  return button.show()
}

function changeDeleteButtonAction(event, nextAction) {
  const deleteButton = $(event.currentTarget.parentElement).find("a:last")

  deleteButton.attr("data-action", nextAction)
  deleteButton.data("action", nextAction)
}

function resolveSmallActions(event, action, direction) {
  const editButton = getEditButton(event, action)
  resolveDisplayEditButton(editButton, direction)

  if (action === "edit-stage") {
    changeDeleteButtonAction(event, "close-edit-form")
  }
}

function formNewEditJobStage(previousTitle, action) {
  const title = previousTitle || ""

  return `<div class='flex gap-2 items-center'>
    <input class='input' type='text' name='title' value='${title}'>
    <button class='btn--large btn--primary' data-action=${action} type="button">Save</button>
  </div>`
}

function saveNewStage(event) {
  const formDiv = $(event.currentTarget.parentElement)
  const hiddenInput = formDiv
    .closest(".list-group-item.ui-sortable-handle")
    .find("input:hidden[name='job[job_stages][][title]']")
  const newTitle = formDiv.find("input").val()
  const editButton = getEditButton(event, "edit-stage")

  formDiv.text(newTitle)
  hiddenInput.val(newTitle)
  resolveDisplayEditButton(editButton, "show")
}

function maybeSaveNewStage(event) {
  const key = event.which
  if (Keyboard.keyMatchesName(key, "enter")) {
    event.preventDefault()
    saveNewStage(event)
  }
}

function updateJobStage(event) {
  const parentDiv = $(event.currentTarget.offsetParent)
  const stageTitleDiv = parentDiv.find(".stage-title")
  const currentTitle = parentDiv.find("input:hidden[name='job[job_stages][][title]']").val()
  const editForm = formNewEditJobStage(currentTitle, "save-new-stage")
  resolveSmallActions(event, "edit-stage", "hide")

  stageTitleDiv.html(editForm)
  saveNewStage(event)
  $(".hiring-workflow.list-group.input-group.is-draggable.ui-sortable")
    .find("button[data-action='save-new-stage']:last")
    .on("click", saveNewStage)
  $(".hiring-workflow.list-group.input-group.is-draggable.ui-sortable")
    .find("a[data-action='edit-stage']:last")
    .on("click", updateJobStage)
  $(".hiring-workflow input[name=title]").on("keydown", maybeSaveNewStage).focus()
}

function refreshStageTitles() {
  $(".list-group-item.ui-sortable-handle").each((index, stageDiv) => {
    const stagePositionElement = $(stageDiv).eq(0).find(".list-group-item-input").eq(0)

    updateStageTitle(stagePositionElement, index)
  })
}

function removeStage(event) {
  const actionType = event.currentTarget.dataset.action

  switch (actionType) {
    case "delete-stage":
      $(event.currentTarget.offsetParent).remove()
      refreshStageTitles()
      break
    case "close-edit-form": {
      const parentDiv = $(event.currentTarget.offsetParent)
      const editForm = parentDiv.find(".flex.gap-2")
      const previousTitle = editForm.find("input:last").val()
      const stageTitleDiv = parentDiv.find(".stage-title")
      const editButton = getEditButton(event, "edit-stage")

      editForm.remove()
      stageTitleDiv.html(previousTitle)
      changeDeleteButtonAction(event, "delete-stage")
      resolveDisplayEditButton(editButton, "show")
      break
    }
    default:
  }
}

$.onmount("[data-action='edit-stage']", function () {
  $(this).click((event) => {
    updateJobStage(event)
  })
})

$.onmount("[data-action='add-stage']", function () {
  $(this).click((event) => {
    const container = $(".hiring-workflow.list-group.input-group.is-draggable.ui-sortable")
    const totalStagesCount = $(".list-group-item.ui-sortable-handle").length
    const newStageForm = formNewEditJobStage(null, "save-new-stage")
    const newEmptyStageDiv = `
    <div class='list-group-item ui-sortable-handle'>
      <b class='list-group-item-input'>Stage ${totalStagesCount + 1}</b>
      <div class='stage-title'>
        ${newStageForm}
      </div>
      <div class='small-actions'>
        <a data-action="edit-stage" href='#'>
          <i class='far fa-pencil' aria-hidden='true'></i>
        </a>
        <a data-action="delete-stage" href='#'>
          <i class='far fa-times' aria-hidden='true'></i>
        </a>
      </div>
      <input name='job[job_stages][][title]' type='hidden' value='' />
      <input name='job[job_stages][][stage_num]' type='hidden' value=${totalStagesCount} />
    </div>`

    container.append(newEmptyStageDiv)

    resolveSmallActions(event, "new-stage", "hide")

    container.find("a[data-action='delete-stage']:last").on("click", removeStage)
    container.find("a[data-action='edit-stage']:last").on("click", updateJobStage)
    container.find("button[data-action='save-new-stage']:last").on("click", saveNewStage)
    $(".hiring-workflow input[name=title]").on("keydown", maybeSaveNewStage).focus()
  })
})

$.onmount("[data-action='delete-stage']", function () {
  $(this).click((event) => removeStage(event))
})

$.onmount(".hiring-workflow", () => {
  $(".hiring-workflow").sortable({
    stop() {
      refreshStageTitles()
    },
  })
})
