const $ = window.jQuery
const Modal = window.Modal
const Pjax = window.Pjax

$.onmount(Modal.selector("person-send-invitation"), ({ selector }) => {
  // The invitation modal is used in at least 3 places:
  // 1) The org chart, some JS will reload the org chart
  //    See: app/assets/javascripts/application/org_chart/views/profile_panel.js
  // 2) The V2 profile page, where we want to go all the way through this
  //    specific logic.
  // 3) The profile panel, which may be on any given page and should handle
  //    its own reload.
  //    See: webpack/v2/react/components/orgChart/ProfilePanel/PersonHeader.tsx
  const profilePanelContainer = $(".react-profile-panel")
  if (profilePanelContainer && profilePanelContainer.length > 0) return

  $(selector).on("modal:save", (_e, modal) => {
    modal.reloadTargetContainer()
    if (modal.data.modalAfterUrl) {
      $.pjax({
        url: modal.data.modalAfterUrl,
        container: Pjax.targetContainer("modal-content"),
        fragment: Pjax.targetContainer("modal-content"),
        push: false,
        scrollTo: false,
      })
      $.pjax.xhr = null
    }
  })
})
