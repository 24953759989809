/* eslint-disable no-use-before-define */

import parseLabel from "./parse_label"

const roundNum = (num) => Math.round((num + Number.EPSILON) * 100) / 100

const { $ } = window
/**
 * Populates the tooltip with content
 * @param {*} selection d3 selection of the tooltip div
 * @param {*} data data used for populating the tooltip
 * @param {*} color color scheme from bar chart
 */
function positionTrendTooltipContent(selection, data, color) {
  const { categories, datum, labels } = data

  // Process data
  const tooltipData = categories.map((d) => {
    const label = labels ? labels[d] : d
    return [label, datum[d]]
  })
  const totalData = ["Total".t("dashboard"), { total: datum.total }]

  const formatMetric = (d) => {
    if (datum.secondary_number === "percent") {
      return `${roundNum(d.total)} <span style='color:#adadad'>(${(
        (d.total / datum.total) *
        100.0
      ).toFixed(0)}%)</span>`
    }
    return roundNum(d.total)
  }

  // Styles
  const infoLeftPadding = "0.75rem"

  // Add header date
  selection.select("#header-date").text(datum.full_date)

  // Handle total row
  const totalRow = selection.select("#total-row")

  totalRow.select(".row-label").text(totalData[0])
  totalRow
    .select(".cat-info")
    .style("text-align", "right")
    .style("padding-left", infoLeftPadding)
    .html(formatMetric(totalData[1]))

  // Handle the categories
  const tooltipList = selection.select(".widget-tooltip-list")

  const rows = tooltipList
    .selectAll("div")
    .data(tooltipData, (d) => d[0])
    .join("div")
    .attr("class", "list-row flex grow")
    .style("align-self", "stretch")
    .style("align-items", "center")
    .attr("id", (d) => `${d[0]}`)

  rows
    .selectAll("div")
    .data((d) => d)
    .join("div")
    .attr("class", (_, i) => (i === 0 ? "row-label flex grow" : "cat-info grow items-end"))

  // Handle category rows
  const catRows = tooltipList.selectAll(".list-row:not(#total-row)")

  catRows
    .selectAll(".row-label")
    .style("align-items", "center")
    .selectAll("div")
    .data((d) => [d, d])
    .join("div")
    .attr("class", (_, i) => (i === 0 ? "widget-legend-item-color" : "cat-text"))

  catRows.selectAll(".widget-legend-item-color").style("background-color", (d) => {
    const colorKey = labels ? Object.keys(labels).find((key) => labels[key] === d) : d
    return color(colorKey)
  })

  catRows.selectAll(".cat-text").text((d) => (labels ? d : parseLabel(d)))

  catRows
    .selectAll(".cat-info")
    .style("text-align", "right")
    .style("padding-left", infoLeftPadding)
    .html((d) => formatMetric(d))

  // Handle wrapping
  const isWrap = detectWrap(rows)
  if (isWrap) {
    // Specific tooltip list selection
    const listSelection = $(`#${selection.attr("id")} > .widget-tooltip-list`)
    // Resize tootlip div if there's wrapping
    listSelection.each(function resizeTooltip() {
      const lastChild = $(this).children().last()
      const newWidth =
        lastChild.position().left - $(this).position().left + lastChild.outerWidth(true)
      $(`#${selection.attr("id")}`).width(newWidth)
    })
    // Get size of first list row
    const { width } = tooltipList.select(".list-row").node().getBoundingClientRect()
    // Resize the total row so it matches with first item, thus looking like it's in the same column
    totalRow.style("max-width", `${width}px`)
  } else {
    $(`#${selection.attr("id")}`).width("auto")
  }
}

// Adapted from: https://stackoverflow.com/questions/40012428/how-to-detect-css-flex-wrap-event
const detectWrap = (selection) => {
  let prevItem = {}
  let currItem = {}
  const items = selection.nodes()

  for (let i = 0; i < items.length; i += 1) {
    currItem = items[i].getBoundingClientRect()
    if (prevItem && prevItem.top > currItem.top) {
      return true
    }
    prevItem = currItem
  }
  return false
}

export default positionTrendTooltipContent
