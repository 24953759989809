const { $ } = window

/**
 * Component that represents a single job requisition opening as a connection.
 *
 * Users can interact with this to connect the opening with an existing
 * position, connect the opening with a new position (that they create), or
 * disconnect the opening from a position.
 */
function EditJobRequisitionConnectionFields() {
  const $component = $(this)
  const $newPositionForm = $component.find(".new-position-form")
  const $positionAutocomplete = $component.find("[data-part=position_label]")
  const newPositionLabel = $component.data("newPositionLabel")

  // Reset the form for creating a new position. This is useful if the user
  // changes course, and decides to find an existing position instead.
  const resetNewPositionForm = () => {
    $newPositionForm.removeClass("is-enabled").find("[data-is-new-position-enabled]").val(false)

    $newPositionForm.find("input").val("")
  }

  // Displays a hidden form for creating a new position, blurring the
  // autocomplete and focusing the position title field.
  const handleAddPositionClick = (ev) => {
    ev.preventDefault()
    $component.find(".autocomplete-container").css("display", "none")
    $component.find("input[data-part=position_label]").blur()

    // Flag that the new position form should be used.
    $newPositionForm.addClass("is-enabled").find("[data-is-new-position-enabled]").val(true)

    // Snapshot the value of the position label so we can set the initial value
    // of the new position's title. After, changes the value into a label for
    // the new position form.
    const initialTitle = $positionAutocomplete.val()
    $positionAutocomplete.val(newPositionLabel)

    // Clears the position id since we're creating a new position.
    $component.find("input[data-part=position_id]").val("")

    // Set the initial title of the new position and focus the field.
    const $titleInput = $newPositionForm.find("[data-part=new_position_title]")
    $titleInput.val(initialTitle)
    $titleInput.focus()
  }

  // Clears the position id if the user empties out the autocomplete field. This
  // supports the user disconnecting an opening that was connected previously.
  const clearPositionIdIfEmpty = () => {
    if ($component.find("[data-part=position_label]").val() !== "") return

    $component.find("[data-part=position_id]").val("")
  }

  // Clears/hides the new position form if the user starts searching for an
  // existing position.
  const maybeResetNewPositionForm = () => {
    const shown = $component.find("input[data-is-new-position-enabled]").val()
    if (!shown) return

    const val = $component.find("input[data-part=position_label]").val()
    if (val.trim() !== newPositionLabel) {
      resetNewPositionForm()
    }
  }

  $component
    .on("change", "input[data-part=position_label]", clearPositionIdIfEmpty)
    .on("keyup", "input[data-part=position_label]", maybeResetNewPositionForm)
    .on("mousedown", "#show-add-new-position-form", handleAddPositionClick)
}

$.onmount(
  "[data-use-component=EditJobRequisitionConnectionFields]",
  EditJobRequisitionConnectionFields,
)
