/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
class ButtonHelpers {
  static getElementType(element) {
    const buttonObject = {}
    element = (() => {
      switch (true) {
        case this.lastTargetElement.element instanceof jQuery:
          return this.lastTargetElement.element
        case element.tagName != null:
          return $(element)
        case element instanceof jQuery:
          return element
        case typeof element.target === "object" && element.target != null:
          return $(element.target)
      }
    })()

    buttonObject[element.prop("tagName")] = element

    return buttonObject
  }

  static getObjectAndAccessor(element) {
    element = this.getElementType(element)
    switch (true) {
      case typeof element.INPUT === "object":
        return {
          element: element.INPUT,
          accessor: "val",
        }
      case typeof element.BUTTON === "object":
        return {
          element: element.BUTTON,
          accessor: "text",
        }
    }
  }

  static setAllButtonsState(target, state) {
    this.allButtons = target.element.closest("form").find(".btn--large")
    if (state === "disabled") {
      this.allButtons.prop("disabled", true)
      return this.allButtons.addClass("btn-disabled")
    } else if (state === "enabled") {
      this.allButtons.prop("disabled", false)
      return this.allButtons.removeClass("btn-disabled")
    }
  }

  static memoizeLastTargetElement(target) {
    this.lastTargetElement = {}
    this.lastTargetElement.element = target.element
    return (this.lastTargetElement.enabledLabel = target.element[target.accessor]())
  }

  static getTargetElementText(state) {
    if (state === "enabled" && this.lastTargetElement.enabledLabel) {
      return this.lastTargetElement.enabledLabel
    } else {
      let action = this.lastTargetElement.element.attr("data-action")
      if (!action) {
        action = this.lastTargetElement.element.attr("value").toLowerCase()
      }
      if (!action) {
        return ""
      }
      const key = action + "_" + state
      return key.t("button_defaults")
    }
  }

  static setTargetElementText(target, state) {
    if (state === "disabled") {
      this.memoizeLastTargetElement(target)
    }

    return target.element[target.accessor](this.getTargetElementText(state))
  }

  // V2 PJAX modals track last clicked submit tag
  static findClickedButton($form) {
    const name = $form.find("input[name=submit_button]").val()
    if (!name || !(name.length > 0)) {
      return ""
    }
    return $form.find(`button[type=submit][name=${name}]`)
  }

  // If the target element is not a button or a submit element,
  // then find the button or submit element on the form and return it.
  static makeButtonOrSubmit(element) {
    let $element = element

    if (!(element instanceof jQuery)) {
      $element = $(element)
    }

    const type = $element.prop("type")

    if (type != null && (type === "submit" || type === "button")) {
      return element
    }

    const $form = $element.closest("form")
    if ($form != null) {
      let button = this.findClickedButton($form)
      if (!button.length) {
        button = $form.find(":submit")
      }
      if (!button.length) {
        button = $form.find(":button:not(.tox-tbtn)")
      }
      if (button != null) {
        return button[0]
      }
    }

    Sentry.captureException(new Error("Unable to find a button or a submit element on the form."))

    return element
  }

  // Sets state for given target. A target can be HTML tag type of input or
  // button given as a jQuery object, Javascript Event object or a Javascript
  // EventTarget.
  // Two valid states for target are 'disabled' and 'enabled'.
  // On 'disable' the target is memorized so the corresponding 'enable' call
  // does not require a target. The text for target element is determined with
  // the key combination of attribute 'data-action' + '_disabled'.
  // See 'button_defaults' scope in en.js
  //
  // Workflow:
  // 1.) this.setButtonState(myTargetElement, 'disable')
  //     -> All elements with 'button' class are disabled, text of target
  //        element is updated
  // 2.) this.setButtonState({}, 'enabled')
  //     -> All elements with 'button' class are enabled, text of target
  //       element is restored to initial value
  static setButtonState(target, state) {
    // Check if we already re-enabled all buttons after memoizing a disable
    if (!$.isEmptyObject(target) || !$.isEmptyObject(this.lastTargetElement)) {
      if (state === "disabled") {
        this.lastTargetElement = {}
      }
      target = this.makeButtonOrSubmit(target)
      if (!target) {
        return
      }
      target = this.getObjectAndAccessor(target)
      this.setTargetElementText(target, state)
      return this.setAllButtonsState(target, state)
    }
  }
}

window.ButtonHelpers = ButtonHelpers
