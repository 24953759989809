/* eslint-disable
  no-param-reassign
*/
import { path as d3Path, select } from "d3"
import { sankey as d3Sankey } from "d3-sankey"

const $ = window.jQuery

const sankeyChart = ({ container, data, width, height, useAnimation = true }) => {
  const firstNodeHeight = 117

  select(container).select("svg").remove()
  const svg = select(container)
    .append("svg")
    .attr("width", width)
    .attr("height", height)
    .style("width", "100%")
    .style("height", "auto")

  if (useAnimation) {
    svg.style("opacity", 0).transition().duration(300).style("opacity", 1)
  }

  svg
    .append("defs")
    .append("clipPath")
    .attr("id", "round-corner")
    .append("rect")
    .attr("width", Math.max(134, width / 4 + 4))
    .attr("height", 117)
    .attr("rx", 4)
    .attr("ry", 4)
    .attr("x", 0)
    .attr("y", height / 2 - firstNodeHeight / 2)

  const nodeWidth = Math.max(125, width / 7)

  const { nodes, links } = d3Sankey()
    .nodeWidth(nodeWidth)
    .nodeId((d) => d.id)
    .extent([
      [0, 0],
      [width, height],
    ])({
    nodes: data.nodes,
    links: data.links,
  })

  const nodeGroups = svg
    .selectAll("g.node")
    .data(nodes)
    .enter()
    .insert("g")
    .style("shape-rendering", "crispEdges")
    .attr("class", "node")
    .attr("clip-path", (d) => (d.index === 0 ? "url(#round-corner)" : ""))

  nodeGroups.each((d, i) => {
    d.width = d.index === 0 ? Math.max(130, width / 4) : nodeWidth
    d.height = d.index === 0 ? firstNodeHeight : d.value * height

    if (d.index === 0) {
      d.x1 = Math.max(130, width / 4) + 0.5
      d.labelHeight = firstNodeHeight
      d.labelY0 = height / 2 - d.height / 2
      d.y0 = d.labelY0
    }

    if (d.index === 1) {
      d.labelHeight = Math.max(30, d.value * height)
      d.labelY0 = 0
      d.y0 = 0
    } else if (d.index > 1) {
      const prev = nodeGroups.data()[i - 1]
      d.labelHeight = Math.max(30, d.value * height)
      d.labelY0 = prev.labelY0 + prev.labelHeight
      d.y0 = prev.y0 + prev.height
    }

    d.y1 = Math.min(height, d.y0 + d.height)
  })

  function openModal(e) {
    const { status = "all" } = e.currentTarget.dataset

    $.pjax({
      url: "/succession/plan_status_details",
      data: { plan_status_filter: { status_type: status } },
      container: "[data-pjax-container=modal]",
      push: false,
      scrollTo: false,
    })
    $.pjax.xhr = null
  }

  const modalLinks = nodeGroups
    .append("a")
    .attr("class", (d) => `svg-modal-link ${d.id}`)
    .attr("data-status", (d) => d.id)
    .on("click", openModal)

  function textPosition(d, firstLine) {
    let center = Math.min(
      height - ((4 - d.index) * 30 - 18),
      Math.max(d.index * 30 - 18, (d.y1 + d.y0) / 2),
    )

    if (d.index === 0) {
      center = (d.y1 + d.y0) / 2
    }

    const offset = d.index === 0 ? 14 : 7
    return firstLine ? center - offset : center + offset
  }

  modalLinks
    .append("rect")
    .attr("width", (d) => d.width)
    .attr("height", (d) => d.labelHeight)
    .attr("fill", (d) => d.color)
    .attr("x", (d) => d.x0)
    .attr("y", (d) => d.labelY0)

  modalLinks
    .append("text")
    .attr("class", "bold")
    .style("font", "bold 11px 'Satoshi'")
    .attr("fill", (d) => (d.index === 0 ? "#fff" : "rgba(012, 020, 075, 0.8)"))
    .attr("x", (d) => (d.index === 0 ? d.x0 + d.width / 2 : d.x0 + 20))
    .attr("y", (d) => textPosition(d, true))
    .attr("dy", "0.35em")
    .attr("text-anchor", (d) => (d.index === 0 ? "middle" : "start"))
    .text((d) => d.label_bold)

  modalLinks
    .filter((d) => d.index === 0)
    .append("text")
    .attr("class", "bold2")
    .style("font", "bold 11px 'Satoshi'")
    .attr("fill", "#fff")
    .attr("x", (d) => d.x0 + d.width / 2)
    .attr("y", (d) => (d.y1 + d.y0) / 2)
    .attr("dy", "0.35em")
    .attr("text-anchor", "middle")
    .text((d) => d.label_bold_2)

  modalLinks
    .append("text")
    .attr("class", "regular")
    .style("font", "11px 'Satoshi'")
    .attr("fill", (d) => (d.index === 0 ? "#fff" : "rgba(027, 098, 255, 1)"))
    .attr("x", (d) => (d.index === 0 ? d.x0 + d.width / 2 : d.x0 + 20))
    .attr("y", (d) => textPosition(d, false))
    .attr("dy", "0.35em")
    .attr("text-anchor", (d) => (d.index === 0 ? "middle" : "start"))
    .text((d) => d.label_regular)

  function sankeyLinkPath(link, prev) {
    link.y0 = prev ? prev.y1 : link.source.y0
    link.y1 = link.y0 + link.source.height * link.value

    const sx = link.source.x1 - 1
    const tx = link.target.x0
    const sy0 = link.y0
    const sy1 = link.y1
    const ty0 = link.target.y0
    const ty1 = link.target.y1

    const halfx = (tx - sx) / 2
    const halfy0 = (Math.max(sy0, ty0) - Math.min(sy0, ty0)) / 2
    const halfy1 = (Math.max(sy1, ty1) - Math.min(sy1, ty1)) / 2
    const curve1x = sx + halfx / 1.5
    const curve2x = tx - halfx / 1.5
    const radius = 50
    let cpx1 = 0
    let cpy1 = 0
    let cpx2 = 0
    let cpy2 = 0

    const path = d3Path()
    path.moveTo(sx, sy0)

    if (sy0 !== ty0) {
      cpx1 = curve1x
      cpy1 = sy0
      cpx2 = sx + halfx
      cpy2 = ty0 < sy0 ? sy0 - halfy0 : sy0 + halfy0
      path.arcTo(cpx1, cpy1, cpx2, cpy2, radius)

      cpx1 = curve2x
      cpy1 = ty0
      cpx2 = tx
      cpy2 = ty0
      path.arcTo(cpx1, cpy1, cpx2, cpy2, radius)
    }

    path.lineTo(tx, ty0)
    path.lineTo(tx, ty1)

    if (sy1 === ty1) {
      path.lineTo(sx, sy1)
    } else {
      cpx1 = curve2x
      cpy1 = ty1
      cpx2 = sx + halfx
      cpy2 = ty1 < sy1 ? sy1 - halfy1 : sy1 + halfy1
      path.arcTo(cpx1, cpy1, cpx2, cpy2, radius)

      cpx1 = curve1x
      cpy1 = sy1
      cpx2 = sx
      cpy2 = sy1
      path.arcTo(cpx1, cpy1, cpx2, cpy2, radius)
    }

    path.lineTo(sx, sy1)
    path.lineTo(sx, sy0)

    return path.toString()
  }

  const paths = svg
    .insert("g", "g")
    .selectAll("a")
    .data(links)
    .enter()
    .append("a")
    .attr("class", (d) => `svg-modal-link ${d.target.id}`)
    .attr("data-status", (d) => d.target.id)
    .on("click", openModal)
    .append("path")
    .attr("fill", (d) => d.color)

  paths.attr("d", (d, i) => sankeyLinkPath(d, paths.data()[i - 1]))

  // Handle hover states
  function mouseOver(e) {
    const { status = "all" } = e.currentTarget.dataset
    svg.selectAll(`.svg-modal-link:not(.${status}):not(.total)`).style("opacity", 0.35)
    svg.selectAll(`.${status}`).style("opacity", 1)
  }
  function mouseOut() {
    svg.selectAll(".svg-modal-link").style("opacity", 1)
  }
  svg.selectAll("a").on("mouseover", mouseOver).on("mouseout", mouseOut)

  return svg.node()
}

function renderSankeyChart(props = {}) {
  sankeyChart({
    container: props.container || "#plan-health-sankey-chart",
    data: props.data || window.plan_health_data,
    width: props.width || $("#plan-health-sankey-chart").width(),
    height:
      props.height ||
      $("#plan-health-sankey-chart").parents(".grid-stack-item-content").height() - 47,
    useAnimation: props.useAnimation,
  })
}

$.onmount("#plan-health-sankey-chart", () => {
  if ($(this).children().length > 0) {
    return
  }

  renderSankeyChart({ useAnimation: true })

  window.addEventListener("resize", () => {
    $("#plan-health-sankey-chart").empty()
    renderSankeyChart({ useAnimation: false })
  })
})
/* eslint-enable
  no-param-reassign
*/
