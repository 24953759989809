/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const { Preview } = window

const constants = {
  elementSizes: {
    text: {
      height: 20,
      width: 200,
    },
    signature: {
      height: 30,
      width: 250,
    },
    date: {
      height: 20,
      width: 100,
    },
    checkbox: {
      height: 15,
      width: 15,
    },
  },
}

const switchToText = function (e) {
  if (e) {
    e.preventDefault()
  }
  $("#new_pdf_field #pdf_field_field_type").val("text")
  $("#new_pdf_field #pdf_field_person_attribute").val("")
  $("canvas").css("cursor", "crosshair")
  $(".active").removeClass("active")
  return $("[data-action=text]").addClass("active")
}

const switchToSignature = function (e) {
  if (e) {
    e.preventDefault()
  }
  $("#new_pdf_field #pdf_field_field_type").val("signature")
  $("#new_pdf_field #pdf_field_person_attribute").val("")
  $("canvas").css("cursor", "crosshair")
  $(".active").removeClass("active")
  return $("[data-action=signature]").addClass("active")
}

const switchToDate = function (e) {
  if (e) {
    e.preventDefault()
  }
  $("#new_pdf_field #pdf_field_field_type").val("date")
  $("#new_pdf_field #pdf_field_person_attribute").val("")
  $("canvas").css("cursor", "crosshair")
  $(".active").removeClass("active")
  return $("[data-action=date]").addClass("active")
}

const switchToCheckbox = function (e) {
  if (e) {
    e.preventDefault()
  }
  $("#new_pdf_field #pdf_field_field_type").val("checkbox")
  $("#new_pdf_field #pdf_field_person_attribute").val("")
  $("canvas").css("cursor", "crosshair")
  $(".active").removeClass("active")
  return $("[data-action=checkbox]").addClass("active")
}

const renderFields = function (page) {
  if ($(`${page} .form-field`).length) {
    return $.each($(`${page} .form-field`), function (_i, field) {
      const $field = $(field)
      const viewport = $(this).closest(".page").data("viewport")
      const vpPosition = viewport.convertToViewportPoint($field.data("left"), $field.data("top"))

      $field.css({
        width: parseFloat($field.data("width")) * viewport.scale + "px",
        height: parseFloat($field.data("height")) * viewport.scale + "px",
        left: vpPosition[0],
        top: vpPosition[1],
      })

      $field.draggable({
        containment: $field.closest(".page")[0],
        stop: (event, ui) => {
          const pdfPosition = viewport.convertToPdfPoint(ui.position.left, ui.position.top)
          $(event.target).find("#pdf_field_position_x").val(pdfPosition[0])
          $(event.target).find("#pdf_field_position_y").val(pdfPosition[1])
          return $(event.target).find("form").trigger("submit")
        },
      })

      return $field.not(".checkbox").resizable({
        containment: $field.closest(".page")[0],
        stop(event, ui) {
          $(event.target)
            .find("#pdf_field_width")
            .val(ui.size.width / viewport.scale)
          $(event.target)
            .find("#pdf_field_height")
            .val(ui.size.height / viewport.scale)
          return $(event.target).find("form").trigger("submit")
        },
      })
    })
  }
}

$.onmount(Preview.selector("company-documents-edit"), function () {
  return $(this).on("preview:renderPage", function (_e, _preview, page) {
    $("[data-action=checkbox]").on("click", switchToCheckbox)
    $("[data-action=signature]").on("click", switchToSignature)
    $("[data-action=date]").on("click", switchToDate)
    $("[data-action=text]").on("click", switchToText)
    $("[data-action=text]").trigger("click")

    const $page = $(".page" + page.pageNumber)

    $page.on("click", function (e) {
      if ($(e.target).is("canvas")) {
        const viewport = $(this).closest(".page").data("viewport")
        const pdfPosition = viewport.convertToPdfPoint(e.offsetX, e.offsetY)
        const sizes = constants.elementSizes[$("#new_pdf_field #pdf_field_field_type").val()]
        const $form = $("#new_pdf_field")
        $form.find("#pdf_field_position_x").val(pdfPosition[0])
        $form.find("#pdf_field_position_y").val(pdfPosition[1])
        $form.find("#pdf_field_height").val(sizes.height / viewport.scale)
        $form.find("#pdf_field_width").val(sizes.width / viewport.scale)
        $form.find("#pdf_field_page").val(page.pageNumber)
        $form.data("pjax-submit", `pdf_fields_${page.pageNumber}`)
        const editable = $form.data("editable")
        const fieldType = $form.find("#pdf_field_field_type").val()

        if (editable.includes(fieldType)) {
          return $("#new-form-link").trigger("click")
        } else {
          return $("#new_pdf_field").trigger("submit")
        }
      }
    })

    renderFields(".page" + page.pageNumber)

    return $(`[data-pjax-container=pdf_fields_${page.pageNumber}]`)
      .parent()
      .on("pjax:end", () => {
        return renderFields(".page" + page.pageNumber)
      })
  })
})
