const $ = window.jQuery
const NoteAttachment = window.NoteAttachment

$.onmount(".edit_note, .new_note", function editNoteForm() {
  function registerNoteAttachmentActions($container) {
    $container.find('[data-action="editNote"]').on("click", function toggleRowEdit() {
      NoteAttachment.rowForButton(this).toggleEdit()
    })

    $container.find('[data-action="closeEdit"]').on("click", function closeEdit() {
      window.currentNoteAttachment.closeRow(this)
    })

    $container.find('[data-action="deleteNote"]').on("click", function removeRow() {
      NoteAttachment.rowForButton(this).remove()
    })

    $container.find('[data-action="saveEdit"]').on("click", function saveRow(e) {
      e.stopPropagation()
      e.preventDefault()
      window.currentNoteAttachment.saveRow(this)
    })

    $container.find("input[type=file]").on("change", function fileChanged(e) {
      NoteAttachment.rowForButton(this).fileSelected(e)
    })
  }

  const $modalForm = $(this)
  window.currentNoteAttachment = new NoteAttachment($modalForm)
  registerNoteAttachmentActions($modalForm)

  window.currentNoteAttachment.$addFileButton.on("click", () => {
    const newFileRow = window.currentNoteAttachment.addRow()
    registerNoteAttachmentActions(newFileRow.$div)
  })
})
