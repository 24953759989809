/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
jQuery(function ($) {
  // Mask inputs
  $.onmount("input[data-maskedinput]", function () {
    if ($.fn.maskInputs) {
      return $(this).maskInputs()
    }
  })

  // Date picker
  $.onmount("[data-widget=datepicker]", function () {
    const options = {}
    if (this.dataset.maxDate) {
      options["maxDate"] = new Date(this.dataset.maxDate)
    }
    return new PikadayInput(this).create(options)
  })

  if (window.gon?.tinymce_style_url) {
    window.tinymceDefaults = {
      branding: false,
      browser_spellcheck: true,
      content_css: window.gon.tinymce_style_url,
      contextmenu: false,
      formats: {
        underline: { inline: "u", exact: true },
      },
      height: 200,
      icons: "built-icons",
      inline_styles: false,
      link_target_list: [
        { text: "Current tab", value: "" },
        { text: "New tab", value: "_blank" },
      ],
      menubar: false,
      plugins: "link lists",
      selector: "textarea.tinymce",
      toolbar: "bold italic underline | bullist numlist | link unlink",
      init_instance_callback(editor) {
        return (
          editor.on("focus", () =>
            editor.editorContainer
              .querySelector(".tox-editor-container")
              ?.classList.add("editor-focus"),
          ),
          editor.on("blur", () =>
            editor.editorContainer
              .querySelector(".tox-editor-container")
              ?.classList.remove("editor-focus"),
          )
        )
      },
      setup(editor) {
        return editor.on("change", () => editor.save())
      },
    }
  }

  $.onmount(".tinymce", () => tinymce.init(tinymceDefaults))

  // File Input
  $.onmount('.input-file input[type="file"]', function () {
    const $fileInput = $(this)
    if (!$fileInput.hasClass("file-upload") && !$fileInput.hasClass("no-file-replace")) {
      return $fileInput.on("change", function (e) {
        const $container = $(this).parent()
        if ($container.find("span").length) {
          return $container
            .addClass("disabled")
            .find("span")
            .text("upload_document_disabled".t("button_defaults"))
        }
      })
    }
  })

  // Cocoon - tool for adding more rows to table form
  $.onmount(".modal .add_fields", () => $.cocoon())

  return $.onmount(".select select, .relative .input", function () {
    $(this).on("focus", function () {
      return $(this).parents(".select, .relative").addClass("active")
    })
    return $(this).on("blur", function () {
      return $(this).parents(".select, .relative").removeClass("active")
    })
  })
})
