import { PdfRenderManager } from "./pdf_render_manager.js"

const $ = window.jQuery
const _ = window._

const applicationPrintBtnSelector = "[data-action=print-application]"
const historyPrintBtnSelector = "[data-action=print-notes]"
const modalPrintBtnSelector = "[data-action=print-modal]"
const windowPrintBtnSelector = "[data-action=print-window]"

function appendToPrintFrame(hiddenFrame, location, content) {
  $(hiddenFrame).contents().find(location).append(content)
}

function closePrint() {
  document.body.removeChild(this.__container__)
}

function openPrint(hiddenFrame) {
  hiddenFrame.contentWindow.focus()
  hiddenFrame.contentWindow.print()
}

function processQueue(hiddenFrame, queue, promise) {
  $.when(...queue).done((...args) => {
    _.each(args, (data) => appendToPrintFrame(hiddenFrame, "body", data))
    promise.resolve()
  })
}

function addFilesForPrint(hiddenFrame, files) {
  const filesLoaded = new $.Deferred()
  const renderQueue = []

  _.each(files, (url) => {
    window.PDFJS.getDocument(url)
      .then((pdf) => {
        const file = new PdfRenderManager(pdf)
        file.addForPrint()
        renderQueue.push(file.promise)

        if (files.length === renderQueue.length) {
          processQueue(hiddenFrame, renderQueue, filesLoaded)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  })
  return filesLoaded
}

function setPrintContent(hiddenFrame, content, attachments, files) {
  const contentSet = new $.Deferred()
  const contentCloned = content.clone()
  contentCloned.find("form").remove()
  appendToPrintFrame(hiddenFrame, "body", contentCloned)

  if (attachments) {
    return addFilesForPrint(hiddenFrame, files).done(() => contentSet.resolve())
  }
  return contentSet.resolve()
}

function setupPrint(button) {
  const hiddenFrame = document.createElement("iframe")
  hiddenFrame.style.visibility = "hidden"
  hiddenFrame.style.position = "fixed"
  hiddenFrame.style.right = "0"
  hiddenFrame.style.bottom = "0"

  document.body.appendChild(hiddenFrame)
  hiddenFrame.contentWindow.__container__ = hiddenFrame
  hiddenFrame.contentWindow.onbeforeunload = closePrint
  hiddenFrame.contentWindow.onafterprint = closePrint

  return $.get(button.dataset.styleUrl, (data) => {
    appendToPrintFrame(hiddenFrame, "head", `<style>${data}</style>`)
  }).then(() => hiddenFrame)
}

function printApplication(e) {
  e.preventDefault()

  $.ajax({
    url: e.currentTarget.dataset.url,
    dataType: "json",
    complete: (data) => {
      let files = data.responseJSON.files
      let jobAttachments = false
      const printContent = [".recruiting-header-profile"]
      printContent.push(".application-info")

      if (files) {
        files = files.split(",")
        jobAttachments = true
      }

      $.when(setupPrint(e.currentTarget)).then((hiddenFrame) => {
        const $btn = $(applicationPrintBtnSelector)
        const title = `Built-${$btn.data("printTitle")}`
        appendToPrintFrame(hiddenFrame, "head", `<title>${title}</title>`)
        setPrintContent(hiddenFrame, $(printContent.join(", ")), jobAttachments, files).then(() =>
          openPrint(hiddenFrame),
        )
      })
    },
  })
}

function printHistory(e) {
  e.preventDefault()

  const printContent = [".recruiting-header-profile"]
  printContent.push(".job-app-events")

  $.when(setupPrint(e.currentTarget)).then((hiddenFrame) => {
    const $btn = $(historyPrintBtnSelector)
    const title = `Built-${$btn.data("printTitle")}-history`
    appendToPrintFrame(hiddenFrame, "head", `<title>${title}</title>`)
    setPrintContent(hiddenFrame, $(printContent.join(", ")), false, []).then(() =>
      openPrint(hiddenFrame),
    )
  })
}

function printModal(e) {
  e.preventDefault()

  $("body > div:not([data-pjax-container=modal])").addClass("no-print")
  window.print()
  $("body > div:not([data-pjax-container=modal])").removeClass("no-print")
}

$.onmount(applicationPrintBtnSelector, () => {
  $(applicationPrintBtnSelector).on("click", (e) => printApplication(e))
})

$.onmount(historyPrintBtnSelector, () => {
  $(historyPrintBtnSelector).on("click", (e) => printHistory(e))
})

$.onmount(modalPrintBtnSelector, () => {
  $(modalPrintBtnSelector).on("click", (e) => printModal(e))
})

$.onmount(windowPrintBtnSelector, () => {
  $(windowPrintBtnSelector).on("click", (e) => {
    e.preventDefault()
    window.print()
  })
})
