/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount(
  ".edit-position-modal, .new-position-modal, .position-info-modal, .job-definition-modal",
  function () {
    $(this).on("autocomplete:renderSuggestions", function (e) {
      const $nameInput = $(e.target)

      return $nameInput
        .siblings(".autocomplete-container")
        .find("#show-add-org-unit-form")
        .on("mousedown", function (e) {
          e.preventDefault()
          e.stopPropagation()

          const $autocompleteContainer = $nameInput.siblings(".autocomplete-container")
          $autocompleteContainer.hide()

          return window.InlineOrgUnitForm.mount({
            unitInput: $nameInput[0],
            contextEl: e.currentTarget,
          })
        })
    })

    $(this).on("autocomplete:clearSelected", function (e) {
      const targetInputId = $(e.target).data("id")
      // Clears the parent_id input if the reports_to field is cleared
      if (targetInputId === "position_parent_id") {
        return $(`#${targetInputId}`).val("")
      }
    })

    $(this).on("modal:save", function (e, modal, $form) {
      if (gon.selected_chart) {
        const position_id = $form.prop("id").split("edit_position_")[1]
        $.get({
          url: App.endpoint(["positions", "organize", gon.selected_chart.id]),
          data: { ids: [position_id] },
        }).done((data) => {
          const position_data = data[0]
          const position = App.OrgChart.findPosition(position_data.id)
          position.set(position_data)

          if ($(this).hasClass("new-position-modal")) {
            return App.OrgChart.addPosition(position)
          } else {
            return App.OrgChart.updatePosition(position)
          }
        })
      }

      return this
    })

    var init = () => {
      window.BudgetTable.tableEvents(this)
      window.FundingSources.fundingSourceEvents(this)
      window.FilledByInput.events(this)
      window.TitleInput.events(this)
      window.DropdownMenu.handleVisibility(this)

      const withOrgUnitAutocompleteBehavior =
        window.InlineOrgUnitForm.attachOrgUnitAutocompleteBehavior
      $(this).off("focus", ".inline-org-units", withOrgUnitAutocompleteBehavior)
      $(this).on("focus", ".inline-org-units", withOrgUnitAutocompleteBehavior)

      let activeTabIndex = 0
      if ($("#position-management-tabs").attr("data-selected-tab") === "job_description") {
        activeTabIndex = 1
      }
      if ($("#position-management-tabs").attr("data-selected-tab") === "planning") {
        activeTabIndex = 2
      }

      $(this)
        .find("#position-tab-container")
        .tabs({
          active: activeTabIndex,
          classes: { "ui-tabs-active": "active" },
        })

      return $(this).on("modal:error", function () {
        // --- Reinitialize handlers on modal error
        init()
        // ---

        const error_tab_id = $(".form-error").parents(".tab-panel").attr("id")
        const error_link_id = $(`.ui-tabs-tab[aria-controls=${error_tab_id}]`).attr(
          "aria-labelledby",
        )
        return $(`#${error_link_id}`).trigger("click")
      })
    }

    return init()
  },
)
