import Fingerprint2 from "fingerprintjs2"

const $ = window.jQuery

const setFingerprint = ($input) => {
  Fingerprint2.get((components) => {
    const values = components.map((component) => component.value)
    const murmur = Fingerprint2.x64hash128(values.join(""), 31)
    $input.val(murmur)
  })
}

$.onmount("#new_user", () => {
  const $input = $('[name="user[login_location][device_fingerprint]"]')
  const timeout = 500
  const callback = () => setFingerprint($input)

  if (window.requestIdleCallback) {
    window.requestIdleCallback(callback, { timeout })
  } else {
    window.setTimeout(callback, timeout)
  }
})
