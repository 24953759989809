const { jQuery: $ } = window

class ProfilePtoAccrual {
  constructor(ptoTypeID, defaultAmount, defaultAccruals) {
    this.ptoTypeID = ptoTypeID.toString()
    this.defaultAmount = defaultAmount
    this.defaultAccruals = defaultAccruals

    const $container = $(`i#periodic-secondary_pt_${this.ptoTypeID}`).parents(".accrual-rate")
    this.$useDefaultInput = $container.find(`input#pto_type_${this.ptoTypeID}_use_default_accrual`)
    this.$accrualInput = $container.find(`input#pto_type_${this.ptoTypeID}_accrual`)
    this.$accrualAmountSpan = $container.find("span#accrual-amount")

    this.$useDefaultInput.on("change", () => {
      this.updateAccrual()
    })
    this.$accrualInput.on("input", () => {
      this.updateAmount()
    })
  }

  accrualAmount(yearlyAccrual) {
    return Math.round((yearlyAccrual / this.defaultAccruals) * 10000) / 10000
  }

  updateAccrual() {
    if (this.$useDefaultInput.prop("checked")) {
      this.$accrualInput.val("")
      this.$accrualAmountSpan.html(this.accrualAmount(this.defaultAmount))
    } else {
      this.$accrualInput.trigger("focus")
    }
  }

  updateAmount() {
    this.$accrualAmountSpan.html(this.accrualAmount(this.$accrualInput.val()))
  }
}

window.ProfilePtoAccrual = ProfilePtoAccrual
