import { createPopper } from "@popperjs/core"
import * as d3 from "d3"

import pickChartOptionsOfNode from "../utils/pickChartOptionsOfNode"
import {
  convertToFixed,
  formatTooltipDate,
  getChartData,
  getChildTspans,
  getLineScales,
  getYears,
  insertLineBreaks,
  isStartOfMonth,
  isStartOfYear,
  parseDate,
  timeFormat,
} from "../utils/turnoverUtils"

const { $ } = window
const COLOR_PRIMARY_80_SOLID = "rgb(072, 129, 255)"

const LineChartBaseConfig = Object.freeze({
  margins: {
    top: 20, // top margin, in pixels
    right: 20, // right margin, in pixels
    bottom: 30, // bottom margin, in pixels
    left: 25, // left margin, in pixels
  },
  curve: d3.curveLinear,
  yLabel: null,
  stroke: {
    color: COLOR_PRIMARY_80_SOLID,
    width: 2,
    lineJoin: "round",
    lineCap: "round",
  },
  circle: {
    fill: COLOR_PRIMARY_80_SOLID,
    radius: 5,
    stroke: COLOR_PRIMARY_80_SOLID,
  },
  useAnimation: false,
  maxPointsToShow: 18,
})

class LineChart {
  constructor({ options }) {
    this.margin = options.margins || { ...LineChartBaseConfig.margins }
    this.curve = options.curve || LineChartBaseConfig.curve
    this.yLabel = options.yLabel || LineChartBaseConfig.yLabel
    this.stroke = options.stroke || LineChartBaseConfig.stroke
    this.circle = options.circle || LineChartBaseConfig.circle
    this.useAnimation = options.useAnimation || LineChartBaseConfig.useAnimation
    this.maxPointsToShow = options.maxPointsToShow || LineChartBaseConfig.maxPointsToShow
  }

  /**
   * Creates the chart backbone (Axis, ticks and labels)
   *
   */
  createChart({ selector }) {
    this.selector = selector
    this.selection = d3.select(selector)
    this.data = pickChartOptionsOfNode(this.selection.node())
    this.generateChartScaffold()

    const { O } = this.chartData
    const { width, height } = this.dimensions
    const { xAxis, yAxis } = this.axes
    const { resolution } = this
    const { dateRange } = this

    // Create chart svg
    this.svg = this.selection
      .append("svg")
      .attr("id", "line-svg")
      .attr("height", height)
      .attr("width", width)
      .attr("viewBox", [0, 0, width, height])
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
      .attr("cursor", "default")
      .style("-webkit-tap-highlight-color", "transparent")
      .style("overflow", "visible")
    const { svg } = this

    // Create axis groups
    const xAxisGroup = svg
      .append("g")
      .attr("class", "axis")
      .attr("transform", `translate(0,${height - this.margin.bottom})`)
      .call(xAxis)
      .call((g) => {
        if (resolution !== "annually" || dateRange !== "one_year") {
          return g.selectAll("text").each(insertLineBreaks)
        }
        return null
      })

    const yAxisGroup = svg
      .append("g")
      .attr("id", "yAxis")
      .attr("class", "axis")
      .attr("transform", `translate(${this.margin.left},0)`)
      .call(yAxis)
      .call((g) => g.select(".domain").remove())

    if (this.yLabel) {
      yAxisGroup.call((g) =>
        g
          .append("text")
          .attr("x", -this.margin.left)
          .attr("y", 10)
          .attr("fill", "currentColor")
          .attr("text-anchor", "start")
          .attr("id", "yLabel")
          .text(this.yLabel),
      )
    }

    this.axisGroups = { xAxisGroup, yAxisGroup }

    // Create clip path for animation
    this.overlay = svg
      .append("defs")
      .append("clipPath")
      .attr("id", "overlay")
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 0) // may need to adjust here
      .attr("height", height)

    // Create group for line paths
    const chartGroup = svg
      .append("g")
      .attr("id", "chartGroup")
      .attr("clip-path", () => (this.useAnimation ? "url(#overlay)" : null))

    // Create path for line
    this.chartLine = chartGroup
      .append("path")
      .attr("id", "chart-line")
      .attr("fill", "none")
      .attr("stroke", this.stroke.color)
      .attr("stroke-width", this.stroke.width)
      .attr("stroke-linejoin", this.stroke.lineJoin)
      .attr("stroke-linecap", this.stroke.lineCap)

    // Create path for area
    this.chartArea = chartGroup
      .append("path")
      .attr("fill", d3.color(this.stroke.color).copy({ opacity: 0.15 }))

    // Create circles
    this.circles = chartGroup
      .selectAll("circles")
      .data(O.filter((d) => d.turnover_rate !== null))
      .enter()
      .append("circle")
      .attr("id", (_, i) => `data-point-${i}`)
      .attr("fill", "white")
      .attr("stroke", this.circle.stroke)
      .attr("stroke-width", this.stroke.width)
      .attr("r", this.circle.radius)
      .attr("visibility", "hidden")

    // Select tooltip parts
    const tooltip = this.selection.select("#line-chart-tooltip")
    const tooltipSelections = getChildTspans(tooltip.select(".tooltip-text"))

    // Use popper
    const popper = createPopper(svg.node(), tooltip.node(), {
      placement: "top",
      modifiers: [{ name: "offset", options: { offset: [0, 10] } }],
    })

    this.tooltipElems = { tooltip, popper, ...tooltipSelections }

    // Update left margin if there's y-axis label overflow
    this.maybeUpdateLeftMargin()

    // Call updateChart to draw the data onto the chart
    this.updateChart()
  }

  /**
   * Draws data onto the chart.
   */
  updateChart() {
    const { dI, I, Y, O, X, nonNullIndex } = this.chartData
    const { line, area } = this.generators
    const { tooltip, popper, tooltipDate, tooltipLeavers, tooltipTurnoverRate } = this.tooltipElems
    const { xScale, yScale } = this.scales
    const { resolution, dateRange, svg, overlay, numPoints, maxPointsToShow } = this
    const { width } = this.dimensions

    const getTooltipData = (i) => {
      let date = formatTooltipDate(O[i], resolution)
      // Handles special case of year resolution with rolling year selection
      if (resolution === "annually" && dateRange === "one_year") {
        const start = parseDate(O[i].date_start)
        date = `${timeFormat.month(start)} ${timeFormat.year(start)} - ${timeFormat.month(
          X[i],
        )} ${timeFormat.year(X[i])}`
      }
      return {
        leavers: O[i].leavers,
        rate: convertToFixed(O[i].turnover_rate),
        date,
      }
    }

    function pointerleft(that) {
      // remove tooltip
      tooltip.attr("data-show", null)
      // Unhighlight point, unless its clicked
      const unHighlight = svg
        .selectAll(".highlighted")
        .attr("fill", "white")
        .attr("stroke", that.circle.stroke)
        .attr("stroke-width", that.stroke.width)
        .attr("r", that.circle.radius)
        .classed("highlighted", false)
      // If less than some # points, hide point, unless it's clicked
      if (numPoints > maxPointsToShow) {
        unHighlight.attr("visibility", "hidden")
      }
    }

    function pointermoved(that, event) {
      const i = d3.bisectCenter(I, xScale.invert(d3.pointer(event)[0]))
      if (i >= nonNullIndex) {
        tooltip.attr("data-show", "")

        const { leavers, rate, date } = getTooltipData(i)

        // Populate tooltip data
        tooltipDate.text(date)
        tooltipTurnoverRate.text(`${rate}%`)
        tooltipLeavers.text(() =>
          leavers === 1 ? `${leavers} termination` : `${leavers} terminations`,
        )

        const circleNode = d3.select(`#data-point-${i - nonNullIndex}`)
        const unHighlight = svg
          .selectAll(".highlighted")
          .attr("fill", "white")
          .attr("stroke", that.circle.stroke)
          .attr("stroke-width", that.stroke.width)
          .attr("r", that.circle.radius)
          .classed("highlighted", false)
        if (numPoints > maxPointsToShow) {
          unHighlight.attr("visibility", "hidden")
          circleNode.attr("visibility", "visible")
        }

        circleNode
          .classed("highlighted", true)
          .attr("fill", that.circle.fill)
          .attr("r", that.circle.radius + that.stroke.width / 2)
          .attr("stroke-width", 20)
          .attr("stroke", d3.color(that.circle.stroke).copy({ opacity: 0.15 }))

        popper.state.elements.reference = circleNode.node()
        popper.update()
      } else {
        pointerleft(that)
      }
    }

    // Draw line
    this.chartLine.attr("d", line(dI))

    // Draw area
    this.chartArea.attr("d", area(dI))

    // Draw circles if numPoints <= maxPointsToShow
    if (numPoints <= maxPointsToShow) {
      this.circles.attr("visibility", (d) => (d.turnover_rate !== null ? "visible" : "hidden"))
    }

    // Place circles
    this.circles
      .attr("cx", (_, i) => xScale(I[i + nonNullIndex]))
      .attr("cy", (_, i) => yScale(Y[i + nonNullIndex]))

    // Animate line
    if (this.useAnimation) {
      overlay.transition().duration(2000).attr("width", width)
    }

    // Add event handlers to svg
    svg
      .on("pointerenter pointermove", (event) => pointermoved(this, event))
      .on("pointerleave", () => pointerleft(this))
      .on("touchstart", (event) => event.preventDefault())
  }

  /**
   * Handles chart updating on window resize.
   */
  resizeChart() {
    // Updates chart params
    this.generateChartScaffold()
    const { width, height } = this.dimensions
    const { xAxis, yAxis } = this.axes
    const { xAxisGroup, yAxisGroup } = this.axisGroups
    const { resolution } = this
    const { dateRange } = this

    // Updates svg
    this.svg.attr("height", height).attr("width", width).attr("viewBox", [0, 0, width, height])
    // Updates axes
    xAxisGroup
      .attr("transform", `translate(0,${height - this.margin.bottom})`)
      .call(xAxis)
      .call((g) => {
        if (resolution !== "annually" || dateRange !== "one_year") {
          return g.selectAll("text").each(insertLineBreaks)
        }
        return null
      })

    yAxisGroup
      .attr("transform", `translate(${this.margin.left},0)`)
      .call(yAxis)
      .call((g) => g.select(".domain").remove())

    // Updates the w/ new scaffold
    this.updateChart()
  }

  /**
   * Generates all params needed for creating a chart and
   * stores them as class attributes.
   */
  generateChartScaffold() {
    // Dynamically size line chart svg
    const width = $(this.selector).width()
    const height = $(this.selector).height()
    this.dimensions = { width, height }

    // Get Line Data
    const { X, Y, O, I, dX, dY, dO, dI, dateExtent, cumulativeData, nonNullIndex } = getChartData(
      this.data,
    )
    this.chartData = {
      X,
      Y,
      O,
      I,
      dI,
      dY,
      dX,
      dO,
      dateExtent,
      cumulativeData,
      nonNullIndex,
    }
    this.numPoints = X.length

    // Get current selected resolution
    const resolution = $("#turnover-resolution").children("[selected]").val()
    this.resolution = resolution
    const dateRange = $("#time-range").children(".active").attr("id")
    this.dateRange = dateRange

    // Get ranges
    const xRange = [this.margin.left, width - this.margin.right]
    const yRange = [height - this.margin.bottom, this.margin.top]
    this.ranges = { xRange, yRange }

    // Get Scales
    const { xScale, yScale } = getLineScales(dX, dY, xRange, yRange)
    this.scales = { xScale, yScale }

    // Get Ticks
    const numTicks = Math.floor((dX.length > width / 50 ? width / 50 : dX.length) - 1)
    const numYears = getYears(dateExtent[0], dateExtent[1])
    let tickValues = null
    if (resolution === "annually") {
      tickValues = I
    } else if (numYears > 2) {
      tickValues = I.filter((i) => isStartOfYear(dO[i]))
    } else if (resolution !== "annually") {
      tickValues = I.filter((i) => isStartOfMonth(dO[i]))
    }

    const tickDates = tickValues.map((d) => {
      if (resolution === "annually" || resolution === "quarterly") {
        return parseDate(dO[d].date_start).getMonth()
      }
      return dX[d].getMonth()
    })

    // Dynamically filter ticks based on width/num data points
    const tickNum = tickValues.length
    const tickHalf = Math.floor(tickNum * 0.5)
    const tickThird = Math.floor(tickNum * 0.33)

    if (tickNum > 2 && tickHalf >= numTicks && tickThird <= numTicks) {
      tickValues = tickValues.filter((_, i) =>
        numYears > 2 ? i % 2 === 0 : tickDates[i] % 2 === 0,
      )
    } else if (tickNum > 2 && tickThird > numTicks) {
      tickValues = tickValues.filter((_, i) =>
        numYears > 2 ? i % 4 === 0 : tickDates[i] % 4 === 0,
      )
    }

    // Logic for displaying axes labels
    const multiFormat = (i, j) => {
      if (resolution === "annually") {
        if (dateRange === "one_year") {
          const start = parseDate(dO[i].date_start)
          return `${timeFormat.month(start)} ${timeFormat.year(start)} - ${timeFormat.month(
            dX[i],
          )} ${timeFormat.year(dX[i])}`
        }
        return timeFormat.year(dX[i])
      }
      if (numYears < 3) {
        if (j === 0 || isStartOfYear(dO[i])) {
          return resolution === "quarterly"
            ? `Q${timeFormat.quarter(dX[i])} ${timeFormat.year(dX[i])}`
            : `${timeFormat.month(dX[i])} ${timeFormat.year(dX[i])}`
        }
        return resolution === "quarterly"
          ? `Q${timeFormat.quarter(dX[i])}`
          : `${timeFormat.month(dX[i])}`
      }
      return timeFormat.year(dX[i])
    }

    // Get Axes
    const xAxis = d3
      .axisBottom(xScale)
      .tickValues(tickValues)
      .tickFormat(multiFormat)
      .tickSizeOuter(0)
    const yAxis = d3
      .axisLeft(yScale)
      .ticks(height / 40)
      .tickFormat((d) => `${d}%`)
    this.axes = { xAxis, yAxis }

    // Construct a line generator.
    const line = d3
      .line()
      .defined((i) => dO[i].turnover_rate !== null)
      .curve(this.curve)
      .x((i) => xScale(i))
      .y((i) => yScale(dY[i]))

    // Construct an area generator.
    const area = d3
      .area()
      .defined((i) => dO[i].turnover_rate !== null)
      .curve(this.curve)
      .x((i) => xScale(dI[i]))
      .y0(yScale(0))
      .y1((i) => yScale(dY[i]))

    this.generators = { line, area }
  }

  maybeUpdateLeftMargin() {
    let maxw = 0
    const marginBuffer = 10
    this.svg
      .select("#yAxis")
      .selectAll("text:not(#yLabel)")
      .each(function getWidth() {
        if (this.getBBox().width > maxw) maxw = this.getBBox().width
      })
    if (this.margin.left < maxw + marginBuffer) {
      this.margin.left = maxw + marginBuffer
      this.svg.remove()
      this.generateChartScaffold()
      this.createChart({ selector: this.selector })
    }
  }
}

export default LineChart
