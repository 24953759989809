const $ = window.jQuery
const Pjax = window.Pjax

$.onmount('#subordinate-settings-container input[type="checkbox"]', function loader() {
  $(this).on("click", (e) => {
    const $el = $(this).closest("[data-pjax-url]")
    $.pjax({
      push: false,
      scrollTo: false,
      url: $el.data("pjax-url"),
      type: "PUT",
      data: { succession_settings: { can_edit_subordinate_succession: this.checked } },
      fragment: Pjax.targetContainer($el.data("pjax-selector")),
      container: Pjax.targetContainer($el.data("pjax-selector")),
    })
    $.pjax.xhr = null
  })
})
