/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount("form.new_company[id*='new_company']", function () {
  const $datepicker = $(this).find("[data-widget=monthdaypicker]")

  return $datepicker.each(function () {
    this.picker = new Pikaday({
      field: this,
      format: "MMM DD",
      defaultDate: "Jan 01",
      setDefaultDate: true,
      theme: "hide-year",
    })
    this.picker.setDate($(this).val())

    return $(this).prop("picker", this.picker)
  })
})
