import { debounce } from "underscore"

const $ = window.jQuery
const searchRateLimit = window.rate_limit_search ? 1000 : 500

function pjaxTableSorter(table) {
  const DEFAULT_PAGE_SIZE = 50

  let sortKey
  let sortDirection
  let pageSize = DEFAULT_PAGE_SIZE
  let pjaxContainer
  let filter
  let $searchInput
  let $filterSelect
  let $pageSizeSelect
  let filterKeys = []

  const directions = {
    desc: "fa-sort-up",
    asc: "fa-sort-down",
    unsorted: "fa-sort",
  }

  const sortingClassList = Object.values(directions).join(" ")

  const newSortDirection = (element) => {
    const currentSortDirection = $(element).data("sort-direction")
    if (currentSortDirection) {
      return currentSortDirection === "asc" ? "desc" : "asc"
    }

    return "asc"
  }

  const helpers = {
    // Get current url with params added or replaced using values from
    // replacement hash.
    addOrReplaceUrlParams: (newParamsHash) => {
      const searchParams = new URLSearchParams(window.location.search)

      Object.keys(newParamsHash).forEach((key) => {
        searchParams.delete(key)
        if (newParamsHash[key]) {
          searchParams.set(key, newParamsHash[key])
        }
      })

      return `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`
    },

    urlParamValue: (key, defaultValue = "") =>
      new URLSearchParams(window.location.search).get(key) || defaultValue,
  }

  // Since the table controls can be outside of the table pjax container, their
  // values won't be updated with browser navigation changes.
  const syncTableControls = () => {
    const searchValue = helpers.urlParamValue("search")
    $searchInput.val(searchValue)
    const pageSizeValue = helpers.urlParamValue("page_size")
    if ($pageSizeSelect && pageSizeValue) {
      $pageSizeSelect.val(pageSizeValue)
    }
    if ($filterSelect) {
      let filterValue = ""
      filterKeys.forEach((key) => {
        filterValue = filterValue || helpers.urlParamValue(key)
      })

      $filterSelect.val(filterValue)
    }
  }

  // Make a new pjax request and replace the table with the result.
  const replaceTableRows = () => {
    if ($(table).parents(".modal").length > 0) {
      $("[name*=sort_key]").val(sortKey)
      $("[name*=sort_direction").val(sortDirection)

      $(table).parents("form").submit()
    } else {
      let replacementParams = {
        sort_key: sortKey,
        sort_direction: sortDirection,
        page_size: pageSize,
        page: 1,
      }

      // Reset all possible filter key params from filter dropdown
      filterKeys.forEach((key) => (replacementParams[key] = null))

      if ($searchInput.length) {
        replacementParams.search = $searchInput.val()
      }

      replacementParams = { ...replacementParams, ...filter }

      const url = helpers.addOrReplaceUrlParams(replacementParams)

      $.pjax({
        url,
        container: pjaxContainer,
        fragment: pjaxContainer,
      })
    }

    $(pjaxContainer).on("pjax:popstate", () => {
      syncTableControls()
      $.onmount("[data-pjax-sort]")
    })
  }

  const toggleSortDirection = (e) => {
    const $element = $(e.currentTarget)
    const $icon = $element.find("svg")
    const direction = newSortDirection($element)
    sortDirection = direction

    $icon.removeClass(sortingClassList)
    $element
      .siblings("th.sort[data-sort-key]")
      .data("sort-direction", null)
      .find("svg")
      .removeClass(sortingClassList)
      .addClass("fa-sort")

    $element.data("sort-direction", direction)
    $icon.addClass(directions[direction])
    sortKey = $element.data("sort-key")

    replaceTableRows()
  }

  const setPageSize = (value, initial = true) => {
    const initialPageSize = pageSize
    pageSize = parseInt(value, 10)
    if (isNaN(pageSize)) {
      pageSize = DEFAULT_PAGE_SIZE
    }
    if (!initial && pageSize !== initialPageSize) {
      replaceTableRows()
    }
  }

  const setupInitialSorting = () => {
    const $currentlySortedColumn = $(table)
      .find("th.sort[data-sort-key][data-sort-direction]")
      .first()
    if ($currentlySortedColumn) {
      sortKey = $currentlySortedColumn.data("sort-key")
      sortDirection = $currentlySortedColumn.data("sort-direction")
    }
  }

  const setupPageSelector = () => {
    const pageSizeSelector = $(table).data("page-size-selector")
    $pageSizeSelect = $(pageSizeSelector)

    if ($pageSizeSelect) {
      setPageSize($pageSizeSelect.val())
      $pageSizeSelect.off("change.pjax-tablesort")
      $pageSizeSelect.on("change.pjax-tablesort", () => setPageSize($pageSizeSelect.val(), false))
    }
  }

  const setFilter = () => {
    const $selectedOption = $filterSelect.find("option:selected").first()
    const key = $selectedOption.data("key")
    const value = $selectedOption.val()
    if (key && value) {
      filter = {}
      filter[key] = value
    } else {
      filter = null
    }
  }

  const setupFilterSelector = () => {
    const filterSelector = $(table).data("filter-select")
    $filterSelect = $(filterSelector)

    if ($filterSelect) {
      setFilter()
      filterKeys = $filterSelect.find("option[data=key]").val()
      const allFilterKeys = $filterSelect
        .find("option[data-key]")
        .map((_index, element) => $(element).data("key"))
        .get()
      filterKeys = $.uniqueSort(allFilterKeys)
      $filterSelect.off("change.pjax-tablesort")
      $filterSelect.on("change.pjax-tablesort", () => {
        setFilter()
        replaceTableRows()
      })
    }
  }

  const search = debounce(
    () => {
      replaceTableRows()
    },
    searchRateLimit,
    false,
  )

  function clearSearch() {
    if ($searchInput.length) {
      $searchInput.val("")
      $(this).addClass("hidden")
      replaceTableRows()
    }
  }

  const setupSearch = () => {
    const searchElementSelector = $(table).data("search-selector")
    $searchInput = $(searchElementSelector)
    let $checkSearchInput = $searchInput.val()
    if ($searchInput.length) {
      $searchInput.off("keyup.pjax-tablesort").on("keyup.pjax-tablesort", function (e) {
        if ($searchInput.val() !== $checkSearchInput) {
          $checkSearchInput = $searchInput.val()
          $searchInput.siblings(".suffix").toggleClass("hidden", $(this).val() === "")
          if (e.keyCode === 13) {
            replaceTableRows()
          } else {
            search()
          }
        }
      })
      $searchInput.on("focus", function handleFocus() {
        $(this)
          .off("mouseup.pjax-tablesort")
          .one("mouseup.pjax-tablesort", () => {
            $(this).select()
            return false
          })
          .one("mousedown", () => {
            $(this).off("mouseup.pjax-tablesort")
          })
          .select()
      })
      $searchInput
        .siblings(".prefix")
        .off("click.pjax-tablesort")
        .on("click.pjax-tablesort", search)
      $searchInput
        .siblings(".suffix")
        .off("click.pjax-tablesort")
        .on("click.pjax-tablesort", clearSearch)
    }
  }

  const initialize = () => {
    pjaxContainer = $(table).data("pjax-fragment-selector")
    if (!pjaxContainer) {
      return
    }
    $(table).find("th.sort[data-sort-key]").on("click.pjax-tablesort", toggleSortDirection)
    setupInitialSorting()
    setupPageSelector()
    setupFilterSelector()
    setupSearch()
    $(table).pjaxTableSort = this
  }

  initialize()
}

$.onmount("[data-pjax-sort]", ({ selector }) => {
  $(selector).each((_, el) => {
    pjaxTableSorter($(el))
  })
})
