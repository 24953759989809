$.onmount(".input-with-dropdown-selector", function () {
  const $container = $(this)
  const $dropdown = $container.find(".dropdown")

  $dropdown.find(".dropdown-menu-link").on("click", function (e) {
    e.preventDefault()
    $dropdown.find(".dropdown-menu-link").removeClass("active")

    const $target = $(e.currentTarget)
    $target.addClass("active")

    const $selectedIcon = $(this).find(".selection-icon")

    if ($selectedIcon.find(".icon-with-border").length > 0) {
      // Surgically replace the image src and title text in order to maintain
      // the correct styling for dropdowns that utilize the icon-with-border class.
      const imgSrc = $selectedIcon.find("img").attr("src")
      $dropdown.find(".dropdown-link .selection-icon img").attr("src", imgSrc)
      const titleText = $selectedIcon.find(".selection-title").text()
      $dropdown.find(".dropdown-link .selection-icon .text").text(titleText)
    } else if ($selectedIcon.find(".text-for-dropdown-link").length > 0) {
      // Surgically replace the title text in order to maintain consistent styling.
      const titleText = $selectedIcon.find(".text-for-dropdown-link").text()
      $dropdown.find(".dropdown-link .selection-icon .text").text(titleText)
    } else {
      $dropdown.find(".dropdown-link .selection-icon").replaceWith($selectedIcon.clone())
    }

    const selection = $target.data("selector-value")
    $container.find(".dropdown input[type=hidden]").val(selection).trigger("change")
    $dropdown.find(".dropdown-menu").hide()
  })
})
