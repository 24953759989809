const $ = window.jQuery

$.onmount(".todo-card-toggle", function () {
  $(this).click(() => {
    $(this).closest(".todo-card").toggleClass("todo-card-checked")

    const $form = $("form.edit_company")
    if ($form.length > 0) {
      const $complete = $form.find("#company_settings_account_setup_tasks_completed")
      const completeArr = []

      $(".todo-card-checked").each(function () {
        completeArr.push($(this).data("task"))
      })
      $complete.val(completeArr)

      $form.submit()
    }
  })
})
