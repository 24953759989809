const $ = window.jQuery

function saveCustomizeOpenState(id, state) {
  $.ajax({
    url: `/person_settings/${id}`,
    type: "PUT",
    data: {
      person_settings: {
        rails_settings_setting_object: {
          open: state ? 1 : 0,
        },
      },
    },
  })
}

$.onmount("[data-action=customize]", function () {
  $(this).click(function (event) {
    event.preventDefault()
    $(this).toggleClass("active")
    $(".customize-options").toggle()
    saveCustomizeOpenState($(this).data("id"), $(this).hasClass("active"))
  })

  $(".customize-close-icon").click(() => {
    $(".customize-options").hide()
    $("[data-action=customize]").removeClass("active")
    saveCustomizeOpenState($("[data-action=customize]").data("id"), false)
  })
})
