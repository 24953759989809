const $ = window.jQuery
const DropdownMenu = window.DropdownMenu

class NoteAttachmentRow {
  constructor($div) {
    this.$div = $div
  }

  static get dataFileNameAttr() {
    return "data-file-name"
  }
  static get dataFileTitleAttr() {
    return "data-file-title"
  }

  fileObject() {
    return {
      titleDiv: this.$div.find(".list-group-item-title"),
      fileTitleInput: this.$div.find("input[type=text]"),
      subTitleDiv: this.$div.find(".list-group-item-subtitle"),
      fileInput: this.$div.find(".input-file input[type=file]"),
      hasFile: this.$div.find(".input-group[data-current-file]").length > 0,
    }
  }

  addListeners() {
    this.$div.find(".dropdown .dropdown-link").on("click", (e) => {
      DropdownMenu.handleClick(e)
      DropdownMenu.handleVisibility(this.$div)
    })
  }

  fileSelected(e) {
    e.stopPropagation()
    e.preventDefault()
    const fileName = e.currentTarget.files[0].name
    const currentFile = this.fileObject()
    currentFile.fileTitleInput.val(fileName)
    currentFile.fileInput.attr(this.constructor.dataFileNameAttr, fileName)
  }

  static inputFieldValid(inputField) {
    return inputField.val().trim().length > 0
  }

  valid() {
    const currentFile = this.fileObject()
    return currentFile.hasFile || NoteAttachmentRow.inputFieldValid(currentFile.fileInput)
  }

  remove() {
    this.$div.remove()
  }

  toggleEdit() {
    const $visible = this.$div.find(".list-group-item").not(".hidden")
    this.$div.find(".list-group-item").filter(".hidden").removeClass("hidden")
    $visible.addClass("hidden")
  }

  static updateTitleDiv(currentFile) {
    let fileTitle = currentFile.fileTitleInput.val()
    if (fileTitle.length === 0) {
      fileTitle =
        currentFile.fileInput.attr(this.dataFileNameAttr) ||
        currentFile.subTitleDiv.attr(this.dataFileNameAttr)
      currentFile.fileTitleInput.val(fileTitle)
    }

    if (currentFile.titleDiv.find("a").length) {
      currentFile.titleDiv.find("a").text(fileTitle)
    } else {
      currentFile.titleDiv.find("span").text(fileTitle)
    }
    currentFile.titleDiv.attr(this.dataFileTitleAttr, fileTitle)
  }

  static updateSubTitleDiv(currentFile) {
    const fileName = currentFile.fileInput.attr(this.dataFileNameAttr)
    let currentFileName = currentFile.subTitleDiv.attr(this.dataFileNameAttr)

    if (fileName) {
      currentFileName = fileName
      currentFile.subTitleDiv.attr(this.dataFileNameAttr, fileName)
      currentFile.fileInput.removeAttr(this.dataFileNameAttr)
    }

    if (currentFile.titleDiv.attr(this.dataFileTitleAttr) !== currentFileName) {
      currentFile.subTitleDiv.text(currentFileName)
    } else {
      currentFile.subTitleDiv.text("")
    }
  }

  updateShowValues() {
    const currentFile = this.fileObject()

    NoteAttachmentRow.updateTitleDiv(currentFile)
    NoteAttachmentRow.updateSubTitleDiv(currentFile)
  }

  static errorDiv(message) {
    return `<div class="form-error">
              <div class="input-help-text form-error-message">${message}</div></div>`
  }

  showErrors() {
    const currentFile = this.fileObject()

    if (!NoteAttachmentRow.inputFieldValid(currentFile.fileInput)) {
      currentFile.fileInput
        .parent()
        .prepend(NoteAttachmentRow.errorDiv("no file selected".t("profile", "notes")))
    }
  }

  clearErrors() {
    this.$div.find(".form-error").remove()
  }

  saveRow() {
    this.clearErrors()
    if (this.valid()) {
      this.updateShowValues()
      this.toggleEdit()
      return true
    }
    this.showErrors()
    return false
  }

  discardChanges() {
    const currentFile = this.fileObject()

    currentFile.fileTitleInput.val(currentFile.titleDiv.attr(this.constructor.dataFileTitleAttr))
    currentFile.fileInput.removeAttr(this.constructor.dataFileNameAttr)
  }

  setFileIdAttributes(fileId) {
    this.$div.find(".input-file input[type=file]").attr({
      id: `file-id-${fileId}`,
      name: `note[file_uploads][${fileId}][file]`,
    })
    this.$div.find(".input-file label").attr("for", `file-id-${fileId}`)
    this.$div.find(".input-group input[type=text]").attr({
      id: `file-id-${fileId}`,
      name: `note[file_uploads][${fileId}][title]`,
    })
    this.$div.find("#file-title-").attr("for", `file-id-${fileId}`)
  }
}

class NoteAttachment {
  constructor($form) {
    this.$form = $form
    this.$fileList = this.$form.find(".file-uploads .list-group")
    this.$addFileButton = this.$form.find('[data-action="addFile"]')
    this.templates = {
      row: $form.find("#new-file-row-template").html(),
    }
  }

  static rowForButton(button) {
    return new NoteAttachmentRow($(button).closest("div[data-file-upload]"))
  }

  newFileId() {
    let fileId = Math.floor(Math.random() * 10000)
    while (this.$fileList.find(`#new-file-${fileId}`).length) {
      fileId += 1
    }
    return fileId
  }

  closeRow(button) {
    const row = NoteAttachment.rowForButton(button)

    if (window.currentNoteAttachment.$addFileButton.is(":hidden")) {
      this.$addFileButton.show()
      row.remove()
    } else {
      row.discardChanges()
      row.toggleEdit()
    }
  }

  saveRow(button) {
    const row = NoteAttachment.rowForButton(button)

    if (row.saveRow()) {
      this.$addFileButton.show()
    }
  }

  addRow() {
    const $html = $(this.templates.row).clone()
    const newFileRow = new NoteAttachmentRow($html)
    newFileRow.setFileIdAttributes(this.newFileId())
    newFileRow.addListeners()
    newFileRow.toggleEdit()
    this.$fileList.append(newFileRow.$div)
    this.$addFileButton.hide()
    return newFileRow
  }
}

window.NoteAttachment = NoteAttachment
window.NoteAttachmentRow = NoteAttachmentRow
