const $ = window.jQuery

const rowActionsSel = "tbody .import-matching-table__actions a"
const globalActionsSel = "thead .import-matching-table__actions a"

const actionFieldSel = 'input[name="import_model[action]"]'
const listActionFieldSel = 'input[name="list_action[action]"]'
const importableIdSel = 'input[name="import_model[importable_id]"]'

const importableAutocompleteSel = ".import-model-importable-autocomplete"

class ImportMatchingTable {
  constructor($el) {
    this.$table = $el
  }

  init() {
    this.$table
      .on("click", rowActionsSel, this.constructor.onImportActionBtnClicked)
      .on("click", globalActionsSel, this.constructor.onGlobalImportActionBtnClicked)
      .on("blur", importableAutocompleteSel, this.constructor.onImportableAutocompleteBlur)
      .on("autocomplete:selectSuggestion", (e, suggestion) =>
        this.constructor.onAutocompleteSelection(e, suggestion.data("id")),
      )
      .on("autocomplete:clearSelected", (e) => this.constructor.onAutocompleteSelection(e, ""))
      .on("pjax:end", (_) => this.initAutoMatches())

    this.initAutoMatches()
  }

  initAutoMatches() {
    this.$table.find('[data-action="match"].active').each((_, el) => {
      $(el).parent().find(importableAutocompleteSel).show()
    })
  }

  static onAutocompleteSelection(e, suggestionId) {
    const $el = $(e.target)
    $el.parent().find(importableIdSel).val(suggestionId)
  }

  static onImportableAutocompleteBlur(e) {
    const $el = $(e.target)
    const importableId = $el.parent().find(importableIdSel).val()
    if (!importableId) {
      $el.hide()
      $el.parent().find('[data-action="create_new"]').click()
    } else {
      $el.parent().submit()
    }
  }

  static onImportActionBtnClicked(e) {
    e.preventDefault()

    const $el = $(e.target).closest("a")
    const action = $el.data("action")

    $el.parent().find("a").not($el).removeClass("active")
    $el.addClass("active")

    $el
      .parent()
      .find(importableAutocompleteSel)
      .toggle(action === "match")
      .focus()
    $el.parent().find(actionFieldSel).val(action)

    if (action !== "match") {
      $el.parent().find(importableIdSel).val("")
      $el.parent().find(importableAutocompleteSel).val("")
      $el.parent().submit()
    }
  }

  static onGlobalImportActionBtnClicked(e) {
    e.preventDefault()

    const $el = $(e.target).closest("a")
    const $table = $el.closest("table")
    const $form = $el.closest("form")
    const action = $el.data("action")

    // Mark action buttons as 'active'
    $table.find(rowActionsSel).removeClass("active")
    $table.find(`${rowActionsSel}[data-action="${action}"]`).addClass("active")
    $table.find(globalActionsSel).removeClass("active")
    $table.find(`${globalActionsSel}[data-action="${action}"]`).addClass("active")

    // Clear auto complete
    $table.find(importableIdSel).val("")
    $table.find(importableAutocompleteSel).val("")
    $table.find(importableAutocompleteSel).hide()

    $form.find(listActionFieldSel).val(action)
    $form.submit()
  }
}

$.onmount(".import-matching-table", function onMount() {
  const table = new ImportMatchingTable($(this))
  table.init()
})
