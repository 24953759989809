const $ = window.jQuery

$(() => {
  $.onmount("[data-action=select-all]", (behavior) => {
    // Currently when this is used within a table that uses
    // webpack/v2/components/table_sort.js, the `setTimeout` in that file wipes
    // out the event listener here. Because of this, we need to ensure that this
    // event listener is attached subsequent to table_sort running.
    const hasTableSortAncestor = $(behavior.selector).parents("table[data-sort]").length > 0
    const delay = hasTableSortAncestor ? window.REACT_MOUNT_DELAY : 0

    setTimeout(() => {
      $(behavior.selector).on("change", (e) => {
        const name = $(e.currentTarget).prop("name") || $(e.currentTarget).data().name
        $(`[name='${name}']`).prop("checked", $(e.currentTarget).prop("checked"))
        const $form = $(e.currentTarget).parents("form")
        const count = $form.find(
          "input[type=checkbox]:checked:not([data-action=select-all])",
        ).length
        $("[data-marker=count]").text(count)
        $form.trigger("checkbox:select-all:click")
      })
    }, delay)
  })
})
