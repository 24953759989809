const { jQuery: $, ButtonHelpers, PikadayInput, ProfileTimeSheets, ProfileTimeEntries } = window

$.onmount("#person_employee_status_id", function () {
  $("#termination-warning, #leave-warning, .termination_position_effect").hide()
  $(this).change((e) => {
    const isHumanEvent = e.originalEvent !== undefined
    const isTerminatedStatus =
      $(this)
        .find(":selected")
        .data("system-type")
        .match(/terminat/) !== null
    const isLeaveStatus = $(this).find(":selected").data("system-type") === "on_leave"

    $("#termination_date, #termination_reason, .termination_position_effect").toggle(
      isTerminatedStatus,
    )
    $("#termination-warning").toggle(isHumanEvent && isTerminatedStatus)
    $("#leave-warning").toggle(isHumanEvent && isLeaveStatus)
  })
  $(this).trigger("change")
})

$.onmount("#position_reports_to_name", function () {
  $(this).on("autocomplete:selectSuggestion", (obj, $selected) => {
    $("#position_parent_id").val($selected.data("id"))
  })
})

$.onmount("[data-pjax-container=profile] [data-action=upload-document]", function () {
  $(this).change(() => $(this).closest("form").submit())
})

$.onmount("#onboard-dependents", () => {
  $.cocoon()

  $("#onboard-dependents").on("cocoon:after-insert", (e, item) => {
    new PikadayInput(item.find('[data-widget="datepicker"]')).create()
  })

  $("#person_no_dependents").on("click", () => {
    $("#onboard-dependents").toggle()
  })
})

$.onmount("#time-entries .table.timesheets", function initTimeSheet() {
  window.timesheet = {}
  window.timesheet.current = new ProfileTimeSheets($(this).parent())
  ProfilePtos.registerModalMounts()
})

$.onmount("[data-modal=time-entries-detail]", function initTimeEntriesModal() {
  window.timesheet.timeEntriesModal = new ProfileTimeEntries(this)
  $(this).one("modal:save", () => {
    window.timesheet.current.reloadPage()
    window.timesheet.timeEntriesModal = null
  })
})

$.onmount(Pjax.targetContainer("profile-pto"), function initProfilePto() {
  window.pto = new ProfilePtos($(this))
})

$.onmount(Pjax.targetContainer("pto-approval-requests"), () => {
  ProfilePtos.registerModalMounts()
})

class Profile {
  static reloadProfileHeader($form) {
    let personId = $form.find("[name='person[id]']").val()

    if (!personId) {
      personId = $form.find("[name='person_id']").val()
    }

    if (!personId) {
      personId = $form.find("[name='person_message[person_id]']").val()
    }

    if (personId) {
      return $.pjax({
        url: `/people/${personId}/header`,
        container: "[data-pjax-container=profile-header-info]",
        fragment: "[data-pjax-container=profile-header-info]",
        push: false,
        scrollTo: false,
      })
    }

    return -1
  }

  static submitForm(e) {
    ButtonHelpers.setButtonState($(e.relatedTarget), "disabled")
  }
}

window.Profile = Profile
