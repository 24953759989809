/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const { Subform } = window

$(document).on("append-subform", function (_e, item, type) {
  if ($(item).is(Subform.selector("file-upload"))) {
    const vars = {
      $fileInputClone: undefined,
    }

    const constants = {
      $item: $(item),
      $fileContainer: $(item).closest(".file-container"),
      $showFileUploadTemplate: $("#show-file-upload-template"),
      $newFormFileUploadTemplate: $("#new-form-file-upload-template"),
      $editFormFileUploadTemplate: $("#edit-form-file-upload-template"),
      allowedFileTypes: [
        "jpg",
        "jpeg",
        "gif",
        "png",
        "pdf",
        "doc",
        "docx",
        "xls",
        "xlsx",
        "ppt",
        "pptx",
        "txt",
        "rtf",
        "odt",
        "ods",
        "odp",
        "tif",
        "tiff",
        "bmp",
        "csv",
      ],
      $chooseFileBtn() {
        return $(item).find(".input-file .btn--large")
      },
      $fileInputStub() {
        return $(item).find('[name="benefit_plan[file_uploads][][file]"][type=hidden]')
      },
      $fileInput() {
        return $(item).find('[name="benefit_plan[file_uploads][][file]"][type=file]')
      },
      $fileUploadTitle() {
        return $(item).find('[name="benefit_plan[file_uploads][][title]"]')
      },
      $fileUploadId() {
        return $(item).find('[name="benefit_plan[file_uploads][][id]"]')
      },
      $fileUploadSubtitle() {
        return $(item).find(".subtitle")
      },
      $fileUploadPreview() {
        return $(item).find(".preview")
      },
      $fileUploadCurrentFile() {
        return $(item).find("[data-pjax-click=preview]")
      },
      $listGroupItem() {
        return $(item).find(".list-group-item")
      },
    }

    var functions = {
      removeSubformHandlers() {
        constants.$item.off("subform:close")
        return constants.$item.off("subform:save")
      },

      initSubform() {
        constants.$chooseFileBtn().on("click", functions.fileBtnClick)
        constants.$fileInput().on("change", functions.uploadFile)
        constants.$item.on("subform:close", functions.close)
        return constants.$item.on("subform:save", functions.submit)
      },

      fileBtnClick() {
        return constants.$fileInput().trigger("click")
      },

      uploadFile() {
        functions.clearErrors()
        const filename = constants.$fileInput().val().split("\\").pop()
        const extension = filename.split(".").pop()

        if ($.inArray(extension, constants.allowedFileTypes) === -1) {
          const $inputGroup = constants.$item.find("[data-subform-field=file] .input-group")
          $inputGroup.addClass("form-error")
          $inputGroup
            .children("label")
            .after("<div class='input-help-text form-error-message'>Invalid file type</div>")
          constants.$fileInput().val(null)
          return constants.$chooseFileBtn().find(".btn-name").text("Choose File")
        } else {
          constants.$chooseFileBtn().find(".btn-name").text(filename)
          if (constants.$fileUploadTitle().last().val().length === 0) {
            return constants.$fileUploadTitle().val(filename)
          }
        }
      },

      clearErrors: () => {
        constants.$item.find(".form-error").removeClass("form-error")
        return constants.$item.find(".form-error-message").remove()
      },

      close() {
        if (constants.$item.hasClass("new")) {
          constants.$item.remove()
          functions.showBtn()
          return constants.$item.removeClass("new")
        } else {
          let subtitle
          constants.$fileInput().replaceWith(vars.$fileInputClone)

          constants.$listGroupItem().empty()
          const title = constants.$fileUploadTitle().val()

          if (constants.$fileInput().val() !== "") {
            subtitle = constants.$fileInput().val().split("\\").pop()
            constants.$fileUploadSubtitle().data("subtitle", subtitle)
          } else {
            ;({ subtitle } = constants.$fileUploadSubtitle().data())
          }
          constants
            .$listGroupItem()
            .append(_.template(constants.$showFileUploadTemplate.html())({ title, subtitle }))

          const $dropdownLinks = constants.$item.find(".dropdown .dropdown-link")
          $dropdownLinks.each(function () {
            return $(this).on("click", (e) => DropdownMenu.handleClick(e))
          })
          DropdownMenu.handleVisibility(constants.$item.closest(".modal"))
          constants.$item.find("[data-action=edit-file]").on("click", functions.edit)
          constants.$item.find("[data-action=delete-file]").on("click", functions.delete)

          if (constants.$fileUploadPreview().data()) {
            constants
              .$fileUploadCurrentFile()
              .attr("href", constants.$fileUploadPreview().data().url)
            constants.$fileUploadCurrentFile().on("click", window.Pjax.dataPjaxClick)
          } else {
            constants.$fileUploadCurrentFile().attr("href", "#")
          }

          return functions.removeSubformHandlers()
        }
      },

      showBtn() {
        return constants.$fileContainer.find(".add-btn").show()
      },

      hideBtn() {
        return constants.$fileContainer.find(".add-btn").hide()
      },

      edit() {
        vars.$fileInputClone = constants.$fileInput().clone()
        const title = constants.$fileUploadTitle().val()
        let url = undefined

        if (constants.$fileUploadPreview().data()) {
          ;({ url } = constants.$fileUploadPreview().data())
        }
        constants.$listGroupItem().empty()

        if (constants.$fileUploadId().length > 0 && constants.$fileInputStub().length > 0) {
          const { subtitle } = constants.$fileUploadSubtitle().data()
          constants
            .$listGroupItem()
            .append(_.template(constants.$editFormFileUploadTemplate.html())({ subtitle }))
        } else {
          constants.$listGroupItem().append(_.template(constants.$newFormFileUploadTemplate.html()))
        }

        if (url) {
          constants.$fileUploadCurrentFile().attr("href", url)
          constants.$fileUploadCurrentFile().on("click", window.Pjax.dataPjaxClick)
        } else {
          constants.$fileUploadCurrentFile().attr("href", "#")
        }

        constants.$fileUploadTitle().val(title)
        functions.initSubform()
        return constants.$item.trigger("subform:open")
      },

      delete() {
        return constants.$item.remove()
      },

      submit() {
        let subtitle
        const title = constants.$fileUploadTitle().last().val()

        if (constants.$fileInput().val() !== "") {
          subtitle = constants.$fileInput().val().split("\\").pop()
          constants.$fileUploadSubtitle().data("subtitle", subtitle)
        } else {
          ;({ subtitle } = constants.$fileUploadSubtitle().data())
        }

        constants.$fileUploadTitle().val(title)
        constants.$listGroupItem().empty()
        constants
          .$listGroupItem()
          .append(_.template(constants.$showFileUploadTemplate.html())({ title, subtitle }))

        const $dropdownLinks = constants.$item.find(".dropdown .dropdown-link")
        $dropdownLinks.each(function () {
          return $(this).on("click", (e) => DropdownMenu.handleClick(e))
        })
        DropdownMenu.handleVisibility(constants.$item.closest(".modal"))
        constants.$item.find("[data-action=edit-file]").on("click", functions.edit)
        constants.$item.find("[data-action=delete-file]").on("click", functions.delete)

        if (constants.$fileInput().val() === "") {
          const { url } = constants.$fileUploadPreview().data()
          constants.$fileUploadCurrentFile().attr("href", url)
          constants.$fileUploadCurrentFile().on("click", window.Pjax.dataPjaxClick)
        } else {
          constants.$fileUploadCurrentFile().attr("href", "#")
        }

        functions.showBtn()
        constants.$item.removeClass("new")
        constants.$fileInputStub().remove()
        return functions.removeSubformHandlers()
      },
    }

    functions.initSubform()
    if (type === "new") {
      functions.hideBtn()
      constants.$item.addClass("new")
      return constants.$item.trigger("subform:open")
    } else if (type === "exist") {
      constants.$item.find("[data-action=edit-file]").on("click", functions.edit)
      return constants.$item.find("[data-action=delete-file]").on("click", functions.delete)
    }
  }
})
