const $ = window.jQuery
const ButtonHelpers = window.ButtonHelpers

class Company {
  static saveJobsPublicOrder(publicOrderUrl) {
    const order = $("ul.public-order").sortable("toArray", { attribute: "data-sort" })
    const orderUrlParams = order.map((jobId) => `sort_order[]=${jobId}`).join("&")
    const $targetForm = $("#save-public-order").closest("form")

    ButtonHelpers.setButtonState($targetForm, "disabled")

    return $.pjax({
      url: `${publicOrderUrl}?${orderUrlParams}`,
      type: "PUT",
      container: ".public-order",
      fragment: ".public-order",
      push: false,
    })
  }
}

window.Company = Company
