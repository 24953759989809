/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount("[data-uploader=company-logo]", function () {
  $(this).on("image:file:error", (_e, _uploader) =>
    alert("An error occurred. Please select your logo again."),
  )

  return $(this).on("image:upload:error", (_e, _uploader, _xhr, _status, _error) =>
    alert("An error occurred. Please select your logo again."),
  )
})
