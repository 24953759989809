/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount(Modal.selector("pdf-field"), function () {
  const $modal = $(this)

  $modal
    .find("#pdf-field-person-attribute")
    .on("change", (e) => $modal.find("[data-action=close] svg").trigger("click"))

  return $modal.on("modal:close", function (_e, _modal) {
    const personAttribute = $modal.find("option:selected").val()
    const $form = $(`#${$modal.find("#pdf-field-person-attribute").data("form-id")}`)
    $form.find("#pdf_field_person_attribute").val(personAttribute)
    return $form.trigger("submit")
  })
})
