/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount("form.new_pto_type", function () {
  const functions = {
    selectAll() {
      const $icon = $('[data-action="toggle-visibility"] svg')
      const $iconCheckbox = $("[name$=\\[visible\\]]")
      const $checkbox = $("[name$=\\[exists\\]]")

      if ($('[data-action="select-all"]')[0].checked) {
        $checkbox.prop("checked", true)
        $iconCheckbox.prop("checked", true)
        return $icon.addClass("fa-eye").removeClass("disabled")
      } else {
        $checkbox.prop("checked", false)
        $iconCheckbox.prop("checked", false)
        return $icon.addClass("fa-eye-slash disabled")
      }
    },

    toggleVisibility() {
      const $listItem = $(this).closest(".list-group-item")
      const $icon = $listItem.find('[data-action="toggle-visibility"] svg')
      const $iconCheckbox = $listItem.find("[name$=\\[visible\\]]")
      const $checkbox = $listItem.find("[name$=\\[exists\\]]")

      if ($checkbox[0].checked) {
        if ($icon.hasClass("disabled")) {
          $iconCheckbox.prop("checked", true)
          return $icon.addClass("fa-eye").removeClass("disabled")
        } else {
          $iconCheckbox.prop("checked", false)
          return $icon.addClass("fa-eye-slash disabled")
        }
      } else {
        $iconCheckbox.prop("checked", false)
        return $icon.addClass("fa-eye-slash disabled")
      }
    },

    allVisible() {
      const $checkbox = $("[name$=\\[exists\\]]:checked")
      const $listItem = $checkbox.closest(".list-group-item")
      const $icon = $listItem.find('[data-action="toggle-visibility"] svg')
      const $iconCheckbox = $listItem.find("[name$=\\[visible\\]]")

      $iconCheckbox.prop("checked", true)
      return $icon.addClass("fa-eye").removeClass("disabled")
    },

    noneVisible() {
      const $icon = $('[data-action="toggle-visibility"] svg')
      const $iconCheckbox = $("[name$=\\[visible\\]]")

      $iconCheckbox.prop("checked", false)
      return $icon.addClass("fa-eye-slash disabled")
    },
  }

  $(this).find('[data-action="select-all"]').on("change", functions.selectAll)
  $(this).find('[data-action="toggle-visibility"]').on("click", functions.toggleVisibility)
  $(this).find('[name$="\\[exists\\]"]').on("change", functions.toggleVisibility)
  $(this).find('[data-action="all-visible"]').on("click", functions.allVisible)
  return $(this).find('[data-action="none-visible"]').on("click", functions.noneVisible)
})
