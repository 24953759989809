import { createPopper } from "@popperjs/core"
import { select } from "d3"

const $ = window.jQuery

$.onmount("[data-use-popper-tooltip]", function mountTooltip() {
  const selection = select(this)
  const tooltipContent = select(selection.attr("data-use-popper-tooltip"))
  const newParent = $(selection.attr("data-use-popper-tooltip")).parents(
    ".modal, .content, #content",
  )

  // Remove tooltip if it already exists
  newParent.children(selection.attr("data-use-popper-tooltip")).remove()

  // Tooltip needs to be moved outside of the widget so that it doesn't get cut off
  $(selection.attr("data-use-popper-tooltip")).appendTo(newParent)

  const popper = createPopper(this, tooltipContent.node(), {
    placement: selection.attr("data-popper-placement") || "bottom",
    modifiers: [{ name: "offset", options: { offset: [0, 10] } }],
  })

  function show() {
    tooltipContent.attr("data-show", "")

    popper.setOptions(({ modifiers, ...options }) => ({
      ...options,
      modifiers: [...modifiers, { name: "eventListeners", enabled: true }],
    }))

    popper.update()
  }

  function hide() {
    tooltipContent.attr("data-show", null)

    popper.setOptions(({ modifiers, ...options }) => ({
      ...options,
      modifiers: [...modifiers, { name: "eventListeners", enabled: false }],
    }))
  }

  const showEvents = ["mouseenter", "focus"]
  const hideEvents = ["mouseleave", "blur"]

  showEvents.forEach((evKey) => {
    selection.on(evKey, show)
  })

  hideEvents.forEach((evKey) => {
    selection.on(evKey, hide)
  })
})
