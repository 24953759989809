/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount(".year .months td.day", function () {
  return $(this).on("click", function (e) {
    const $dispatchEl = $(this).closest("[data-pjax-url]")
    const url = $dispatchEl.data().pjaxUrl
    const selector = Pjax.targetContainer("modal")

    return $.pjax({
      url,
      container: selector,
      push: false,
      scrollTo: false,
    })
  })
})

$.onmount(".year", function () {
  const $year = $(this)

  const functions = {
    updateYearLinks($prev, $next, node) {
      const data = $(node).data()
      const { year } = data
      const url = node.href
      const newPrevUrl = url.replace(year, year - 1)
      const newNextUrl = url.replace(year, year + 1)

      $prev.prop("href", newPrevUrl)
      $prev.data("year", year - 1)
      $next.prop("href", newNextUrl)
      return $next.data("year", year + 1)
    },
  }

  $.onmount(".previous", function () {
    return $(this).on("pjax:started", function () {
      const $next = $year.find(".next")

      return functions.updateYearLinks($(this), $next, this)
    })
  })

  return $.onmount(".next", function () {
    return $(this).on("pjax:started", function () {
      const $prev = $year.find(".previous")

      return functions.updateYearLinks($prev, $(this), this)
    })
  })
})
