import { isEmpty, isString } from "lodash"

/**
 * Tries to parse a non-empty string that represents JSON; coerces any value
 * that is not a non-empty string to the fallback.
 *
 * @example
 *
 *     parseIfNonEmptyOrReturn(''); //=> null
 *     parseIfNonEmptyOrReturn('{"key": "value"}'); //=> {key: 'value'}
 *     parseIfNonEmptyOrReturn(undefined); //=> null
 *     parseIfNonEmptyOrReturn(undefined, []); //=> []
 *
 * @param {null | string | undefined} maybeString
 * @param {*} fallback
 * @returns {*}
 * @private
 */
const parseIfNonEmptyOrReturn = (maybeString, fallback = null) =>
  isString(maybeString) && !isEmpty(maybeString) ? JSON.parse(maybeString) : fallback

export default parseIfNonEmptyOrReturn
