const $ = window.jQuery
const Modal = window.Modal
const SelectedListGroup = window.SelectedListGroup

$.onmount(Modal.selector("pto-awaiting-approval"), function () {
  $("input[name=add_notifiers]").on("click", function () {
    if (this.checked) {
      $(this.form).attr("data-follow-up-form", true)
      $(this.form).attr("data-modal-class", "modal")
    } else {
      $(this.form).removeAttr("data-follow-up-form")
      $(this.form).removeAttr("data-modal-class")
    }
  })

  const modal = new Modal(this)
  modal.$el.on("modal:save", (_e, modal) => {
    modal.reloadTargetContainer()
  })

  modal.$el.on("modal:followUpForm", () => {
    modal.$el.find('[data-action="close"]').on("click", () => {
      modal.close()
    })

    modal.$el.find('[data-action="send"]').on("click", (e) => {
      modal.clearError()

      if (modal.$el.find(".person-list div").length === 0) {
        modal.showError("recipient is missing".t("profile", "pto_request"))
        e.preventDefault()
        e.stopPropagation()
      }
    })
  })
})

$.onmount(".pto-request [data-autocomplete=person]", function () {
  const peopleList = new SelectedListGroup($(this.form).find("div.person-list"), "recipients[]")

  $(this).on("autocomplete:selectSuggestion", function (obj, $selected) {
    const personId = $selected.data("id")
    const personName = $selected.find(".list-group-item-title").text().trim()

    $.when(peopleList.addListItem(personName, personId)).done(() => {
      $(this).val("")
    })
  })
})
