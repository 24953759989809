/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount("form[id*='new_chart_section']", function () {
  const $additionalForm = $("#add_new_head_form")

  const showForm = function () {
    $additionalForm.show()
    $("#chart_section_new_head").val("true")
    return $("#chart_section_position_id").val("")
  }

  const hideForm = function () {
    $additionalForm.hide()
    $("#chart_section_new_head").val("false")
    $("#chart_section_position_position_type_id").val("")
    $("#chart_section_position_position_type_title").val("")
    $("#chart_section_position_parent").val("")
    return $("#chart_section_position_parent_id").val("")
  }

  $(this).on("autocomplete:selectSuggestion", function (obj, $selected) {
    const receivedId = $selected.data("id")
    const autocompleteInputNameId = $(obj.target).data("id")
    $(`#${autocompleteInputNameId}`).val(receivedId)
    if (autocompleteInputNameId === "chart_section_position_id") {
      return hideForm()
    }
  })

  $(this).on("autocomplete:renderSuggestions", function () {
    $("#show-add-new-position-form").mousedown(showForm)

    return $("#hide-add-new-position-form").mousedown(function () {
      $("#chart_section_position_name").val("")
      return hideForm()
    })
  })

  return $("#chart_section_chart_id").change(function () {
    $("#chart_section_position_name").val("").data("chart-id", $(this).val())
    $("#chart_section_position_id").val("")
    $("#chart_section_position_parent").val("").data("chart-id", $(this).val())
    return $("#chart_section_position_parent_id").val("")
  })
})
