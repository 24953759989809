/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
class ColoredLabelEditor {
  constructor($el) {
    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
    this._wireEvents = this._wireEvents.bind(this)
    this._setUpColorPicker = this._setUpColorPicker.bind(this)
    this._checkClick = this._checkClick.bind(this)
    this._color = this._color.bind(this)
    this._onSave = this._onSave.bind(this)
    this.$el = $el
    this._wireEvents()
    this._setUpColorPicker()
  }

  show() {
    if (!this.binded) {
      this._topLevelContainer().on("click", (e) => this._checkClick(e))
    }
    this.binded = true
    return this.$el.show()
  }

  hide() {
    return this.$el.hide()
  }

  _topLevelContainer() {
    if ($(".modal-overlay").length) {
      return $(".modal-overlay")
    } else {
      return $("body")
    }
  }

  _wireEvents() {
    this.$el.on("click", ".a-close", () => this.hide())
    return this.$el.on("click", "[data-action=save]", (e) => this._onSave(e))
  }

  _setUpColorPicker() {
    return this.$el.find("[data-widget=color-palette]").colorPalette()
  }

  _checkClick(e) {
    const $target = $(e.target)
    const isWidget = $target.className === "colored-label-editor-wrapper"
    const isInsideWidget = $target.parents(".colored-label-editor-wrapper").length
    const isMyEditBtn = $target.closest(".actions")[0] === this.$el.parent().find(".actions")[0]
    if (!isWidget && !isInsideWidget && !isMyEditBtn) {
      return this.hide()
    }
  }

  _color() {
    return this.$el.find("[data-widget=color-palette]").val()
  }

  _onSave(e) {
    e.preventDefault()
    this.$el.trigger("colored-label-editor::save", { color: this._color() })
    return this.hide()
  }
}

$.onmount(".with-color-picker", function () {
  const $btn = $(this)

  const $colorPickerEl = $btn.parent().find(".colored-label-editor-wrapper")
  const colorPickerWidget = new ColoredLabelEditor($colorPickerEl)
  $colorPickerEl.on("colored-label-editor::save", function (e, payload) {
    $btn.css("background-color", payload.color)
    return $btn.css("border-color", payload.color)
  })

  return $btn.on("click", function (e) {
    e.preventDefault()
    return colorPickerWidget.show()
  })
})
