const $ = window.jQuery
const Modal = window.Modal

$.onmount("#permanent-checkbox", () => {
  $("#permanent-checkbox").change((e) => {
    $("#remove-button").prop(
      "value",
      e.target.checked ? "Permanently Delete Position" : "Remove Position",
    )
    $("#permanent").prop("value", e.target.checked)
    $("#remove-button").attr("data-action", e.target.checked ? "delete" : "remove")
    $("#remove-warning").html(
      e.target.checked ? "#{t('.headcount_perm_delete_error')}" : "#{t('.headcount_remove_error')}",
    )
  })
})
