/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
class FileUploadTable {
  constructor(file_field) {
    this.addFileToTable = this.addFileToTable.bind(this)
    this.data = {}

    this.file_field = $(file_field)
    this.container = this.file_field.parent(".input-file")
    this.table = this.container.siblings(".table")

    this.type = this.file_field.data("uploadable-type")
    this.type_lower = this.type.toLowerCase()

    if (this.file_field.data("uploadable-id")) {
      this.data = {
        file_uploadable_type: this.type,
        file_uploadable_id: this.file_field.data("uploadable-id"),
      }
    }

    this.addEvents()
  }

  addEvents() {
    this.table.find("[data-action=delete]").click((e) => {
      e.preventDefault()
      const $link = $(e.currentTarget)
      const $input = this.container.find(`input[value=${$link.data("id")}]`)
      $input.attr("name", `${this.type_lower}[deleted_file_upload_ids][]`)
      return $link.parents("tr").remove()
    })

    this.file_field.fileupload({
      dataType: "json",
      formData: this.data,
      done: this.addFileToTable,
      fail: (e, data) => {
        const error_msg = data.response().jqXHR.responseJSON.error
        return this.container.addClass("form-error")
          .prepend(`<div class='input-help-text form-error-message'> \
${error_msg} \
</div>`)
      },
    })

    return this.container.click(() => {
      this.container.removeClass("form-error")
      return this.container.find(".form-error-message").remove()
    })
  }

  addFileToTable(e, data) {
    const { filename } = data.result.file
    const preview_url = `/previews/${data.result.preview_id}`
    const file_upload_id = data.result.id
    const filetype = data.result.file.filename.extension()
    const date = data.result.updated_at.l()

    const $row = $(`\
<tr data-pjax-click='preview'> \
<td><a href='${preview_url}'>${filename}</a></td> \
<td>${filetype}</td><td>${date}</td> \
<td> \
<a href='#' data-action='delete'> \
<i class='far fa-trash-alt'></i> \
</a> \
</td> \
</tr>\
`)
    this.table.find("tbody").append($row)

    const $input = $(`\
<input \
type='hidden' \
name='${this.type_lower}[file_upload_ids][]' \
value='${file_upload_id}' \
>\
`)
    this.container.append($input)

    $row.find("[data-action=delete]").click(function (e) {
      e.preventDefault()
      $row.remove()
      return $input.attr("name", `${this.type_lower}[deleted_file_upload_ids][]`)
    })

    return $.onmount()
  }
}

$.onmount(".file-upload", function () {
  return new FileUploadTable(this)
})
