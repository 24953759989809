const $ = window.jQuery

$.onmount(".toggle", function setupToggle() {
  $(this).click(function handleToggleClick(e) {
    e.preventDefault()

    const $toggle = $(this)
    $toggle.toggleClass("is-on")
    $toggle.siblings("input[type=hidden]").val($toggle.hasClass("is-on")).trigger("change")
  })
})
