import * as d3 from "d3"

const $ = window.jQuery

const percentToDegree = (p) => p * 180

const defaultProps = {
  margin: {
    left: 10,
    right: 10,
  },
  fontSize: "0.6875rem",
  fontFamily: "Satoshi",
  fontColor: "rgba(012, 020, 075, 0.64)",
  useAnimation: true,
  duration: 750, // ms
  transitionNumber: true,
}

class GaugeChart {
  constructor(props) {
    this.selector = props.selector
    this.height = $(this.selector).parent().height()
    this.width = this.height * (1 / 0.6)
    this.outerRadius = this.width / 2
    this.innerRadius = this.outerRadius * 0.62
    Object.assign(this, defaultProps, props)

    this.svg = d3
      .select(this.selector)
      .append("svg")
      .attr("class", "gauge-chart-svg")
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height)
    this.group = this.svg.append("g")
  }

  render(p) {
    const gradient = this.svg
      .append("defs")
      .append("linearGradient")
      .attr("id", "gauge-chart__gradient")
    const arc = d3.arc()

    gradient.append("stop").attr("offset", "0%").attr("class", "gauge-chart__gradient-stop1")
    gradient.append("stop").attr("offset", "50%").attr("class", "gauge-chart__gradient-stop2")
    gradient.append("stop").attr("offset", "100%").attr("class", "gauge-chart__gradient-stop3")

    arc
      .innerRadius(this.innerRadius)
      .outerRadius(this.outerRadius)
      .startAngle(-Math.PI / 2)
      .endAngle(Math.PI / 2)

    this.group
      .attr("width", this.width)
      .attr("height", this.height)
      .append("path")
      .attr("d", arc)
      .attr("fill", "url(#gauge-chart__gradient)")
      .attr("transform", `translate(${this.outerRadius + this.margin.left},${this.outerRadius})`)

    this.renderNeedle(p, {
      length: this.outerRadius * 0.75,
      radius: this.innerRadius * 0.17,
      x: this.outerRadius,
      y: this.outerRadius,
    })

    this.group
      .append("text")
      .attr("class", "regular")
      .style("font", `${this.fontSize} ${this.fontFamily}`)
      .attr("fill", this.fontColor)
      .attr("x", this.margin.left + (this.outerRadius - this.innerRadius) / 2)
      .attr("y", this.height - this.height * 0.03)
      .attr("text-anchor", "middle")
      .text("Weak".t("dashboard"))

    this.group
      .append("text")
      .attr("class", "regular")
      .style("font", `${this.fontSize} ${this.fontFamily}`)
      .attr("fill", this.fontColor)
      .attr("x", this.width + this.margin.left - (this.outerRadius - this.innerRadius) / 2)
      .attr("y", this.height - this.height * 0.03)
      .attr("text-anchor", "middle")
      .text("Strong".t("dashboard"))
  }

  renderNeedle(p, props) {
    this.needleGroup = this.svg.append("g")
    this.needleLength = props.length
    this.needleRadius = props.radius
    this.needleX = props.x
    this.needleY = props.y

    this.needleGroup
      .append("circle")
      .attr("class", "gauge-chart__needle-base")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", this.needleRadius)

    this.needleGroup
      .append("path")
      .attr("class", "gauge-chart__needle")
      .attr("rx", 5)
      .attr("d", this.getNeedlePath(p))

    this.needleGroup
      .attr("transform", `translate(${this.needleX + this.margin.left},${this.needleY}) rotate(0)`)
      .transition()
      .duration(this.useAnimation ? this.duration : 0)
      .attr(
        "transform",
        `translate(${this.needleX + this.margin.left},${this.needleY}) rotate(${percentToDegree(
          p,
        )})`,
      )

    if (this.transitionNumber && this.useAnimation) {
      const gaugeNumber = this.numberSelection
      gaugeNumber.property("currentVal", 0)
      const format = d3.format(".0%")
      gaugeNumber
        .datum(p)
        .transition()
        .duration(this.duration)
        .textTween(function tween(d) {
          const i = d3.interpolate(this.currentVal, d)
          return function getVal(t) {
            this.currentVal = i(t)
            return format(this.currentVal)
          }
        })
        .end()
    }
  }

  getNeedlePath() {
    const tipRadius = this.needleRadius / 5
    const centerX = 0
    const centerY = 0
    const topX = centerX - this.needleLength
    const topY = centerY
    const leftX = centerX
    const leftY = centerY + this.needleRadius
    const rightX = centerX
    const rightY = centerY - this.needleRadius

    return `M ${leftX} ${leftY} L ${topX + tipRadius} ${
      topY + tipRadius
    } A ${tipRadius},${tipRadius} 0 0 1 ${topX + tipRadius},${
      topY - tipRadius
    } L ${rightX} ${rightY}`
  }
}

export default GaugeChart
