/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount('#required-fields input[type="checkbox"]', function () {
  return $(this).on("click", function (e) {
    const $dispatchEl = $(this).closest("[data-pjax-url]")
    const containerName = $dispatchEl.data("pjax-selector")
    const url = $dispatchEl.data("pjax-url")
    const selector = Pjax.targetContainer(containerName)
    const options = { container: selector, url, scrollTo: false }
    if ($dispatchEl.data("pjax-push") === false) {
      options.push = false
    }
    if ($dispatchEl[0].hasAttribute("data-pjax-type")) {
      options.type = $dispatchEl.data("pjax-type")
    }
    if ($dispatchEl[0].hasAttribute("data-pjax-fragment")) {
      options.fragment = selector
    }

    $.pjax(options)
    return ($.pjax.xhr = null)
  })
})
