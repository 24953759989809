/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount("#lump, #periodic, #hours-worked", function () {
  const constants = {
    carryOver: $("#carry-over-template").html(),
    accrualTier: $("#accrual-tier-template").html(),
    carryOverCount: $(".carry-over-rows").data("count"),
    accrualTierCount: $(".accrual-tiers").data("count"),
    indexPlaceholder: RegExp("_next_tier_index_", "g"),
  }

  var functions = {
    removeTier() {
      const $listGroupItem = $(this).closest(".list-group-item")
      return $listGroupItem.slideUp("normal", function () {
        $listGroupItem.remove()

        const $additionalAccrualTiers = $("#additional-accrual-tiers .list-group")
        if ($additionalAccrualTiers.find(".list-group-item").length === 0) {
          return $additionalAccrualTiers.hide()
        }
      })
    },

    addTier() {
      const $additionalAccrualTiers = $("#additional-accrual-tiers .list-group")
      $additionalAccrualTiers.show()
      $additionalAccrualTiers.append(constants.accrualTier)
      const $listGroupItem = $additionalAccrualTiers.find(".list-group-item:last-child")
      $listGroupItem.html(
        $listGroupItem.html().replace(constants.indexPlaceholder, constants.accrualTierCount),
      )
      $listGroupItem.slideDown("normal")

      return (constants.accrualTierCount += 1)
    },

    addCarry() {
      const $carryOverRows = $(".carry-over-rows")
      $carryOverRows.append(constants.carryOver)
      const $carryOverRow = $carryOverRows.find(".form-inline:last-child")
      const first_type = $carryOverRows
        .find('.form-inline [name="pto_type[pto_carry_overs][0][amount_type]"]')
        .val()
      $carryOverRow.find('[name="pto_type[pto_carry_overs][0][amount_type]"]').val(first_type)
      $carryOverRow.html(
        $carryOverRow.html().replace(constants.indexPlaceholder, constants.carryOverCount),
      )
      $carryOverRow.slideDown("normal")

      $carryOverRows.find("[data-action=remove-carry]").show()

      return (constants.carryOverCount += 1)
    },

    removeCarry() {
      const $carryOverRows = $(".carry-over-rows")
      if ($carryOverRows.find(".form-inline").length < 3) {
        $carryOverRows.find("[data-action=remove-carry]").hide()
      }

      const $carryOverRow = $(this).closest(".form-inline")
      return $carryOverRow.slideUp("normal", function () {
        $carryOverRow.remove()

        return functions.triggerBasedOn()
      })
    },

    triggerBasedOn() {
      let show = false

      $("[name$=\\[amount_type\\]] option:selected").each(function () {
        if ($(this).val() === "percent") {
          return (show = true)
        }
      })

      if (show) {
        return $(".carry-over-variable").slideDown()
      } else {
        return $(".carry-over-variable").slideUp()
      }
    },
  }

  const $tierLink = $(this).find("[data-action=add-tier]")
  const $carryLink = $(this).find("[data-action=add-carry]")

  $tierLink.on("click", functions.addTier)
  $carryLink.on("click", functions.addCarry)
  $(this).on("click", "[data-action=remove-carry]", functions.removeCarry)
  $(this).on("click", "[data-action=remove-tier]", functions.removeTier)

  return $(this).on("change", "select[name$=\\[amount_type\\]]", functions.triggerBasedOn)
})
