/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
window.locale_data = $.extend(true, window.default_locale_data, window.locale_data)

String.prototype.t ||= function (domain, context, pluralKey, count, args) {
  return TranslationHelper.t(this.toString(), {
    domain,
    context,
    plural: pluralKey,
    count,
    args,
  })
}

class TranslationHelper {
  // Translate a key.
  // Allows for the following options:
  //   plural: The default key for the plural version.
  //   count: The count when plural.
  //   args: The values to replace %d, %f, %s, etc. with in the key.
  static t(key, options) {
    options ??= {}
    let tStr = ""
    try {
      this.i18n =
        this.i18n ||
        new Jed({
          missing_key_callback(key) {
            const message = `Undefined localization key: ${key}`
            if (window.log_js_errors) {
              return console.error(message)
            }
          },
          locale_data: window.locale_data,
          domain: "defaults",
        })

      let translation = this.i18n.translate(key)
      if (options.context) {
        translation = translation.withContext(options.context)
      }
      if (options.domain) {
        translation = translation.onDomain(options.domain)
      }
      if (options.plural && typeof options.count !== "undefined") {
        translation = translation.ifPlural(options.count, options.plural)
      }
      tStr = translation.fetch.apply(translation, options.args)
    } catch (e) {
      tStr = key.toString()
    }

    return tStr
  }
}

window.TranslationHelper = TranslationHelper
