const $ = window.jQuery
const { Modal } = window

const MODAL_SELECTORS = [
  { selector: "risk-details", prefix: "succession_risks", uniqFilters: ["risk_type"] },
  { selector: "candidate-pool-details", prefix: "candidate_pool" },
  { selector: "plan-status-details", prefix: "plan_status", uniqFilters: ["status_type"] },
  { selector: "bench-strength-details", prefix: "bench_strength", uniqFilters: ["bench_strength"] },
  {
    selector: "candidates-ready-details",
    prefix: "candidates_ready",
    uniqFilters: ["readiness_type"],
  },
  {
    selector: "pipeline-utilization-details",
    prefix: "pipeline_utilization",
    uniqFilters: ["filled_by_type"],
  },
]

const DEFAULT_FILTERS = ["chart_section", "location", "employee_type", "job_family", "importance"]

MODAL_SELECTORS.forEach((modal) => {
  const { selector, prefix, uniqFilters } = modal
  $.onmount(Modal.selector(selector), function () {
    const $newElement = $(this)
    const filters = uniqFilters ? DEFAULT_FILTERS.concat(uniqFilters) : DEFAULT_FILTERS
    const filterStr = filters.map((filter) => `#${prefix}_filter_${filter}`).join(", ")

    function submitFormFilters() {
      $(this).closest("form").submit()
    }

    $newElement.off("change", "[data-dynamic-filter=true]")
    $newElement.on("change", "[data-dynamic-filter=true]", submitFormFilters)
    $(document).off("change", filterStr)
    $(document).on("change", filterStr, submitFormFilters)
  })
})
