const $ = window.jQuery
const rowActionsSel = ".import-fix_table__actions"

class ImportFixTable {
  constructor($el) {
    this.$table = $el
  }

  init() {
    this.$table.find(rowActionsSel).on("click", this.constructor.onImportActionBtnClicked)
  }

  static onImportActionBtnClicked(e) {
    e.preventDefault()

    const $el = $(e.target).closest("a")
    const action = $el.data("action")

    $el.parent().find("a").not($el).removeClass("active")
    $el.addClass("active")

    $el
      .parent()
      .find("input")
      .val(action === "ignore")

    if (action === "fix_it") {
      $el.closest("tr").next("tr").show()
    } else {
      $el.closest("tr").next("tr").hide()
    }
  }
}

$.onmount(".import-fix-table", function onMount() {
  const table = new ImportFixTable($(this))
  table.init()
})
