import * as d3 from "d3"

/**
 * Converts raw timeseries data into generic data that can be used for
 * any stacked bar chart.
 *
 * example of raw data (for FTE): {
 *  group_by: 'chart_sections',
 *  categories: ["HR", "Sales", ...]
 *  (optional) labels: { "HR": "Human Resources", ...} (if not present, uses categories themselves)
 *  (optional) colors: {HR: #000, ...}
 *  data: [
 *    {
 *      date: "Jun", // for X axis label
 *      full_date: "June 2021", // for tooltip label
 *      total: 10.0,
 *      HR: {
 *        total: 5.0,
 *      }, ...
 *    }, ...
 *  ]
 * }
 *
 * processed data:
 *  X: data for x-axis
 *  Y: data for y-axis
 *  Z: Categorical data
 *  O: Original data
 *  colors: array of colors in same order as categories or undefined
 *  labels: hash of category -> label or undefined
 *  stackedData: stacked data for chart
 *    Needs a zIndex and xVal for each item
 * @param {object} rawData
 */
const processPositionTrendData = (rawData) => {
  const { categories, data } = rawData
  const X = data.map((d) => d.date)
  const Y = data.map((d) => d.total)
  const Z = [...categories]
  // Stack data
  const stack = d3
    .stack()
    .keys([...categories].reverse())
    .value((d, key) => (d[key] ? d[key].total : 0))
  const stackedData = stack(data).map((d) =>
    Object.assign(
      d,
      d.map((j) =>
        Object.assign(j, {
          xVal: j.data.date,
          zIndex: d.index,
          cat: d.key,
        }),
      ),
    ),
  )
  // Handle colors if present
  let colors = null
  if (rawData.colors) {
    const defaultColors = [...d3.schemeCategory10]
    colors = Z.map((d, i) => rawData.colors[d] || defaultColors[i % 10])
  }
  return {
    X,
    Y,
    Z,
    O: data,
    stackedData,
    colors,
    labels: rawData.labels,
  }
}

export default processPositionTrendData
