const $ = window.jQuery

$.onmount("[data-action=toggle-expand]", function () {
  $(this).on("click", (e) => {
    e.preventDefault()
    $(e.currentTarget).parents(".module-card").find(".module-body").slideToggle()
    $(e.currentTarget).find(".svg-inline--fa").toggleClass("fa-chevron-down fa-chevron-right")
  })
})

$.onmount("[data-action=toggle-disqualified]", function () {
  $(this).on("click", (e) => {
    e.preventDefault()
    if ($(e.currentTarget).data().disqualifiedCount > 0) {
      $(e.currentTarget).parents(".disqualified-applicants").find(".disqualified").toggle()
      $(e.currentTarget).find(".svg-inline--fa").toggleClass("fa-chevron-down fa-chevron-right")
    }
  })
})

$.onmount("[data-action=toggle-content]", function () {
  $(this).on("click", (e) => {
    e.preventDefault()
    const link = $(e.currentTarget)
    const module = link.closest(".accordion-module-panel")
    module.toggleClass("body-collapsed")
  })
})
