// Prevent number inputs from changing with scroll or up/down arrow keys
$.onmount("input[type=number]", function () {
  $(this)
    .bind("keydown", function (e) {
      if (Keyboard.keyMatchesName(e.which, "up") || Keyboard.keyMatchesName(e.which, "down")) {
        e.preventDefault()
      }
    })
    .bind("mousewheel", function (e) {
      e.preventDefault()
    })
})
