// Helper class to query daily schedules of a person.
//
class PersonSchedule {
  // *Parameters*
  // _dailySchedules_: Object - with days that contain startTime and endTime
  //                   Sample:
  //                   { '1': { startTime: 9.0, endTime: 17.0 },
  //                     '2': { startTime: 9.0, endTime: 17.0 } }
  constructor(dailySchedules) {
    this.dailySchedules = dailySchedules
  }

  getWorkdayTimes(workday) {
    const workDayTimes = this.dailySchedules[workday]
    if (workDayTimes) return workDayTimes
    return PersonSchedule.defaultTimes()
  }

  static defaultTimes() {
    return { startTime: 8.0, endTime: 17.0 }
  }
}

window.PersonSchedule = PersonSchedule
