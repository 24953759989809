/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount(Modal.selector("settings-integrations"), function () {
  const constants = {
    $modal: $(this),
    containerSelector: Pjax.targetContainer("modal-content"),
  }

  const functions = {
    deactivate(e) {
      e.preventDefault()
      const url = $(this).attr("href")

      if (window.confirm($(this).data("confirm"))) {
        return $.when(
          $.pjax({
            method: "delete",
            url,
            container: constants.containerSelector,
            fragment: constants.containerSelector,
            replace: false,
            push: false,
          }),
        ).done(() => {
          return constants.$modal.find("[data-action=close]").click()
        })
      }
    },
    reloadContainer(_e, modal) {
      return modal.reloadTargetContainer().done(modal.addAnother)
    },
  }

  constants.$modal.find("[data-action=delete]").on("click", functions.deactivate)
  $(this).on("modal:save", functions.reloadContainer)
  return $(this).on("modal:close", functions.reloadContainer)
})
