/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount(Modal.selector("upstandard"), function () {
  return $(this).on("modal:save", (_e, modal) =>
    $("[data-pjax-container=modal] [data-action=close]").trigger("click"),
  )
})
