/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const functions = {
  printPage() {
    $(".main-content").removeClass("no-print")
    $(".profiler-results").addClass("no-print")
    return window.print()
  },
}

$.onmount("#notice-modal", function () {
  return $(this).find("[data-action=print]").on("click", functions.printPage)
})
