/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount("#ical-feeds-table", function () {
  const functions = {
    copyToClipboard(e) {
      e.preventDefault()
      const $copyUrl = $(this).find(".copy-url")
      const $copied = $(this).find(".copied")

      const $linkText = $(this).closest("tr").find(".link-text")

      const range = document.createRange()
      range.selectNodeContents($linkText[0])

      const selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)

      document.execCommand("copy")

      $copyUrl.hide()
      $copied.show()

      return setTimeout(function () {
        $copyUrl.show()
        return $copied.hide()
      }, 3000)
    },
  }

  return $(this).find("[data-action=copy-url]").on("click", functions.copyToClipboard)
})
