import * as d3 from "d3"

import legendList from "../charts/legend_list"
import StackedBarChart from "../charts/stacked_bar_chart"
import createBarTooltip from "../utils/create_bar_tooltip"
import pickChartOptionsOfNode from "../utils/pickChartOptionsOfNode"
import maybeEnableInteraction from "./maybe_enable_interaction"
import positionTrendTooltipContent from "./positions/utils/position_trend_tooltip_content"
import processPositionTrendData from "./positions/utils/process_position_trend_data"
import pipelineTooltipContent from "./succession/pipeline_utilization_tooltip_content"

function StackedBarMounter(nodeOrSelector, options = {}) {
  const selection = d3.select(nodeOrSelector)
  const rawData = pickChartOptionsOfNode(selection.node()).data

  // Transform data to fit with chart
  const data = processPositionTrendData(rawData)

  // Select tooltip content type from chart node options
  const tooltipContentType = selection.attr("data-use-tooltip")
  let tooltipContent = null
  if (tooltipContentType === "PositionTrend") {
    tooltipContent = positionTrendTooltipContent
  } else if (tooltipContentType === "Pipeline") {
    tooltipContent = pipelineTooltipContent
  }

  // Create Tooltip
  let tooltip = null
  if (selection.attr("data-use-popper-bar-tooltip")) {
    tooltip = createBarTooltip(selection)
    tooltip.tooltipContent = tooltipContent
  }

  // Configure options
  const chartOptions = {
    animationDuration: options.disableAnimation ? 0 : 500,
    animationDelay: options.disableAnimation ? 0 : 30,
    colors: data.colors ? data.colors : d3.schemeTableau10,
    tooltip,
    showTooltipForZeroValues: tooltip && tooltipContentType === "Pipeline",
  }

  // Mount chart
  const chart = new StackedBarChart(data, nodeOrSelector, chartOptions)

  // Mount Legend
  if (selection.attr("data-legend")) {
    legendList(data, selection.attr("data-legend"), { color: chart.color })
  }

  // Additional interactions
  maybeEnableInteraction({
    chart,
    classOfInteractiveElement: selection.attr("data-interaction"),
  })
}

export default StackedBarMounter
