import { defaults, pick, reduce } from "lodash"

/**
 * Picks out the configured dimensions for the chart, using an optional hash of
 * fallbacks.
 *
 * @param {object} options
 * @param {HTMLElement} node
 * @returns {object} Object with height and width.
 */
function pickConfiguredDimensions(options, node) {
  let dimensions = pick(options, ["height", "width"])
  if (dimensions.height && dimensions.width) {
    return dimensions
  }

  const dataset = node.dataset || {}
  const normalizedDataset = {
    width: Number(dataset.chartWidth),
    height: Number(dataset.chartHeight),
  }

  const mergeIfTruthy = (target, val, key) => (val ? { ...target, [key]: val } : target)

  dimensions = defaults(dimensions, reduce(normalizedDataset, mergeIfTruthy, {}))
  if (dimensions.height && dimensions.width) {
    return dimensions
  }

  return defaults(dimensions, {
    width: node.offsetWidth,
    height: node.offsetHeight,
  })
}

export default pickConfiguredDimensions
