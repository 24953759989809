/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount(Modal.selector("person-work-contact-info"), function () {
  PhoneNumbers.initIntlTelInputs(this)

  return $(this).on("modal:save", (_e, modal) =>
    modal.reloadTargetContainer().then(() => Profile.reloadProfileHeader(modal.$form)),
  )
})
