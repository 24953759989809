import { select } from "d3"

import GaugeChart from "../../charts/gauge_chart"
import pickChartOptionsOfNode from "../../utils/pickChartOptionsOfNode"

const { $ } = window

function GaugeChartMounter(nodeOrSelector, options = {}) {
  const selection = select(nodeOrSelector)
  const rawData = pickChartOptionsOfNode(selection.node()).data

  const baseURL = $(nodeOrSelector).closest("[data-pjax-render]").data("pjaxRender")
  const groupByTriggered =
    baseURL && select(`[data-pjax-render='${baseURL}']`).property("groupByTriggered")

  const gaugeChart = new GaugeChart({
    selector: ".gauge-chart",
    numberSelection: select($(nodeOrSelector).closest("[data-pjax-render]")[0]).select(
      ".widget-big-number",
    ),
    useAnimation: groupByTriggered ? false : !options.disableAnimation,
  })

  gaugeChart.render(rawData)
}

export default GaugeChartMounter
