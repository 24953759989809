/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
class Subform {
  constructor(el) {
    this.isValid = this.isValid.bind(this)
    this.clearErrors = this.clearErrors.bind(this)
    this.save = this.save.bind(this)
    this.close = this.close.bind(this)
    this.$el = $(el)

    this.data = this.$el.data()
    this.$el.on("click", "[data-action=save_subform]", this.save)
    this.$el.on("click", "[data-action=close_subform]", this.close)
  }

  static selector(container) {
    return `[data-subform='${container}']`
  }

  isValid() {
    this.$el.find("[data-subform-validation*=required]").each(function () {
      let $inputGroup, fieldName
      const $item = $(this).closest(".list-group-subform-item")
      if ($(this).data().validateSelf) {
        if ($(this).val() === "") {
          fieldName = $(this).data().errors

          $inputGroup = $item.find(`[data-subform-field=${fieldName}] .input-group`)
          $inputGroup.addClass("form-error")
          return $inputGroup
            .children("label")
            .after("<div class='input-help-text form-error-message'>Can't be blank</div>")
        }
      } else {
        fieldName = $(this).data().validate
        $inputGroup = $item.find(`[data-subform-field=${fieldName}] .input-group`)
        const $field = $inputGroup.find("input")

        if ($field.val() === "") {
          $inputGroup.addClass("form-error")
          return $inputGroup
            .children("label")
            .after("<div class='input-help-text form-error-message'>Can't be blank</div>")
        }
      }
    })

    this.$el.find("[data-subform-validation*=length]").each(function () {
      let $inputGroup, fieldName
      const $item = $(this).closest(".list-group-subform-item")
      const { max } = $(this).data()

      if ($(this).data().validateSelf) {
        if ($(this).val().length > max) {
          fieldName = $(this).data().errors

          $inputGroup = $item.find(`[data-subform-field=${fieldName}] .input-group`)
          $inputGroup.addClass("form-error")
          return $inputGroup
            .children("label")
            .after(
              `<div class='input-help-text form-error-message'>Is too long (maximum is ${max} characters)</div>`,
            )
        }
      } else {
        fieldName = $(this).data().validate
        $inputGroup = $item.find(`[data-subform-field=${fieldName}] .input-group`)
        const $field = $inputGroup.find("input")

        if ($field.val().length > max) {
          $inputGroup.addClass("form-error")
          return $inputGroup
            .children("label")
            .after(
              `<div class='input-help-text form-error-message'>Is too long (maximum is ${max} characters)</div>`,
            )
        }
      }
    })

    return this.$el.find(".form-error").length === 0
  }

  clearErrors() {
    this.$el.find(".form-error").removeClass("form-error")
    return this.$el.find(".form-error-message").remove()
  }

  save() {
    this.clearErrors()
    if (this.isValid()) {
      return this.$el.trigger("subform:save")
    }
  }

  close(e) {
    e?.preventDefault()
    return this.$el.trigger("subform:close")
  }
}

window.Subform = Subform

$(document).on("append-subform", (_e, item) => new Subform(item))
