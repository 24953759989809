/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount("#edit_settings", function () {
  const functions = {
    entryMethodChanged() {
      const entryMethod = $(this).find("option:selected").val()
      $("[data-hide=entry-method]").hide()

      switch (entryMethod) {
        case "manual":
          return $("[data-show=entry-method-manual]").show()
        case "timer":
          return $("[data-show=entry-method-timer]").show()
      }
    },
    toggleChartVisibility() {
      if ($(this).val() === "manager") {
        return $("#chart-select").slideDown("normal")
      } else {
        return $("#chart-select").slideUp("normal")
      }
    },
  }

  $("#company_settings_timesheets_entry_method").on("change", functions.entryMethodChanged)
  return $('[name="company[settings][timesheets][approver_type]"]').on(
    "change",
    functions.toggleChartVisibility,
  )
})
