/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
jQuery(function ($) {
  class LocationsMap {
    constructor(el) {
      this.el = el
      this.$el = $(this.el)
      this.loadMap = this.loadMap.bind(this)
    }

    loadMap(locations) {
      this.locations = locations
      if (!window.google) {
        return
      }

      // Default center to zoomed out view on US
      const properties = {
        zoom: 15,
        maxZoom: 18,
        center: new google.maps.LatLng(38.8833, -100.0),
      }

      this.markerBounds = new google.maps.LatLngBounds()

      const initial = this.getLatLng(_.first(this.locations))
      if (initial) {
        properties["center"] = initial
      } else {
        this.markerBounds.extend(properties["center"])
      }

      this.map = new google.maps.Map(this.$el.get(0), properties)

      _.each(this.locations, (location) => this.setMarker(location))
      if (this.locations.length > 1) {
        return this.map.fitBounds(this.markerBounds)
      }
    }

    getLatLng(location, center) {
      center ??= false
      if (!location) {
        return
      }
      const lat = location.latitude
      const lng = location.longitude

      if (!lat || !lng) {
        return
      }

      const point = new google.maps.LatLng(parseFloat(lat), parseFloat(lng))
      this.markerBounds.extend(point)
      if (center) {
        this.map.setCenter(point)
      }

      return point
    }

    setMarker(location) {
      let img = "red-dot.png"
      if (location.default) {
        img = "green-dot.png"
      }
      img = `//maps.google.com/mapfiles/ms/icons/${img}`

      const { label } = location
      const position = this.getLatLng(location, location.default)

      if (!position) {
        return
      }

      return new google.maps.Marker({
        icon: img,
        map: this.map,
        position,
        title: label,
      })
    }
  }

  return $.onmount("[data-locations-map]", function () {
    let companyId
    if (!window.google) {
      return
    }
    const locationsMap = new LocationsMap(this)
    if (!(companyId = $(this).data("companyId"))) {
      return
    }
    const url = `/api/app/v1/companies/${companyId}/locations.json`

    const loadMap = () => $.getJSON(url, locationsMap.loadMap)
    return loadMap()
  })
})
