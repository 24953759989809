/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount(Modal.selector("salary"), function () {
  $(this).on("modal:save", function (_e, modal) {
    window.BudgetTable.tableEvents(this)
    return modal.reloadTargetContainer()
  })

  if ($(".modal-title").text() === "Delete") {
    return
  }

  window.BudgetTable.tableEvents(this)

  return $(this).on("modal:error", function () {
    return window.BudgetTable.tableEvents(this)
  })
})
