/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
class PjaxImageUploader {
  constructor(el) {
    this.onActionClick = this.onActionClick.bind(this)
    this.onFileChange = this.onFileChange.bind(this)
    this.notImageFile = this.notImageFile.bind(this)
    this.fileError = this.fileError.bind(this)
    this.openModal = this.openModal.bind(this)
    this.upload = this.upload.bind(this)
    this.$el = $(el)
    this.$image = this.$el.find("img")
    this.$imageAction = this.$el.find("[data-action=edit-image]")
    this.$form = this.$el.find("form")
    // form will be present only if the person has the ability to edit the image
    if (!!!this.$form[0]) {
      return
    }
    this.$fileInputField = this.$form.find("[type=file]")

    this.modalUrl = this.$el.data("pjax-uploader-modal-url")
    this.imageUrlKey = this.$el.data("pjax-uploader-image-url-key")
    if (!this.modalUrl || !this.imageUrlKey) {
      throw new Error("data attributes missing for image uploader")
    }

    this.$imageAction.click(this.onActionClick)
    this.$fileInputField.change(this.onFileChange)
  }

  onActionClick() {
    return this.$fileInputField.click()
  }

  onFileChange(e) {
    if (!this.$fileInputField.val()) {
      e.preventDefault()
      return this.fileError()
    } else {
      const file = this.$fileInputField[0].files[0]

      if (file.type.match("image.*")) {
        return this.openModal()
      } else {
        e.preventDefault()
        return this.notImageFile()
      }
    }
  }

  notImageFile() {
    return alert("must be an image")
  }

  fileError() {
    this.$form[0].reset()
    Sentry?.captureMessage("Trying to upload an image without an actual file.")
    return this.$el.trigger("image:file:error", [this])
  }

  openModal() {
    return $.pjax({
      url: this.modalUrl,
      container: "[data-pjax-container=modal]",
      push: false,
      scrollTo: false,
    }).then(() => {
      return $("[data-pjax-modal-image]").on("image:set", this.upload)
    })
  }

  upload(_e, blob, dataURL) {
    const formData = new FormData()
    const name =
      this.$fileInputField
        .val()
        .split(/(\\|\/)/g)
        .pop() || "blob.png"
    formData.append(this.$fileInputField.attr("name"), blob, name)

    if (this.$el.data("pjax-uploader-save") === false) {
      this.$image.attr("src", dataURL)
      return
    }

    return $.when(
      $.pjax({
        container: "[data-pjax-container='modal-content']",
        replace: false,
        push: false,
        type: "PUT",
        url: this.$form.attr("action"),
        data: formData,
        processData: false,
        contentType: false,
        timeout: 3000,
      }),
    ).done(() => {
      return this.$el.trigger("image:updated")
    })
  }
}

$.onmount("[data-pjax-uploader]", function () {
  return new PjaxImageUploader(this)
})
