/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */

// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// Code adapted from:
// https://observablehq.com/@d3/stacked-bar-chart

import * as d3 from "d3"

const { $ } = window

const defaultProps = {
  margins: {
    top: 5, // top margin, in pixels
    right: 0, // right margin, in pixels
    bottom: 30, // bottom margin, in pixels
    left: 30, // left margin, in pixels
  },
  barEdgeRadius: 3, // px
  colors: d3.schemeTableau10, // array of colors
  animationDuration: 0, // ms
  animationDelay: 0, // ms
  tooltip: null, // { tooltipDiv, popper, tooltipContent() }
  showTooltipForZeroValues: false,
  barHoverOpacity: 0.5,
  useGridLines: true,
  fontFamily: d3.select("body").style("font-family"),
  axisColor: "rgba(012, 020, 075, 0.64)",
  bgColor: "#fbfbfb", // Overlay color for non-hovered-on/non-active bars
  xPadding: 0.2, // amount of x-range to reserve to separate bars
  yType: d3.scaleLinear, // type of y-scale
  xTickFormater: (d) => d, // function to format xTicks
}

class StackedBarChart {
  constructor(data, element, props = {}) {
    this.data = data // Data
    this.props = props // Chart Properties
    this.element = element // DOM element where chart is mounted
    this.selection = d3.select(element) // D3 selection of DOM element
    Object.assign(this, defaultProps, props) // Populate class with properties
    if (this.yLabel) {
      this.margins.top = 20
    } // Adjust top margin if yLabel exists
    this.computeChartProperties(this) // Computes dimensions, ranges, and domains
    this.createChart()
  }

  createChart() {
    const noData = this.data.stackedData.length === 0
    if (noData) {
      this.createEmptyChart()
    } else {
      this.createSvg()
      this.addEventHandling()
      this.createYAxis()
      this.createBarGroup()
      this.drawBars()
      this.createXAxis()
      this.maybeUpdateLeftMargin()
    }
  }

  createEmptyChart() {
    this.createSvg()
    this.createYAxis()
    this.createXAxis()
  }

  // Updates chart with new properties
  // example: chart.updateChart({ data: val, width: 200, ...etc. })
  updateChart(props = {}) {
    Object.assign(this, props)
    this.computeChartProperties(props)
    this.updateSvg()
    this.addEventHandling()
    this.updateYAxis()
    this.drawBars()
    this.drawXAxis()
  }

  computeChartProperties(props = {}) {
    // Dimensions, Ranges, Domains, and Scales
    this.computeDimensions(props)
    this.computeRanges(props)
    this.computeDomains(props)
    this.computeScales()
    this.computeAxes()
    this.computeTitles()
  }

  computeDimensions({ width, height }) {
    this.width = width || $(this.element).width() // outer width, in pixels
    this.height = height || $(this.element).height() // outer height, in pixels
  }

  computeRanges({ xRange, yRange }) {
    this.xRange = xRange || [this.margins.left, this.width - this.margins.right] // [left, right]
    this.yRange = yRange || [this.height - this.margins.bottom, this.margins.top] // [bottom, top]
  }

  computeDomains({ xDomain, yDomain, zDomain }) {
    const { data } = this
    this.xDomain = xDomain || data.X // array of x-values
    this.yDomain = yDomain || this.getYDomain() // [ymin, ymax]
    this.zDomain = zDomain || data.Z // array of z-values
  }

  computeScales() {
    // Compute scales
    this.xScale = d3.scaleBand().domain(this.xDomain).range(this.xRange).paddingInner(this.xPadding)
    // Add custom invert function
    // credit: https://bl.ocks.org/shimizu/808e0f5cadb6a63f28bb00082dc8fe3f
    this.xScale.invert = (x) => {
      const domain = this.xScale.domain()
      const range = this.xScale.range()
      const scale = d3.scaleQuantize().domain(range).range(domain)
      return scale(x)
    }
    this.yScale = this.yType(this.yDomain, this.yRange)
    this.color = d3.scaleOrdinal(this.zDomain, this.colors)
  }

  computeAxes() {
    // Compute axes
    this.xAxis = d3.axisBottom(this.xScale).tickSizeOuter(0).tickFormat(this.xTickFormater)
    this.yAxis = d3.axisLeft(this.yScale).ticks(this.height / 40, this.yFormat)
  }

  computeTitles() {
    const { X, Y, Z } = this.data
    // Compute titles.
    const formatValue = this.yScale.tickFormat(100, this.yFormat)
    this.title = (i) => `${X[i]}\n${Z[i]}\n${formatValue(Y[i])}`
  }

  createSvg() {
    const { width, height } = this
    this.svg = this.selection
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
  }

  removeSvg() {
    this.svg.remove()
  }

  updateSvg() {
    const { svg, width, height } = this
    svg.attr("width", width).attr("height", height).attr("viewBox", [0, 0, width, height])
  }

  maybeUpdateLeftMargin() {
    let maxw = 0
    const marginBuffer = 10
    this.svg
      .select("#yAxis")
      .selectAll("text:not(#yLabel)")
      .each(function getWidth() {
        if (this.getBBox().width > maxw) maxw = this.getBBox().width
      })
    if (this.margins.left < maxw + marginBuffer) {
      this.margins.left = maxw + marginBuffer
      this.removeSvg()
      this.computeChartProperties()
      this.createChart()
    }
  }

  createBarGroup() {
    const { svg } = this
    this.barGroup = svg.append("g").attr("id", "barGroup")
  }

  createYAxis() {
    const { svg, margins, yAxis, axisColor, fontFamily, yLabel } = this
    const yGroup = svg
      .append("g")
      .attr("id", "yAxis")
      .attr("transform", `translate(${margins.left},0)`)
      .call(yAxis)

    if (yLabel) {
      yGroup.call((g) =>
        g
          .append("text")
          .attr("x", -margins.left)
          .attr("y", 10)
          .attr("id", "yLabel")
          .attr("fill", axisColor)
          .attr("text-anchor", "start")
          .style("font-family", fontFamily)
          .text(yLabel),
      )
    }

    this.drawYAxis()
  }

  createXAxis() {
    const { svg, yScale, xAxis } = this
    svg
      .append("g")
      .attr("id", "xAxis")
      .attr("transform", `translate(0,${yScale(0)})`)
      .call(xAxis)
    this.drawXAxis()
  }

  drawYAxis() {
    const { svg, margins, axisColor, fontFamily, width, useGridLines } = this
    const yGroup = svg.select("#yAxis")
    // Axes
    yGroup.call((g) => {
      g.selectAll("text").style("fill", axisColor).style("font-family", fontFamily)
      g.select(".domain").remove()
      g.selectAll("path,line").attr("stroke", axisColor)
    })

    if (useGridLines) {
      yGroup.call((g) => {
        g.selectAll(".grid-line").remove()
        g.selectAll(".tick line")
          .clone()
          .attr("class", "grid-line")
          .attr("x2", width - margins.left - margins.right)
          .attr("stroke-opacity", 0.1)
      })
    }
  }

  drawXAxis() {
    const { svg, xAxis, axisColor, fontFamily } = this
    svg
      .select("#xAxis")
      .call(xAxis)
      .call((g) => {
        g.selectAll("text")
          .style("fill", axisColor)
          .style("font-family", fontFamily)
          .each(insertLineBreaks)
        g.selectAll("path,line").attr("stroke", axisColor)
      })

    function insertLineBreaks(d) {
      const el = d3.select(this)
      const words = d.split(" ")
      el.text("")
      for (let i = 0; i < words.length; i += 1) {
        const tspan = el.append("tspan").text(words[i])
        if (i > 0) tspan.attr("x", 0).attr("dy", "12")
      }
    }
  }

  updateYAxis() {
    const { svg, yAxis, animationDuration } = this
    const yAxisGroup = svg.select("#yAxis")
    const t = yAxisGroup.transition().duration(animationDuration)
    yAxisGroup.transition(t).call(yAxis)
    this.drawYAxis()
  }

  drawBars() {
    const { barGroup, xScale, yScale, animationDelay, barEdgeRadius, color, animationDuration } =
      this
    const { stackedData } = this.data
    const maxZIndex = this.getMaxIndices()
    const t = barGroup.transition().duration(animationDuration)

    barGroup
      .selectAll("g")
      .data(stackedData, (d) => d.key)
      .join("g")
      .attr("fill", (g) => color(g.key))
      .selectAll("path")
      .data(
        (d) => d,
        (d) => `bar-${d.xVal}-${d.zIndex}`,
      )
      .join("path")
      .attr("id", (d, i) => `${d.cat}-${i}`)
      .attr("class", (d, i) => `bar bar-${i} bar_${d.cat}`)
      .attr("d", (d) => topRoundedRect(xScale(d.xVal), yScale(0), xScale.bandwidth(), 0, 0))
      .transition(t)
      .delay((_, i) => i * animationDelay)
      .attr("d", (d, i) => {
        const edgeRadius = d.zIndex === maxZIndex[i] ? barEdgeRadius : 0
        return topRoundedRect(
          xScale(d.xVal),
          yScale(d[1]),
          xScale.bandwidth(),
          Math.abs(yScale(d[0]) - yScale(d[1])),
          edgeRadius,
        )
      })

    // Adapted from: https://stackoverflow.com/questions/66877506/d3-how-to-add-rounded-top-border-to-a-stacked-bar-chart
    function topRoundedRect(x, y, width, height, radii) {
      const r = Math.min(radii, height, width)
      return `M${x},${y + r}
      a${r},${r} 0 0 1 ${r},${-r}
      h${width - 2 * r}
      a${r},${r} 0 0 1 ${r},${r}
      v${height - r}
      h${-width}Z`
    }
  }

  addEventHandling() {
    const { svg, xScale, tooltip, color, showTooltipForZeroValues } = this
    const maxZIndex = this.getMaxIndices()
    const { X, Y, Z, O, labels } = this.data

    // Store orginal popper options
    let originalPopperOpts = null
    let newPopperOpts = null
    if (showTooltipForZeroValues) {
      const opts = tooltip.popper.state.options
      originalPopperOpts = {
        placement: opts.placement,
        modifiers: opts.modifiers,
      }
      newPopperOpts = {
        zeroPlacement: "top",
        zeroModifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 10],
            },
          },
        ],
      }
    }

    svg
      .on("pointerenter pointermove", (event) => (tooltip !== null ? pointerMoved(event) : null))
      .on("pointerleave", () => (tooltip !== null ? pointerLeft() : null))
      .on("touchstart", (event) => event.preventDefault())

    const pointerMoved = (event) => {
      const x = xScale.invert(d3.pointer(event)[0])
      const i = X.indexOf(x)
      const val = Y[i]

      // Break if bar is not present or we're already hovered over the bar
      if (val === 0 && !showTooltipForZeroValues) {
        pointerLeft()
        return
      }
      if (i === this.prevHoveredBar) {
        return
      }
      this.prevHoveredBar = i

      // Handle bar hover state
      svg.selectAll(`.bar-${i}`).style("fill", (d) => color(d.cat))

      svg.selectAll(`.bar:not(.bar-${i})`).style("fill", (d) => this.lighten(color(d.cat)))

      // Get relevant categories for time point
      const catAtX = Z.filter((d) => Object.keys(O[i]).includes(d))

      // Generate tooltip content
      tooltip.tooltipContent(tooltip.tooltipDiv, { categories: catAtX, datum: O[i], labels }, color)

      // Select current bar to fix tooltip to it
      const currBars = svg.selectAll(`.bar-${i}`)
      const targetBar = currBars.nodes().filter((d) => d.__data__.zIndex === (maxZIndex[i] || 0))[0]
      tooltip.tooltipDiv.attr("data-show", "")
      tooltip.popper.state.elements.reference = targetBar
      // Dynamically adjust tooltip properties if showing zero values
      if (showTooltipForZeroValues) {
        const { placement, modifiers } = originalPopperOpts
        const { zeroPlacement, zeroModifiers } = newPopperOpts
        const zeroData = val === 0
        const newPlacement = zeroData ? zeroPlacement : placement
        const arrowVisibility = zeroData ? "block" : "none"
        const newModifiers = zeroData ? zeroModifiers : modifiers
        tooltip.popper.setOptions((options) => ({
          ...options,
          placement: newPlacement,
          modifiers: newModifiers,
        }))
      }
      tooltip.popper.update()
    }

    const pointerLeft = () => {
      this.prevHoveredBar = null
      tooltip.tooltipDiv.attr("data-show", null)
      svg.selectAll(".bar").style("fill", (d) => color(d.cat))
    }
  }

  // Adapted from: https://stackoverflow.com/questions/47022484/in-js-find-the-color-as-if-it-had-0-5-opacity-on-a-white-background
  lighten(color) {
    const c = d3.color(color).rgb()
    const bgC = d3.color(this.bgColor).rgb()

    const a = this.barHoverOpacity
    c.r = Math.floor(c.r * a + bgC.r * (1 - a))
    c.g = Math.floor(c.g * a + bgC.g * (1 - a))
    c.b = Math.floor(c.b * a + bgC.b * (1 - a))
    return c
  }

  getYDomain() {
    // If max y-value is 0.0 (i.e. set max to 1)
    const yMax = d3.max(this.data.Y)
    return yMax === 0 ? [0, 1] : [0, yMax]
  }

  getMaxIndices() {
    // Credit to: https://stackoverflow.com/questions/4856717/javascript-equivalent-of-pythons-zip-function
    const zip = (...rows) => [...rows[0]].map((_, c) => rows.map((row) => row[c]))
    // Retrieve top level index (that isn't zero) for each bar
    const data = this.data.stackedData
    const mappedIndices = data.map((d) => d.map((j) => (j[1] - j[0] === 0 ? null : j.zIndex)))
    return zip(...mappedIndices).map((d) => d3.max(d))
  }
}

export default StackedBarChart
