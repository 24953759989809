/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
String.prototype.l ||= function () {
  return DateHelper.localizeDate(this.toString())
}

class DateHelper {
  static localizeDate(date_str) {
    const date = new Date(date_str)
    return date.toLocaleDateString()
  }
}
