import * as d3 from "d3"

const pipelineInteraction = (chart, classOfInteractiveElement) => {
  const interactiveElement = d3.select(classOfInteractiveElement)
  const { data, tooltip, color } = chart
  const { labels } = data
  const rawData = data.O.map((d) => d.pipeline_utilization)
  const datum = {
    full_date: `${data.O.at(0).full_date} - ${data.O.at(-1).full_date}`,
    pipeline_utilization: {
      candidates: d3.sum(rawData, (d) => d.candidates),
      non_candidates: d3.sum(rawData, (d) => d.non_candidates),
    },
  }

  interactiveElement
    .on("mouseover", () => {
      tooltip.tooltipContent(tooltip.tooltipDiv, { datum, labels }, color)
      tooltip.tooltipDiv.attr("data-show", "")
      tooltip.popper.state.elements.reference = interactiveElement.node()
      tooltip.popper.setOptions(() => ({
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 10],
            },
          },
        ],
        placement: "right",
      }))
      tooltip.popper.update()
    })
    .on("mouseout", () => {
      tooltip.tooltipDiv.attr("data-show", null)
    })
}

const maybeEnableInteraction = ({ chart, classOfInteractiveElement }) => {
  if (classOfInteractiveElement === ".pipeline-number") {
    pipelineInteraction(chart, classOfInteractiveElement)
  }
}

export default maybeEnableInteraction
