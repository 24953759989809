const $ = window.jQuery

class PhoneNumbers {
  static initIntlTelInputs(modal) {
    $(modal)
      .find("[data-widget=intl-tel-input]")
      .each(function () {
        const $input = $(this)
        const inputData = $input.data()

        const options = {
          autoPlaceholder: "polite",
          formatOnDisplay: true,
          initialCountry: "auto",
          geoIpLookup: (callback) => {
            $.get("https://ipinfo.io", () => {}, "jsonp").always((resp) => {
              const countryCode = resp && resp.country ? resp.country : "US"
              return callback(countryCode)
            })
          },
          utilsScript:
            "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/" + "12.1.0/js/utils.js",
        }

        if (inputData.intlTelInputNumberType) {
          options.placeholderNumberType = inputData.intlTelInputNumberType
        }

        $input.intlTelInput(options)

        if (inputData.intlTelInputHiddenInput) {
          $input.on("keyup change", () => {
            const number = $input.intlTelInput("getNumber")
            return $(`[name='${inputData.intlTelInputHiddenInput}']`).val(number)
          })
        }
      })
  }
}

window.PhoneNumbers = PhoneNumbers
