const ButtonHelpers = window.ButtonHelpers
const $ = window.jQuery

class BatchUpdater {
  constructor(element) {
    this.$form = $(element)
    this.$errors = this.$form.find(".form-error-messages")
    this.$checkboxes = this.$form.find("input[type=checkbox]")
    this.$submitButton = $("button[form=batch-approve]")
  }

  get selectedBoxes() {
    return this.$checkboxes.filter(":checked")
  }

  clearErrors() {
    this.$errors.text("").removeClass("active")
  }

  validateSelection() {
    this.clearErrors()

    if (this.selectedBoxes.length === 0) {
      this.$errors.text("check a box".t("batch_update")).addClass("active")
      this.$form.find("option:eq(0)").prop("selected", true)
      return false
    }
    return true
  }
}

$.onmount("[data-action=batch-update]", function () {
  const batchUpdater = new BatchUpdater(this)

  $("[data-action=complete-action]").change(() => {
    if (batchUpdater.validateSelection()) {
      batchUpdater.$form.submit()
    }
  })

  batchUpdater.$submitButton.on("click", (e) => {
    ButtonHelpers.setButtonState(batchUpdater.$submitButton, "disabled")

    if (batchUpdater.validateSelection()) {
      batchUpdater.$form.submit()
    } else {
      ButtonHelpers.setButtonState(batchUpdater.$submitButton, "enabled")
      e.preventDefault()
    }
  })

  batchUpdater.$form.on("checkbox:select-all:click", () => {
    batchUpdater.clearErrors()
  })

  batchUpdater.$checkboxes.on("change checkbox:select-all:click", () => {
    batchUpdater.clearErrors()
    const count = batchUpdater.selectedBoxes.length
    $("[data-marker=count]").text(count)
  })
})
