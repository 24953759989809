import { debounce } from "underscore"

const $ = window.jQuery
const { Modal } = window
const { Pjax } = window
const searchRateLimit = window.rate_limit_search ? 1000 : 500

function pjaxSearchWidget() {
  let $searchInput
  let $widgetList

  const searchWidgetList = () => {
    const search = $searchInput.val()
    if (search !== "") {
      const widgetListItems = $widgetList.find(".grid-stack-item-content")
      widgetListItems.hide()
      widgetListItems
        .filter(function filterWidgets() {
          return $(this).text().toLowerCase().includes(search.toLowerCase())
        })
        .show()
    } else {
      $searchInput.siblings(".suffix").addClass("hidden")
      $widgetList.find(".grid-stack-item-content").show()
    }
  }

  const search = debounce(
    () => {
      searchWidgetList()
    },
    searchRateLimit,
    false,
  )

  const clearSearch = (e) => {
    $searchInput.val("")
    $(e.currentTarget).addClass("hidden")
    searchWidgetList()
  }

  const setupSearch = () => {
    $searchInput = $("[data-widget-search]")
    if ($searchInput) {
      $searchInput.off("keyup.pjax-widget-search").on("keyup.pjax-widget-search", (e) => {
        $searchInput.siblings(".suffix").toggleClass("hidden", $searchInput.val() === "")
        if (e.keyCode === 13) {
          searchWidgetList()
        } else {
          search()
        }
      })
      $searchInput.on("focus", function handleFocus() {
        $(this)
          .off("mouseup.pjax-widget-search")
          .one("mouseup.pjax-widget-search", () => {
            $(this).select()
            return false
          })
          .one("mousedown", () => {
            $(this).off("mouseup.pjax-widget-search")
          })
          .select()
      })
      $searchInput
        .siblings(".prefix")
        .off("click.pjax-widget-search")
        .on("click.pjax-widget-search", search)
      $searchInput
        .siblings(".suffix")
        .off("click.pjax-widget-search")
        .on("click.pjax-widget-search", clearSearch)
    }
  }

  const updateGrid = (widget) => {
    $.ajax({
      type: "PUT",
      url: "save_dashboard",
      data: { add_widget: $(widget).data() },
    }).done((data) => {
      window.currentModal.close()
      window.grid.addWidget(data)
      const selector = Pjax.targetContainer("page-actions")
      $.pjax.reload({ container: selector, fragment: selector })
    })
  }

  const setupClick = () => {
    $widgetList.find(".widget-wrapper").click((e) => {
      updateGrid(e.currentTarget)
    })
  }

  const initialize = () => {
    $widgetList = $(".widget-list")

    setupSearch()
    setupClick()
  }

  initialize()
}

$.onmount(Modal.selector("add-widget"), () => {
  pjaxSearchWidget()
})
