/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount("[data-action=export]", function () {
  return $(this).on("click", function (e) {
    const selector = $(this).data("table")
    const filename = $("h1").first().text().toLowerCase().replace(/\s/gi, "_") + ".csv"
    return tableToCSV($(selector), filename, e.currentTarget)
  })
})

$.onmount("[data-action=export-modal]", function () {
  return $(this).on("click", function (e) {
    if ($(".modal .pagination").length > 0 && $(e.target).data("export") !== "page") {
      $(".export-options").show()
      return false
    }

    const filename = $(".modal-title").first().text().toLowerCase().replace(/\s/gi, "_") + ".csv"
    App.Helpers.tableToCSV($(".modal table"), filename, e.currentTarget)
    return $(".export-options").hide()
  })
})

$.onmount("[data-action=close-export]", function () {
  return $(this).on("click", function (e) {
    e.preventDefault()
    $(".export-options").hide()
    return $(".export-all-message").hide()
  })
})

$.onmount("[data-action=export-all]", function () {
  return $(this).on("click", function (e) {
    $(".export-options").hide()
    return $(".export-all-message").show()
  })
})
