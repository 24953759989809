/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount("form[id*='new_ical_feed']", function () {
  const functions = {
    toggleAll() {
      const $listGroup = $(this).closest(".list-group")
      const { checked } = $(this)[0]

      return $listGroup.find('[name*="ical_feed[calendars]"]').prop("checked", checked)
    },
  }

  $(this).find('[data-action="toggle-all-pto"]').on("change", functions.toggleAll)
  return $(this).find('[data-action="toggle-all-other"]').on("change", functions.toggleAll)
})
