/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const vars = {
  $files: undefined,
  $formId: undefined,
}

$.onmount("form[id*='new_benefit_plan']", function () {
  const constants = {
    $form: $(this),
    newFileUploadTemplate: _.template($("#new-file-upload-template").html()),
    formFileUploadTemplate: _.template($("#new-form-file-upload-template").html()),
    newLinkTemplate: _.template($("#new-link-template").html()),
    formLinkTemplate: _.template($("#form-link-template").html()),
  }

  var functions = {
    enableSubformItems() {
      $("[data-action=add-file-upload]").on("click", functions.addFileForm)
      $("[data-action=add-link]").on("click", functions.addLinkForm)
      return $(".list-group-subform-item").each(function () {
        return $(document).trigger("append-subform", [$(this), "exist"])
      })
    },

    addFileForm() {
      const $fileContainer = $(this).closest(".file-container")
      $fileContainer.find(".add-btn").hide()
      $fileContainer.find(".list-group").append(constants.newFileUploadTemplate)
      const $item = $fileContainer.find(".list-group-subform-item:last")
      $item.find(".list-group-item").append(constants.formFileUploadTemplate)
      return $(document).trigger("append-subform", [$item, "new"])
    },

    addLinkForm() {
      const $linkContainer = $(this).closest(".link-container")
      $linkContainer.find(".add-btn").hide()
      $linkContainer.find(".list-group").append(constants.newLinkTemplate)
      const $item = $linkContainer.find(".list-group-subform-item:last")
      $item.find(".list-group-item").append(constants.formLinkTemplate)
      return $(document).trigger("append-subform", [$item, "new"])
    },

    saveFiles() {
      vars.$formId = constants.$form.attr("id")
      return (vars.$files = constants.$form.find("input[type=file]").clone())
    },

    setFiles() {
      if (vars.$files && vars.$formId === constants.$form.attr("id")) {
        return constants.$form.find("input[type=file]").each(function (index) {
          const $hiddenFields = $(this).parent()
          $(this).replaceWith(vars.$files[index])
          if ($(vars.$files[index]).val() !== "") {
            return $hiddenFields
              .find('[name="benefit_plan[file_uploads][][file]"][type=hidden]')
              .remove()
          }
        })
      }
    },

    beforeSubmit(e) {
      return functions.saveFiles()
    },

    disableSubmit() {
      const $submitBtn = $(".modal [data-action=save]")
      $submitBtn.addClass("disabled")
      return $submitBtn.attr("disabled", "disabled")
    },

    enableSubmit() {
      if ($(".modal .edit").length === 0) {
        const $submitBtn = $(".modal [data-action=save]")
        $submitBtn.removeClass("disabled")
        return $submitBtn.attr("disabled", false)
      }
    },
  }

  functions.setFiles()
  functions.enableSubformItems()

  $(document).on("submit", this, functions.beforeSubmit)
  $(document).on("subform:save", ".list-group-subform-item", functions.enableSubmit)
  $(document).on("subform:close", ".list-group-subform-item", functions.enableSubmit)
  return $(document).on("subform:open", ".list-group-subform-item", functions.disableSubmit)
})
