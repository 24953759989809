const $ = window.jQuery
const Modal = window.Modal
const Company = window.Company

$.onmount(Modal.selector("share-all-jobs"), function () {
  $(this).on("modal:open", () => {
    const modalReloadUrl = $(this).data().modalReloadUrl

    function savePublicOrder() {
      Company.saveJobsPublicOrder(modalReloadUrl)
    }

    $(".public-order").sortable()

    $("#save-public-order").on("click", savePublicOrder.bind(modalReloadUrl))
  })
})
