/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// This adds handling for various row-level and table-level actions that can be
// associated with a pjax table.
$.onmount(".pjax-paginated-table", function () {
  var constants = {
    $tableContainer: $(this),
    targetContainerSelector: $(this).data("target-container"),
    $form() {
      return constants.$tableContainer.find("form")
    },
    $actionSelect() {
      return constants.$tableContainer.find("select#action-select")
    },
    $actionBar() {
      return constants.$tableContainer.find("section#action-bar")
    },
    $actionWrap() {
      return constants.$tableContainer.find(".action-wrap")
    },
    $selectedCount() {
      return constants.$tableContainer.find("#selected-count")
    },
    $checkboxes() {
      return constants.$tableContainer.find("input[type=checkbox]")
    },
  }

  const functions = {
    selectClick() {
      const $option = $(this).find("option:selected")
      const containerName = $option.data("pjax-click")
      if (containerName) {
        constants.$form().attr("data-pjax-submit", "modal")
        constants.$form().attr("action", $option.data("pjax-url"))
        $.onmount()
      } else {
        constants.$form().attr("action", $option.data("url"))
      }

      constants.$form().submit()

      constants.$form().removeAttr("data-pjax-submit")
      $.onmount()
      return $(this).find("option:first-child").prop("selected", true)
    },

    openModal() {
      const $option = $(this)
      constants.$form().attr("data-pjax-submit", "modal")
      constants.$form().attr("action", $option.data("pjax-url"))
      $.onmount()
      constants.$form().submit()
      constants.$form().removeAttr("data-pjax-submit")
      return $.onmount()
    },

    exportContent() {
      const $option = $(this)
      constants.$form().attr("action", $option.data("url"))
      constants.$form().submit()
      constants.$form().removeAttr("data-pjax-submit")
      return $.onmount()
    },

    toggleAll() {
      const newVal = $(this).prop("checked")
      return constants.$checkboxes().prop("checked", newVal)
    },

    countSelected() {
      const count = constants.$tableContainer
        .find("input[type=checkbox]:checked")
        .not("[data-action=toggle-all]").length
      constants.$selectedCount().text(count)
      if (
        count > 0 &&
        (constants.$actionSelect().attr("disabled") ||
          constants.$actionBar().hasClass("translate-y-110"))
      ) {
        constants.$actionSelect().closest(".select").removeClass("disabled")
        constants.$actionSelect().attr("disabled", false)

        constants
          .$actionBar()
          .closest("section")
          .removeClass("translate-y-110 scale-98 mt-[-3.825rem]")
        constants.$actionBar().closest("section").css("transform", "translateY(12px)")
      }

      if (count === 0 && (!constants.$actionSelect().attr("disabled") || constants.$actionBar())) {
        constants.$actionSelect().closest(".select").addClass("disabled")
        constants.$actionSelect().attr("disabled", true)

        constants.$actionBar().closest("section").css("transform", "translateY(110%)")
        return constants
          .$actionBar()
          .closest("section")
          .addClass("translate-y-110 scale-98 mt-[-3.825rem]")
      }
    },

    clearFilters() {
      $(this).hide()
      const targetContainer = Pjax.targetContainer(constants.targetContainerSelector)
      return $.pjax({
        url: window.location.pathname,
        container: targetContainer,
        fragment: targetContainer,
        push: false,
        replace: true,
      })
    },
  }

  $(this).on("change", "select#action-select", functions.selectClick)
  $(this).on("click", "[data-action=toggle-all]", functions.toggleAll)
  $(this).on("click", ".a-open-modal", functions.openModal)
  $(this).on("click", ".a-export", functions.exportContent)
  $(this).on("change", "input[type=checkbox]", functions.countSelected)
  $(document).on("pjax:end", functions.countSelected)
  $(this).on("click", "[data-action=clear-filter]", functions.clearFilters)

  return functions.countSelected()
})
