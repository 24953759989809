class FileUploadList {
  constructor(container, type, fileType) {
    this.type = type
    this.fileType = fileType
    this.$container = container
    this.$list = container.find(".list-group")
    this.addEvents()
  }

  addEvents() {
    this.$container.find("[data-action=add-file]").click((e) => {
      this.openUploadForm(e)
    })

    this.$list.find(".list-group-item").each((i, element) => {
      this.initializeDropdownLinks($(element))
    })
  }

  openUploadForm(e) {
    e.preventDefault()
    $(e.currentTarget).hide()

    const $row = $("<div class='list-group-item'></div>")
    $row.appendTo(this.$list)
    $row.html(this.uploadFormHtml())
    this.replaceTitle($row)

    $row.find("[data-action=close-edit]").on("click", this.closeEditForm)
    $row.find("[data-action=insert-file-row]").click((e) => {
      this.insertRow(e)
    })
  }

  uploadFormHtml() {
    const id = new Date().getTime()
    return `<div>
      <a class="list-group-item-close" data-action="close-edit" href="#">
        <i class="far fa-times" aria-hidden="true"></i>
      </a>
      <div class="input-group">
        <label>Select File</label>
        <div class="input-file">
          <label for="new_file" class="btn--large btn--secondary !font-bold">
            <i class="far fa-upload"></i>
            <span>Choose File</span>
          </label>
          <input id="new_file" type="file" name="${this.type}[${this.fileType}_attributes][${id}][file]">
        </div>
      </div>
      <div class="input-group">
        <label>
        File Title (Optional)
        </label>
        <input class="input" type="text" name="${this.type}[${this.fileType}_attributes[${id}][title]" value="">
      </div>
      <a class="btn--large btn--secondary" data-action="insert-file-row">Save</a>
    </div>`
  }

  replaceTitle($row) {
    $row.find("input[type=file]").on("change", (e) => {
      const file = e.currentTarget.files[0]
      const titleField = $row.find("input[type=text]")

      titleField.val(file.name)
    })
  }

  closeEditForm(e) {
    e.preventDefault()
    $(e.currentTarget).closest(".list-group-item").remove()
    $("[data-action=add-file]").show()
  }

  insertRow(e) {
    e.preventDefault()

    const $titleInput = $(e.currentTarget.parentElement).find("input[type=text]")
    const $fileInput = $(e.currentTarget.parentElement).find("input[type=file]")
    $fileInput.removeAttr("id")

    const $row = $(`
    <div class="list-group-item">
      <span class="dropdown">
        <a class="dropdown-link">
          <i class="fa fa-caret-down" aria-hidden="true"></i>
        </a>
        <div class="dropdown-menu" style="display: none;">
          <a class="dropdown-menu-link" href="#" data-action="edit">
            Edit
          </a>
          <a class="dropdown-menu-link" href="#" data-action="delete">
            Delete
          </a>
        </div>
      </span>
      <div data-wrapper="title">
        ${this.renderTitle($titleInput.val(), $fileInput[0].files[0].name)}
      </div>
      <div class="secondary-text">
        ${moment().format("M/DD/YYYY")}
      </div>
      <div class="hidden hidden-inputs"></div>
    </div>
    `)

    this.$list.append($row)
    $row.find("div.hidden-inputs").append($fileInput)
    $row.find("div.hidden-inputs").append($titleInput.prop("type", "hidden"))
    this.initializeDropdownLinks($row)
    this.closeEditForm(e)
  }

  renderTitle(title, fileName) {
    if (title === fileName) {
      return `<a class="list-group-item-title" href="#" data-pjax-click="preview">${title}</a>`
    }
    return `<a class="list-group-item-title" href="#" data-pjax-click="preview">${title}</a>
      <div class="list-group-item-subtitle">${fileName}</div>`
  }

  initializeDropdownLinks($row) {
    $.onmount(".dropdown .dropdown-link")
    $row.find("[data-action=edit]").click((e) => {
      this.openEditForm(e)
    })
    $row.find("[data-action=delete]").click((e) => {
      this.removeRow(e)
    })
    $row.find("[data-pjax-click=preview]").click((e) => {
      if (e.currentTarget.href.substr(-1) === "#") {
        e.preventDefault()
      }
    })
  }

  openEditForm(e) {
    e.preventDefault()
    $(".dropdown .dropdown-menu").hide()

    const $row = $(e.currentTarget).closest(".list-group-item")
    const $rowWithoutChanges = $row.clone()

    const title = $row.find("input[type=hidden]").first().val()
    const formId = this.generateRandomFormID()
    const $editForm = this.modifyUploadForm(title, formId)

    $rowWithoutChanges.attr("id", `row_${formId}`).hide()
    $rowWithoutChanges.appendTo($row.parent())

    $row.html($editForm)

    this.replaceTitle($row)
    $("[data-action=discard-edit]").click((e) => {
      this.discardChanges(e)
    })
    $("[data-action=update-file-row]").click((e) => {
      this.applyChanges(e)
    })
  }

  removeRow(e) {
    e.preventDefault()

    const $row = $(e.currentTarget).closest(".list-group-item")
    const id = $row.find('input[name*="[id]"]').first().val()
    if (id) {
      this.$container.append(`<input type="hidden" name="deleted_file_ids[]" value="${id}">`)
    }
    $row.remove()
  }

  generateRandomFormID() {
    const id = Math.floor(Math.random() * 100)

    if ($(`div#${id}`).size() > 0) {
      this.generateRandomFormID()
    }
    return id
  }

  modifyUploadForm(title, formId) {
    const $form = $(this.uploadFormHtml())
    $form.attr("id", `form_${formId}`)
    $form.find("a[data-action=close-edit]").attr("data-action", "discard-edit")
    $form.find("a[data-action=insert-file-row]").attr("data-action", "update-file-row")

    if (title) {
      $form.find("input[type=text]").val(title)
    }

    return $form
  }

  discardChanges(e) {
    e.preventDefault()

    const $formWrapper = $(e.currentTarget).closest("div")
    const $row = $formWrapper.closest(".list-group-item")
    const id = $formWrapper.attr("id").split("_")[1]
    const $edit = $(`div#row_${id}`)

    $row.html($edit.html())
    $edit.remove()

    this.initializeDropdownLinks($row)
  }

  applyChanges(e) {
    e.preventDefault()

    const $formWrapper = $(e.currentTarget).closest("div")
    const formId = $formWrapper.attr("id").split("_")[1]
    const $row = $(`div#row_${formId}`)

    const updatedTitle = $formWrapper.find("input[type=text]").val()
    const $updatedFile = $formWrapper.find("input[type=file]")

    if ($updatedFile.val()) {
      const id = $row.find('input[name*="[id]"]').first().val()
      if (id) {
        this.$container.append(`<input type="hidden" name="deleted_file_ids[]" value="${id}">`)
      }
      $row.remove()
      this.insertRow(e)
    } else {
      if (updatedTitle !== "") {
        $row.find("div.hidden-inputs").find("input[type=hidden]").first().val(updatedTitle)
        const filename =
          $row.find("[data-wrapper=title]").data("filename") ||
          $row.find("input[type=file]")[0].files[0].name
        $row.find("div[data-wrapper=title]").html(this.renderTitle(updatedTitle, filename))
      }

      $formWrapper.closest(".list-group-item").replaceWith($row.removeAttr("id").show())
      this.initializeDropdownLinks($row)
    }
  }
}

window.FileUploadList = FileUploadList

$.onmount(".file-upload-list", function () {
  return new FileUploadList($(this), $(this).data("type"), $(this).data("file-type"))
})
