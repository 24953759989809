/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount("#periodic", function () {
  var functions = {
    triggerAccrualFrequency() {
      $(".accrual-date").hide()
      const accrualFrequency = $("#pto_type_accrual_frequency option:selected").val()
      return $(`.${accrualFrequency}-accrual-day`).show()
    },

    weeklyPeriod() {
      const accrualTiers = $('.first-accrual-tier [name="pto_type[accrual_tiers][0][value]"]').val()
      const periodAmount = accrualTiers / functions.numAccruals().toFixed(4)
      $("#period-amount").text(periodAmount.toFixed(4))
      return $("#amount-accrual-frequency").text(functions.accrualFrequency())
    },

    numAccruals() {
      switch (functions.accrualFrequency()) {
        case "weekly":
          return 52
        case "biweekly":
          return 26
        case "monthly":
          return 12
        case "bimonthly":
          return 24
        default:
          return 1
      }
    },

    accrualFrequency() {
      return $("#pto_type_accrual_frequency option:selected").val()
    },
  }

  $(this).on("change", '[name="pto_type[accrual_frequency]"]', functions.triggerAccrualFrequency)
  $(this).on("change", '[name="pto_type[accrual_frequency]"]', functions.weeklyPeriod)
  functions.triggerAccrualFrequency()

  $(this).on("input", '[name="pto_type[accrual_tiers][0][value]"]', functions.weeklyPeriod)
  return functions.weeklyPeriod()
})
