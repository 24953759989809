function isFieldClearedFor(which) {
  switch (which) {
    case "person":
      return $("#person-id").prop("value") === "cleared"
    case "position":
      return $("#position-id").prop("value") === "cleared"
    default:
      return false
  }
}

$.onmount("#person-position-select", () => {
  let remoteData = {}

  const originalData = {
    personId: $("#person-id").val(),
    personValue: $("#asset_person").val(),
    positionId: $("#position-id").val(),
    positionValue: $("#asset_person_by_position").val(),
  }

  function handleIdChange(event) {
    if (isFieldClearedFor("person") && isFieldClearedFor("position")) return

    const url = `/assets/association_info`
    const id = $(".modal form").prop("id").replace("edit_asset_", "")

    if (!id) return

    const data = $("#position-wrapper").is(":hidden")
      ? { person_id: event.target.value }
      : { position_id: event.target.value }

    $.ajax({ url, data }).done((response) => {
      remoteData = response
      if (remoteData.person_value === "") {
        $("#person-position-select").val("position")
      }
      $("#person-position-select").trigger("change")
    })
  }

  $("#person-position-select").on("change", (event) => {
    const value = event.target.value

    if (value === "person") {
      $("#person-wrapper").show()
      $("#position-wrapper").hide()

      if (isFieldClearedFor(value)) return

      $("#person-id").val(remoteData.person_id ?? originalData.personId)
      $("#asset_person").val(remoteData.person_value ?? originalData.personValue)
    } else {
      $("#person-wrapper").hide()
      $("#position-wrapper").show()

      if (isFieldClearedFor(value)) return

      $("#position-id").val(remoteData.position_id ?? originalData.positionId)
      $("#asset_person_by_position").val(remoteData.position_value ?? originalData.positionValue)
    }
  })

  $("#person-id, #position-id").on("change", handleIdChange.bind(this))
})

$.onmount("#asset_person, #asset_person_by_position", function () {
  $(this).on("autocomplete:clearSelected", () => {
    $("#person-id").val("cleared")
    $("#asset_person").val("")
    $("#position-id").val("cleared")
    $("#asset_person_by_position").val("")
  })
  $(this).on("autocomplete:selectSuggestion", () => {
    if (isFieldClearedFor("person")) $("#person-id").val(0)
    if (isFieldClearedFor("position")) $("#position-id").val(0)
  })
})
