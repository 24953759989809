const $ = window.jQuery

$.onmount(".search-applicant", function () {
  $(".input", this).on("autocomplete:selectSuggestion", (obj, $selected) => {
    if (!$selected.length) {
      return
    }

    if ($("[data-pjax-container='jobs-show']").length) {
      $.pjax({
        url: $selected.attr("href"),
        container: "[data-pjax-container='jobs-show']",
      })
      return
    }

    window.location.href = $selected.attr("href")
  })
})
