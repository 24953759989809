/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount("form[id*='new_company_document']", function () {
  const $fileInput = $(this).find("#file-input-group")
  const $required = $(this).find("select#company_document_required")
  const $requiredSection = $(this).find("[data-section=required]")
  const $specificPositionsSection = $(this).find("[data-section=specific-positions]")
  const $specificLocationsSection = $(this).find("[data-section=specific-locations]")
  const $specificEmployeesSection = $(this).find("[data-section=specific-employee-types]")
  const $customRequiredMonths = $(this).find("select#company_document_custom_required_months")
  const $startingSection = $(this).find("[data-section=starting]")
  const $datepicker = $(this).find("[data-widget=monthdaypicker]")

  this.picker = new Pikaday({
    field: $datepicker[0],
    format: "MMM DD",
    defaultDate: "Jan 01",
    setDefaultDate: true,
    theme: "hide-year",
  })

  $datepicker.prop("picker", this.picker)

  $fileInput.find(".input-file").on("click", () => $fileInput.find("input[type=file]").click())

  $fileInput.find("input[type=file]").on("change", function () {
    if (!$("#company_document_title").val()) {
      const filename = $fileInput.find("input[type=file]").val().split("\\").pop()
      return $("#company_document_title").val(filename)
    }
  })

  $required.on("change", function () {
    if ($required.find("option:selected").val() === "true") {
      return $requiredSection.removeClass("hidden")
    } else {
      return $requiredSection.addClass("hidden")
    }
  })

  $requiredSection
    .find("input[type=radio][name='company_document[positions_required_for]']")
    .on("change", function () {
      if (this.value === "positions") {
        return $specificPositionsSection.removeClass("hidden")
      } else {
        return $specificPositionsSection.addClass("hidden")
      }
    })

  $requiredSection
    .find("input[type=radio][name='company_document[locations_required_for]']")
    .on("change", function () {
      if (this.value === "locations") {
        return $specificLocationsSection.removeClass("hidden")
      } else {
        return $specificLocationsSection.addClass("hidden")
      }
    })

  $requiredSection
    .find("input[type=radio][name='company_document[employees_required_for]']")
    .on("change", function () {
      if (this.value === "employee_types") {
        return $specificEmployeesSection.removeClass("hidden")
      } else {
        return $specificEmployeesSection.addClass("hidden")
      }
    })

  return $customRequiredMonths.on("change", function () {
    if ($customRequiredMonths.find("option:selected").val() === "once") {
      return $startingSection.addClass("hidden")
    } else {
      return $startingSection.removeClass("hidden")
    }
  })
})
