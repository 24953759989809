import { extendMoment } from "moment-range"

const moment = extendMoment(window.moment)
const { jQuery: $ } = window

class ProfileTimeEntries {
  constructor(modal) {
    this.$form = $(modal).find("form")
    this.$submit = this.$form.find("input[type=submit]")
    this.$tableBody = this.$form.find("table tbody")
    this.$tableFoot = this.$form.find("table tfoot")

    const self = this
    this.$form.find("[data-maskedinput=time]").change((e) => this.updateRow(e))

    this.$form.find("[data-action=add]").click((e) => {
      e.preventDefault()
      self.addRow()
    })
    this.$form.find(ProfileTimeEntries.removeButton).click((e) => {
      e.preventDefault()
      this.removeRow(e)
    })
  }

  static get rowErrorClass() {
    return "form-error"
  }
  static get errorMessageClass() {
    return "form-error-message"
  }
  static get errorDiv() {
    return $("<div>", { class: this.errorMessageClass })
  }
  static get removeButton() {
    return "[data-action=remove]"
  }
  static get removedRowInput() {
    return $("<input>", {
      type: "hidden",
      name: "removed_entries[]",
    })
  }
  static rowFromEvent(event) {
    return $(event.currentTarget).parents("tr")
  }
  static inputAsMoment($row, input) {
    return moment($row.find(`input.${input}`).val(), "HH:mm a")
  }

  disableFormState(state) {
    this.$form.prop("disabled", state)
    this.$submit.prop("disabled", state)

    if (state) {
      this.$submit.addClass("disabled")
    } else {
      this.$submit.removeClass("disabled")
    }
  }

  addError($row, message) {
    $row.addClass(ProfileTimeEntries.rowErrorClass)
    const $errorDiv = $row.find(`td div.${ProfileTimeEntries.errorMessageClass}`)
    if ($errorDiv.length) {
      $errorDiv.html(message)
    } else {
      $row.find("td").first().prepend(ProfileTimeEntries.errorDiv.html(message))
    }
    this.disableFormState(true)
  }

  clearErrors() {
    this.$form
      .find(`.${ProfileTimeEntries.rowErrorClass}`)
      .removeClass(ProfileTimeEntries.rowErrorClass)
    this.$form.find(`.${ProfileTimeEntries.errorMessageClass}`).remove()
    this.disableFormState(false)
  }

  updateTotalHours() {
    const totalHours = this.$tableBody
      .find(".entry-hours")
      .map(function parseHours() {
        return parseFloat(this.textContent)
      })
      .get()
    this.$tableFoot.find("#total-hours").text(totalHours.reduce((a, b) => a + b, 0.0).toFixed(2))
  }

  static checkRangeOverlap($row, range) {
    let hasOverlap = false
    $row.siblings(":not(.hidden)").each(function checkRowRange() {
      const $row = $(this)
      const startTime = ProfileTimeEntries.inputAsMoment($row, "start-time")
      const endTime = ProfileTimeEntries.inputAsMoment($row, "end-time")

      if (range.overlaps(moment.range(startTime, endTime))) {
        $row.addClass(ProfileTimeEntries.rowErrorClass)
        hasOverlap = true
      } else {
        $row.removeClass(ProfileTimeEntries.rowErrorClass)
      }
    })
    return hasOverlap
  }

  validateRow(event) {
    const $row = ProfileTimeEntries.rowFromEvent(event)
    const startTime = ProfileTimeEntries.inputAsMoment($row, "start-time")
    const endTime = ProfileTimeEntries.inputAsMoment($row, "end-time")

    if (!endTime.isValid()) {
      return false
    } else if (startTime >= endTime) {
      this.addError($row, "End time must be after start time".t("profile", "time_entries"))
      return false
    } else if (ProfileTimeEntries.checkRangeOverlap($row, moment.range(startTime, endTime))) {
      this.addError($row, "Times cannot overlap other rows".t("profile", "time_entries"))
      return false
    }

    this.clearErrors()

    const rowTotal = moment.duration(endTime.diff(startTime)).asHours()
    $row.find(".entry-hours").text(rowTotal.toFixed(2))
    $row.find(".amount").val(rowTotal)

    return true
  }

  updateRow(event) {
    this.validateRow(event)
    this.updateTotalHours()
  }

  addRow() {
    const $row = this.$tableBody.find("tr.hidden").clone().removeClass("hidden")
    this.$tableBody.append($row)
    $row.maskAllInputs()
    $row.find("input").change((event) => this.updateRow(event))
    $row.find(ProfileTimeEntries.removeButton).click((row) => this.removeRow(row))
    $row.find("input[type=text]").first().focus()
  }

  removeRow(event) {
    const $row = ProfileTimeEntries.rowFromEvent(event)
    const rowId = $row.attr("data-id")

    if (rowId) {
      this.$tableBody.append(ProfileTimeEntries.removedRowInput.val(rowId))
    }

    $row.remove()
    this.updateTotalHours()
    this.disableFormState(false)
    this.$tableBody.find("tr.form-error input:first").trigger("change")
  }
}

window.ProfileTimeEntries = ProfileTimeEntries
