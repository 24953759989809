/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount(Modal.selector("schedule_with_notice"), function () {
  $("input#select-all").on("click", function () {
    return $("input[name^=schedule\\[person_id]").prop("checked", $(this).prop("checked"))
  })

  return $(this).on("modal:save", function (_e, modal) {
    $(".notice").trigger("click")
    return modal.reloadTargetContainer()
  })
})
