const $ = window.jQuery

$(function onmountAlert() {
  $.onmount(".alert", () => {
    const closeIcon = $(this).find(".alert-close-icon")

    if (closeIcon) {
      closeIcon.on("click", function removeAlert() {
        $(this).closest(".alert").remove()
      })
    }
  })
})
