/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
class ImageUploader {
  constructor(el) {
    this.onActionClick = this.onActionClick.bind(this)
    this.onFileChange = this.onFileChange.bind(this)
    this.fileError = this.fileError.bind(this)
    this.openModal = this.openModal.bind(this)
    this.upload = this.upload.bind(this)
    this.uploadError = this.uploadError.bind(this)
    this.uploadSuccess = this.uploadSuccess.bind(this)
    this.$el = $(el)
    this.$image = this.$el.find("img")
    this.$imageAction = this.$el.find("[data-action=edit-image]")
    this.$form = this.$el.find("form")
    // form will be present only if the person has the ability to edit the image
    if (!!!this.$form[0]) {
      return
    }
    this.$fileInputField = this.$form.find("[type=file]")
    this.$loadingIndicator = this.$el.find(".image-uploader-loading-indicator")

    this.modalUrl = this.$el.data("uploader-modal-url")
    this.imageUrlKey = this.$el.data("uploader-image-url-key")
    if (!this.modalUrl || !this.imageUrlKey) {
      throw new Error("data attributes missing for image uploader")
    }

    this.$imageAction.click(this.onActionClick)
    this.$fileInputField.change(this.onFileChange)
  }

  onActionClick() {
    return this.$fileInputField.click()
  }

  onFileChange(e) {
    if (!this.$fileInputField.val()) {
      e.preventDefault()
      return this.fileError()
    } else {
      return this.openModal()
    }
  }

  fileError() {
    this.$form[0].reset()
    Sentry?.captureMessage("Trying to upload an image without an actual file.")
    return this.$el.trigger("image:file:error", [this])
  }

  openModal() {
    return $.pjax({
      url: this.modalUrl,
      container: "[data-pjax-container=modal]",
      push: false,
      scrollTo: false,
    }).then(() => {
      return $("[data-modal-image]").on("image:set", this.upload)
    })
  }

  upload(_e, blob, dataURL) {
    const formData = new FormData()
    const name =
      this.$fileInputField
        .val()
        .split(/(\\|\/)/g)
        .pop() || "blob.png"
    formData.append(this.$fileInputField.attr("name"), blob, name)

    if (this.$el.data("uploader-save") === false) {
      this.$image.attr("src", dataURL)
      return
    }

    this.$loadingIndicator.show()
    return $.ajax({
      type: "PUT",
      url: this.$form.attr("action"),
      data: formData,
      processData: false,
      contentType: false,
      error: this.uploadError,
      success: this.uploadSuccess,
    })
  }

  uploadError(xhr, status, error) {
    this.$form[0].reset()
    Sentry?.captureMessage("Image failed to be posted to the server")
    return this.$el.trigger("image:upload:error", [this, xhr, status, error])
  }

  uploadSuccess(data, status, xhr) {
    this.$image.load(() => this.$loadingIndicator.hide())
    this.$image.attr("src", data[this.imageUrlKey])
    this.$form[0].reset()
    return this.$el.trigger("image:upload:success", [this, data, status, xhr])
  }
}

$.onmount("[data-uploader]", function () {
  return new ImageUploader(this)
})
