/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const functions = {
  dailySchedulesOfficeDayChange() {
    const $schedule = $(this).closest(".input-group-row").find(".schedule")

    if (this.checked) {
      return $schedule.removeClass("hidden")
    } else {
      return $schedule.addClass("hidden")
    }
  },

  resetTotalHours(e) {
    const $schedule = $(this).closest(".input-group-row").find(".schedule")
    const $from = $schedule.find("#schedule_daily_schedules_start_time")
    const $to = $schedule.find("#schedule_daily_schedules_end_time")
    const $totalHours = $schedule.find("#schedule_daily_schedules_total_hours")
    const startTime = $from.find(":selected").val()
    const endTime = $to.find(":selected").val()

    let totalHours = endTime - startTime
    if (totalHours < 0) {
      totalHours = totalHours + 24
    }

    return $totalHours.val(totalHours)
  },

  syncDays($officeDays) {
    return function (e) {
      e.preventDefault()

      const $currentSchedule = $(this).closest(".input-group-row").find(".schedule")
      const $currentFrom = $currentSchedule.find("#schedule_daily_schedules_start_time")
      const $currentTo = $currentSchedule.find("#schedule_daily_schedules_end_time")
      const $currentTotalHours = $currentSchedule.find("#schedule_daily_schedules_total_hours")
      const $schedules = $officeDays.find(".schedule")
      const $from = $schedules.find("[name$=\\[start_time\\]]")
      const $to = $schedules.find("[name$=\\[end_time\\]]")
      const $totalHours = $schedules.find("[name$=\\[total_hours\\]]")
      const startTime = $currentFrom.find(":selected").val()
      const endTime = $currentTo.find(":selected").val()
      const totalHours = $currentTotalHours.val()

      $from.val(startTime)
      $to.val(endTime)
      return $totalHours.val(totalHours)
    }
  },
}

$.onmount("#office-days", function () {
  const $officeDays = $(this)
  $officeDays.find("[name$=\\[office_day\\]]").on("change", functions.dailySchedulesOfficeDayChange)

  $officeDays.find("[name$=\\[start_time\\]]").on("change", functions.resetTotalHours)
  $officeDays.find("[name$=\\[end_time\\]]").on("change", functions.resetTotalHours)

  return $officeDays.find("[data-action=sync-days]").on("click", functions.syncDays($officeDays))
})
