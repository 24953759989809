/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount(Modal.selector("person-settings"), function () {
  return $(this).on("modal:save", function (e) {
    const $modal = $(e.currentTarget)
    const navigate_to_start = $modal.find("#person_settings_navigate_to_start[value=true]").length
    const reload_page = $modal.find("#person_settings_reload_page[value=true]").length
    if (navigate_to_start || reload_page) {
      return location.reload()
    }
  })
})
