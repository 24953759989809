window.App = window.App || {}
App.Symbols = class Symbols {
  static currencyDefinitions() {
    return {
      AUD: {
        icon: "far fa-dollar-sign",
        symbol: "$",
        disambiguation: "A$",
      },
      AED: {
        icon: "fac fa-uae-dirham-sign",
        symbol: "د.إ",
        disambiguation: null,
      },
      BRL: {
        icon: "fac fa-brazilian-real-sign",
        symbol: "R$",
        disambiguation: null,
      },
      CAD: {
        icon: "far fa-dollar-sign",
        symbol: "$",
        disambiguation: "C$",
      },
      CHF: {
        icon: "fac fa-chf-sign",
        symbol: "CHF",
        disambiguation: null,
      },
      CNY: {
        icon: "far fa-yen-sign",
        symbol: "¥",
        disambiguation: "CN¥",
      },
      DKK: {
        icon: "fac fa-danish-krone-sign",
        symbol: "kr.",
        disambiguation: null,
      },
      EUR: {
        icon: "far fa-euro-sign",
        symbol: "€",
        disambiguation: null,
      },
      GBP: {
        icon: "far fa-pound-sign",
        symbol: "£",
        disambiguation: null,
      },
      HKD: {
        icon: "fac fa-hong-kong-dollar-sign",
        symbol: "HK$",
        disambiguation: null,
      },
      INR: {
        icon: "fac fa-indian-rupee-sign",
        symbol: "₹",
        disambiguation: null,
      },
      JPY: {
        icon: "far fa-yen-sign",
        symbol: "¥",
        disambiguation: "JP¥",
      },
      MXN: {
        icon: "far fa-dollar-sign",
        symbol: "$",
        disambiguation: "MX$",
      },
      NOK: {
        icon: "fac fa-kr-sign",
        symbol: "kr",
        disambiguation: null,
      },
      PHP: {
        icon: "fac fa-philippine-peso-sign",
        symbol: "₱",
        disambiguation: null,
      },
      RON: {
        icon: "fac fa-romanian-leu-sign",
        symbol: "Lei",
        disambiguation: null,
      },
      SEK: {
        icon: "fac fa-kr-sign",
        symbol: "kr",
        disambiguation: null,
      },
      SGD: {
        icon: "fac fa-singapore-dollar-sign",
        symbol: "S$",
        disambiguation: null,
      },
      THB: {
        icon: "fac fa-thai-baht-sign",
        symbol: "฿",
        disambiguation: null,
      },
      TRY: {
        icon: "fac fa-turkish-lira-sign",
        symbol: "₺",
        disambiguation: null,
      },
      USD: {
        icon: "far fa-dollar-sign",
        symbol: "$",
        disambiguation: "US$",
      },
      ZAR: {
        icon: "fac fa-south-african-rand-sign",
        symbol: "R",
        disambiguation: null,
      },
    }
  }
}
