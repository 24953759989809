const $ = window.jQuery
const Modal = window.Modal
const Pjax = window.Pjax

function setModalReloadUrl(jobId, jobApplicationId) {
  return $(".modal").data({
    modalReloadUrl: `/jobs/${jobId}/applicants/${jobApplicationId}`,
  })
}

function updateModalReloadUrl(e) {
  const jobId = e.target.value
  const jobApplicationId = e.target.dataset.jobApplicationId
  return setModalReloadUrl(jobId, jobApplicationId)
}

$.onmount(Modal.selector("move-to-another-job"), function () {
  const selectedOption = $("input:radio:first")
  selectedOption.attr("checked", true)

  $(this).on("modal:save", (_e, modal) => {
    const $el = modal.$el
    const containerName = $el.data("modal-reload")
    const reloadURL = $el.data("modal-reload-url")
    const containerSelector = Pjax.targetContainer(containerName)

    if (reloadURL) {
      return $.pjax({
        url: reloadURL,
        container: containerSelector,
        replace: true,
      })
    }

    return $.pjax.reload(containerSelector, {
      fragment: containerSelector,
    })
  })

  $("input:radio").change(updateModalReloadUrl)
  return setModalReloadUrl(selectedOption.val(), selectedOption.data().jobApplicationId)
})
