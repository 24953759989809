const { $ } = window
const $pjax = $.pjax

/**
 * Component that polls the server for the status of an import.
 */
function ImportStatus() {
  const $component = $(this)
  const $pollFlag = $component.find("[data-import-status-should-poll=yes]")
  const maybeUrl = $component.data("importStatusUrl")
  const maybeHideSelector = $component.data("importStatusHide")
  const maybeRevealSelector = $component.data("importStatusReveal")

  if ($pollFlag.length > 0 && maybeUrl) {
    // Schedule a retry.
    setTimeout(() => {
      $pjax
        .reload({
          url: maybeUrl,
          container: ".import-status",
          push: false,
          replace: false,
        })
        .done(() => ImportStatus.call($component[0]))
    }, 3000)
  } else {
    if (maybeHideSelector) $(maybeHideSelector).addClass("hidden")
    if (maybeRevealSelector) $(maybeRevealSelector).removeClass("hidden")
  }
}

$.onmount("[data-use-component=ImportStatus]", ImportStatus)
