/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount(Modal.selector("standard"), function () {
  return $(this).on("modal:save", (_e, modal) =>
    $.when(modal.reloadTargetContainer()).done(modal.addAnother),
  )
})
