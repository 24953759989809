/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
window.App = window.App || {}

window.endpoint = function (path) {
  path = $.isArray(path) ? path.join("/") : path

  return "/api/app/v1/" + path + ".json"
}

window.App.endpoint = window.endpoint
