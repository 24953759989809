const $ = window.jQuery

function addEventListeners() {
  $("input[name='hire_actions']").on("change", (e) => {
    switch (e.target.value) {
      case "leave": {
        $(".fill-open-position").hide()
        $(".create-new-position").hide()

        $(".position-select select").prop("disabled", true)
        $("#hire_applicant_form_parent_id").prop("disabled", true)
        $("#hire_applicant_form_position_title").prop("disabled", true)

        break
      }
      case "fill": {
        $(".fill-open-position").show()
        $(".create-new-position").hide()

        $(".position-select select").prop("disabled", false)
        $("#hire_applicant_form_parent_id").prop("disabled", true)
        $("#hire_applicant_form_position_title").prop("disabled", true)

        break
      }
      case "create": {
        $(".fill-open-position").hide()
        $(".create-new-position").show()

        $(".position-select select").prop("disabled", true)
        $("#hire_applicant_form_parent_id").prop("disabled", false)
        $("#hire_applicant_form_position_title").prop("disabled", false)

        break
      }
      default: {
        break
      }
    }
  })
}

$.onmount(Modal.selector("hire-applicant"), function () {
  addEventListeners()

  $(this).on("modal:save", (_e, _modal) => {
    const redirectUrl = $(".modal form").data("redirect-url")
    window.location.href = redirectUrl
  })

  $(this).on("pjax:end", () => {
    const modal = $(Modal.selector("hire-applicant"))
    if (modal.find(".form-error, .form-error-message").length) {
      addEventListeners()
    }
  })
})

$.onmount("#hire_applicant_form_reports_to_name", function () {
  $(this).on("autocomplete:selectSuggestion", (obj, $selected) => {
    $("#hire_applicant_form_parent_id").val($selected.data("id"))
    $(".position_title_field").show()
  })
})
