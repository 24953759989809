$.onmount(".allocation-type-table", function () {
  const allocationTypeId = $(this).data("allocation-type-id")

  function sumTypeFields() {
    let sum = 0
    $(`.allocation-amount-field-${allocationTypeId}`).each(function () {
      sum += Number($(this).val())
    })
    $(`.total-percent-text-${allocationTypeId}`).html(String(sum))
  }

  $(`.allocation-amount-field-${allocationTypeId}`).on("input", sumTypeFields.bind(this))
  $(`.allocation-amount-field-${allocationTypeId}`).on("focus", function () {
    if ($(this).val() === "0") {
      $(this).attr("value", null)
    }
  })
  $(`.allocation-amount-field-${allocationTypeId}`).on("focusout", function () {
    if (!$(this).val() || $(this).val() === "" || $(this).val() === "0") {
      $(this).val(0)
    }
  })

  sumTypeFields()
})

$.onmount("#position_allocation_effective_date", function () {
  const allocationTypeId = $(this).data("allocationtype")

  $(this).on("load change", (event) => {
    $(`.effective-date-${allocationTypeId}`).each(function () {
      $(this).val(event.target.value)
    })
  })
})

$.onmount("#reports_filter_allocation_type", function () {
  $(this).on("select change", function () {
    $(this).closest("form").submit()
  })
})
