/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount("#edit-people", function () {
  var constants = {
    $modal: $(this),
    $addColumn: $(this).find("[data-action=add-column]"),
    $addRow: $(this).find("[data-action=add-row]"),
    $tableBody: $(this).find("tbody"),
    $columnSelect() {
      return constants.$modal.find(".column-select")
    },
  }

  var functions = {
    onSelectChange(e) {
      let inputGroupSelector
      const $select = $(this)
      const $selectedOption = $(this).find("option:selected")
      const attribute = $selectedOption.val()
      if ($selectedOption.data("unique-key")) {
        inputGroupSelector = `.unique-key-${$selectedOption.data("unique-key")}`
      } else {
        inputGroupSelector = `[name$='[${attribute}]']`
      }
      const $order = $select.closest("th").find("[name*=additional_fields_order]")
      $order.val(attribute)

      constants.$modal.find("tbody tr").each(function () {
        const $inputGroup = $(this)
          .find(`#additional-columns ${inputGroupSelector}`)
          .parent()
          .clone()
        $inputGroup.find(inputGroupSelector).attr("disabled", false)
        const $field = $(this).find(`.${$select.attr("id")}`)

        $field.empty()
        return $field.append($inputGroup)
      })

      functions.changeOptions()
      return functions.resizeSelect(this)
    },

    onAddColumnClick() {
      const $select = constants.$modal.find("#column-1").closest("th").clone()
      const columnNumber = constants.$columnSelect().length + 1

      // header
      $select.find(".column-select").attr("id", `column-${columnNumber}`)
      constants.$columnSelect().each(function () {
        return $select
          .find(`option[value=${$(this).find("option:selected").val()}]`)
          .attr("disabled", true)
          .attr("selected", false)
          .addClass("hidden")
      })
      const $options = $select.find("option").not(".hidden")
      if ($options.length === 1) {
        constants.$addColumn.addClass("hidden")
      }
      const $selectedOption = $($options[0])
      $selectedOption.attr("selected", "selected")
      const attribute = $selectedOption.val()
      const $order = $select.find('[name="people[additional_fields_order[1]]"]')
      $order.attr("id", `people_additional_fields_order[${columnNumber}]`)
      $order.attr("name", `people[additional_fields_order[${columnNumber}]]`)
      $order.val(attribute)
      constants.$modal.find("thead tr").append($select)

      // body
      constants.$modal.find("tbody tr").each(function () {
        const $inputGroup = $(this)
          .find(`#additional-columns [name$='[${attribute}]']`)
          .parent()
          .clone()
        $inputGroup.find(`[name$='[${attribute}]']`).attr("disabled", false)

        $(this).append(`<td class=column-${columnNumber}></td>`)
        return $(this).find(`.column-${columnNumber}`).append($inputGroup)
      })

      functions.changeOptions()

      const column = constants.$modal.find(`#column-${columnNumber}`)[0]
      return functions.resizeSelect(column)
    },

    onAddRowClick() {
      const index = constants.$tableBody.find("tr").length
      const $personRow = _.template($("#person-row-template").html())
      constants.$tableBody.append($personRow({ index }))

      return constants.$columnSelect().each(function () {
        let inputGroupSelector
        const $select = $(this)
        const $selectedOption = $select.find("option:selected")
        const attribute = $selectedOption.val()
        if ($selectedOption.data("unique-key")) {
          inputGroupSelector = `.unique-key-${$selectedOption.data("unique-key")}`
        } else {
          inputGroupSelector = `[name$='[${attribute}]']`
        }

        const columnNumber = $select.attr("id").split("-")[1]
        const $tr = constants.$tableBody.find("tr:last-child")
        const $inputGroup = $tr.find(`#additional-columns ${inputGroupSelector}`).parent().clone()
        $inputGroup.find(inputGroupSelector).attr("disabled", false)

        $tr.append(`<td class=column-${columnNumber}></td>`)
        return $tr.find(`.column-${columnNumber}`).append($inputGroup)
      })
    },

    changeOptions() {
      constants.$columnSelect().find("option.hidden").removeClass("hidden").attr("disabled", false)
      const $selectedOptions = constants.$columnSelect().find("option:selected")
      return constants.$columnSelect().each(function () {
        const $select = $(this)
        return $selectedOptions.each(function () {
          if ($(this).val() === $select.find("option:selected").val()) {
            return
          }

          return $select
            .find(`[value=${$(this).val()}]`)
            .attr("disabled", true)
            .addClass("hidden")
        })
      })
    },

    resizeSelect(el) {
      const $selectedOption = $(el)
        .children('[value="' + el.value + '"]')
        .attr("selected", true)
      const selectedIndex = $selectedOption.index()

      const $nonSelectedOptions = $(el)
        .children()
        .not($selectedOption)
        .remove()
        .attr("selected", false)

      $selectedOption.remove()
      $(el).append($nonSelectedOptions)
      if (selectedIndex >= $nonSelectedOptions.length) {
        return $(el).append($selectedOption)
      } else {
        return $(el).children().eq(selectedIndex).before($selectedOption)
      }
    },
  }

  constants.$addColumn.on("click", functions.onAddColumnClick)
  constants.$addRow.on("click", functions.onAddRowClick)
  $(document).on("change", ".column-select", functions.onSelectChange)

  return constants.$columnSelect().each(function () {
    return functions.resizeSelect(this)
  })
})
