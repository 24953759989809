/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
class Modal {
  constructor(el) {
    this.open = this.open.bind(this)
    this.addEvents = this.addEvents.bind(this)
    this.clickEvent = this.clickEvent.bind(this)
    this.saveAndAdd = this.saveAndAdd.bind(this)
    this.startSave = this.startSave.bind(this)
    this.afterSave = this.afterSave.bind(this)
    this.addAnother = this.addAnother.bind(this)
    this.reloadTargetContainer = this.reloadTargetContainer.bind(this)
    this.closeForEscape = this.closeForEscape.bind(this)
    this.close = this.close.bind(this)
    window.currentModal = this
    this.$el = $(el)

    this.data = this.$el.data()
    this.modalType = this.data.modal

    this.$overlay = this.$el.closest(".modal-overlay")
    this.$modalContent = this.$el.find(
      [
        Pjax.targetContainer("modal-content"),
        Pjax.targetContainer("upmodal-content"),
        Pjax.targetContainer("modal-confirm-content"),
      ].join(", "),
    )

    this.open()
  }

  static selector(container) {
    return `[data-modal='${container}']`
  }

  open() {
    $(".main-content").addClass("no-print")
    $("body").addClass("modal-active")
    this.addEvents()
    const focusFirstInput = () => {
      // If one wants to autofocus a specific element, this is done by adding the
      // data attribute `data-input-focus` to the it. TinyMce autofocusing is
      // handled separately using the tinyMce data field `data-startup-focus`.
      //
      // Ensure we call after the modal has faded in to give time for React
      // components to mount as well.
      const autofocusTinyMce = $("[data-startup-focus]")
      const autofocusInput = $("[data-input-focus]")
      if (
        autofocusTinyMce.length > 0 &&
        autofocusTinyMce.first().attr("data-startup-focus") === "true"
      ) {
        return
      } else if (
        autofocusInput.length > 0 &&
        autofocusInput.first().attr("data-input-focus") === "true"
      ) {
        return autofocusInput.trigger("focus")
      } else {
        // Do not change focus if an input (within the modal) already has it as
        // this can be jarring/interrupt the user.
        const isDescendantFocused = this.$el[0].contains(document.activeElement)
        if (isDescendantFocused) {
          return
        }

        return this.$el
          .find("input[type=text]:not(:disabled, [readonly],[data-widget=datepicker]), select")
          .first()
          .trigger("focus")
      }
    }

    return this.$overlay.fadeIn(() => {
      this.$el.trigger("modal:open", this)
      // Give ReactRailsUJS a chance to mount any inputs before focusing an
      // input.
      return setTimeout(focusFirstInput, 32)
    })
  }

  // Add a hidden input element to the the form of which the submit button was
  // clicked. This helps in the 'startSave' callback to identify which submit
  // button was clicked and update its state.
  addSubmitButtonName(button) {
    return $("<input />")
      .attr("type", "hidden")
      .attr("name", "submit_button")
      .attr("value", button.name)
      .appendTo($(button.form))
  }

  addEvents() {
    $("body").on("keyup", this.closeForEscape)
    this.$overlay.click(this.clickEvent)
    this.$modalContent.on("pjax:start", this.startSave)
    $("[data-action=save-and-add]").click(this.saveAndAdd)
    const self = this
    this.$modalContent.on("click", "button[type=submit], input[type=submit]", function () {
      return self.addSubmitButtonName(this)
    })

    $(".radio input, .radio select").on("click", this.checkRadio)
    $(".radio select").on("focus", this.checkRadio)

    return this.$el.find("input[type=number]").on("mousewheel", (e) => e.preventDefault())
  }

  checkRadio() {
    if ($(this).closest(".radio").find("input[type=radio]:checked").length === 0) {
      if ($(this).is("[data-maskedinput]")) {
        return $(this).closest(".radio").find("input[type=radio]").attr("checked", true)
      } else {
        $(this).closest(".radio").trigger("click")
        $(this).trigger("click")
        return $(this).trigger("focus")
      }
    }
  }

  clickEvent(e) {
    const $target = $(e.target)

    if ($target.data("pjax_click")) {
      return
    }

    e.stopPropagation()
    if ($target.parents("a").data("action") === "close" || $target.data("action") === "close") {
      return this.close(e)
    }
  }

  errorMessages() {
    const error_classes = [
      ".form-error",
      ".form-error-message",
      ".form-error-messages.active",
      ".alert-critical",
    ]
    return this.$el.find(error_classes.join(", "))
  }

  showError(text) {
    return this.errorMessages().first().find("> div").text(text).addClass("active")
  }

  clearError() {
    return this.errorMessages().first().find("> div").text("").removeClass("active")
  }

  saveAndAdd(e) {
    return (this.addUrl = $(e.target).data("add-url"))
  }

  modalSubmitForm() {
    return this.$modalContent
      .find(
        `form[data-pjax-submit=modal-content], \
form[data-pjax-submit=upmodal-content], \
form[data-pjax-submit=modal-confirm-content]\
`,
      )
      .not("[data-secondary-form=true]")
      .not("[data-follow-up-form=true]")
  }

  startSave(e, _xhr, _options) {
    const $targetForm = $(e.relatedTarget)
    if (!$targetForm.length) {
      return
    }
    const $modalSubmitForm = this.modalSubmitForm()

    if ($targetForm.data("secondary-form")) {
      return ButtonHelpers.setButtonState($targetForm, "disabled")
    } else if ($targetForm.data("follow-up-form")) {
      this.$form = $targetForm
      ButtonHelpers.setButtonState($targetForm, "disabled")
      return this.$modalContent.on("pjax:end", this.afterSave)
    } else if ($targetForm.is($modalSubmitForm)) {
      this.$form = $modalSubmitForm
      ButtonHelpers.setButtonState($modalSubmitForm, "disabled")
      return this.$modalContent.on("pjax:end", this.afterSave)
    } else if ($targetForm.data("emulate-submit")) {
      this.$form = $targetForm
      ButtonHelpers.setButtonState($targetForm, "disabled")
      return this.$modalContent.on("pjax:end", this.afterSave)
    }
  }

  // This function differs from the same function in v2-stubs/modal.js. The
  // v1 modal.js seeems to automatically reload the parent container, but
  // here wo do not, requiring one to do a manual call to reloadTargetContainer
  // in each respective piece of UI that uses this function.
  afterSave(e, xhr, options) {
    this.$modalContent.off("pjax:end", this.afterSave)

    const formData = e.relatedTarget.dataset

    const reloadedModalDataset = $(xhr.responseText).find(".modal").data()
    this.data.modalReloadUrl = reloadedModalDataset.modalReloadUrl || this.data.modalReloadUrl

    const errors = this.errorMessages()
    if (errors.length === 0 || (errors.get(0) && errors[0].innerText.length === 0)) {
      if (options.data === "refresh-data" || formData.action === "refresh-data") {
        return
      } else if (formData.followUpForm) {
        this.$el.attr("class", $(xhr.responseText).find(".modal").attr("class"))
        this.$form = null
        return this.$el.trigger("modal:followUpForm")
      } else if (this.data.modalCustomClose) {
        return this.$el.trigger("modal:save", this)
      } else {
        return $.when(this.$el.trigger("modal:save", this)).done(() => this.close())
      }
    } else {
      this.$el.trigger("modal:error", this)
      if (this.data.reloadOnError) {
        this.reloadTargetContainer()
      }
      this.$form = null
      return this.$el.find("[data-action=close]").click(this.clickEvent)
    }
  }

  addAnother() {
    if (!this.addUrl) {
      return
    }

    return $.pjax({
      url: this.addUrl,
      container: "[data-pjax-container='modal']",
      push: false,
      scrollTo: false,
    })
  }

  reloadTargetContainer() {
    if (!this.data.modalReload) {
      return
    }

    const targetContainer = Pjax.targetContainer(this.data.modalReload)
    let containerUrl = this.data.modalReloadUrl
    const modalReloadPush = this.data.modalReloadPush || false
    const modalReloadQuery = this.data.modalReloadQuery || false
    if (modalReloadQuery) {
      containerUrl += window.location.search
    }

    if (!($(targetContainer).length > 0)) {
      window.location.reload()
      return
    }

    if (containerUrl) {
      return $.pjax({
        url: containerUrl,
        container: targetContainer,
        fragment: targetContainer,
        push: modalReloadPush,
        scrollTo: false,
      })
    } else if (this.data.reloadURL) {
      return $.pjax.reload({
        url: this.data.reloadURL,
        container: targetContainer,
        push: false,
      })
    } else {
      return $.pjax.reload(targetContainer, { fragment: targetContainer })
    }
  }

  closeForEscape(e) {
    if (Keyboard.keyMatchesName(e.which, "esc")) {
      return this.close()
    }
  }

  close(e) {
    e?.preventDefault()
    this.$el.trigger("modal:close", this)
    delete window.currentModal
    this.$modalContent.off("pjax:start", this.startSave)
    return this.$overlay.fadeOut(this.cleanup.bind(this))
  }

  cleanup() {
    $(".main-content").removeClass("no-print")
    $("body").removeClass("modal-active").off("keyup").css("height", "")
    $("body").find(".pika-single").remove()
    this.removeEditors()
    $(window).off("resize")
    return this.$overlay.remove()
  }

  removeEditors() {
    return $(".modal .tinymce").each(function () {
      return tinymce.remove(`#${this.id}`)
    })
  }
}

window.Modal = Modal

$.onmount("[data-modal]", function () {
  return new Modal(this)
})
