/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount(".stars", function () {
  const $labels = $(this).find("label")
  return $labels.on("mouseup", function () {
    const $radio = $(this).prev()
    if ($radio.prop("checked")) {
      setTimeout(() => $radio.siblings("input[value=0]").click(), 5)
    }

    return setTimeout(function () {
      if ($radio.parents(".module-card-list-item").length > 0) {
        const $form = $radio.closest("form")
        return $.ajax({
          url: $form.prop("action"),
          type: "PUT",
          data: $form.serialize(),
        })
      }
    }, 10)
  })
})
