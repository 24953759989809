/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// Read more toggle
$.onmount(".read-more", function () {
  return $(this).readmore({
    collapsedHeight: 75,
    moreLink: '<a href="#">Show more</a>',
    lessLink: '<a href="#">Show less</a>',
    afterToggle(trigger, element, expanded) {
      if (!expanded) {
        // The "Close" link was clicked
        element.toggleClass("read-more-ellipsis")
      }
    },
    beforeToggle(trigger, element, expanded) {
      if (expanded) {
        element.addClass("read-more-ellipsis")
      } else {
        element.removeClass("read-more-ellipsis")
      }
    },
    blockProcessed(element, collapsible) {
      if (collapsible) {
        element.addClass("read-more-ellipsis")
      }
    },
  })
})
