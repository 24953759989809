/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount(Modal.selector("people-filter"), function () {
  const constants = {
    $modal: $(this),
    $criterionList: $(this).find(".criterion-list"),
    $form: $(this).find("form"),
    $submit: $(this).find("[data-action=submit]"),
  }

  var functions = {
    columnType(value) {
      switch (value) {
        case "hire_date":
        case "terminated_at":
        case "birth_date":
          return "date"
        case "schedule_id":
          return "schedule"
        case "ssn_ciphertext":
          return "ssn"
        case "employee_status_id":
          return "status"
        case "termination_reason":
          return "termination-reason"
        case "gender":
          return "gender"
        case "is_hispanic":
          return "is-hispanic"
        case "marital_status":
          return "marital-status"
        case "filing_status":
          return "filing-status"
        case "decline_withholding":
          return "decline-withholding"
        case "employee_type_id":
          return "employee-type"
        case "base_pay_type":
          return "pay-type"
        case "base_pay":
          return "currency"
        case "hours_per_week":
          return "quantifiable"
        default:
          return "text"
      }
    },

    hideInput($el) {
      $el.addClass("hidden")
      return $el.find("select, input").attr("disabled", true)
    },

    showInput($el) {
      $el.removeClass("hidden")
      return $el.find("select, input").attr("disabled", false)
    },

    columnChanged() {
      const selected = $(this).find("option:selected").val()
      const $criterion = $(this).closest(".criterion")
      const $visibleCondition = $criterion.find(".criterion-condition:not(.hidden)")
      const $criterionValue = $criterion.find(".criterion-value:not(.hidden)")

      let type = functions.columnType(selected)
      type = (() => {
        switch (type) {
          case "status":
          case "termination-reason":
          case "gender":
          case "is-hispanic":
          case "marital-status":
          case "filing-status":
          case "decline-withholding":
          case "employee-type":
          case "pay-type":
            return "select"
          case "date":
          case "schedule":
          case "ssn":
          case "text":
          case "quantifiable":
          case "currency":
            return type
        }
      })()

      if (!$visibleCondition.hasClass(`criterion-${type}-condition`)) {
        const $selectedCondition = $criterion.find(`.criterion-${type}-condition`)
        functions.hideInput($visibleCondition)
        functions.showInput($selectedCondition)
        $selectedCondition.trigger("change")

        if ($criterionValue) {
          return functions.hideInput($criterionValue)
        }
      }
    },

    conditionChanged() {
      const selected = $(this).find("option:selected").val()
      const $criterion = $(this).closest(".criterion")
      const $visibleValue = $criterion.find(".criterion-value:not(.hidden)")

      const selectedColumn = $criterion.find(".criterion-column:not(.hidden) option:selected").val()

      switch (selected) {
        case "blank":
        case "not_blank":
          return functions.hideInput($visibleValue)
        default:
          var type = functions.columnType(selectedColumn)
          if (type === "ssn") {
            return
          }

          var $selectedValue = $criterion.find(`.criterion-${type}-value`)

          if ($visibleValue !== $selectedValue) {
            functions.hideInput($visibleValue)
            return functions.showInput($selectedValue)
          }
      }
    },

    addFilterRow() {
      const index = constants.$criterionList.find(".criterion").length + 1
      const $filterRow = _.template($("#filter-row-template").html())
      return constants.$criterionList.append($filterRow({ index }))
    },

    toggleBtn(e) {
      e.preventDefault()
      if ($(this).hasClass("active")) {
        return
      }
      const $group = $(this).closest(".operator-group")
      $group.find(".active").removeClass("active")
      $(this).addClass("active")
      return $group.find('[name*="operator"]').val($(this).data().value)
    },

    modalSubmit() {
      let url = window.location.pathname + "?" + constants.$form.find("input, select").serialize()
      const keepers = ["chart_section", "list", "search"]
      const params = new URLSearchParams(window.location.search)
      keepers.forEach(function (key) {
        const paramsValue = params.get(key)
        if (paramsValue) {
          return (url += `&${key}=${paramsValue}`)
        }
      })

      const targetContainer = Pjax.targetContainer("people-index")
      $.pjax({
        url,
        container: targetContainer,
        fragment: targetContainer,
        push: false,
        replace: true,
      })

      return constants.$modal.find("[data-action=close]").trigger("click")
    },
  }

  constants.$submit.on("click", functions.modalSubmit)
  $(this).on("change", ".criterion-column", functions.columnChanged)
  $(this).on("change", ".criterion-condition", functions.conditionChanged)
  $(this).on("click", "[data-action=add-criterion]", functions.addFilterRow)
  return $(this).on("click", "[data-action=toggle-button]", functions.toggleBtn)
})
