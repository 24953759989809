/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
String.prototype.extension ||= function () {
  return FileHelper.extension(this.toString())
}

class FileHelper {
  static extension(uri) {
    const everythingUntilLastPeriod = /^.*\./
    return this.basename(uri).replace(everythingUntilLastPeriod, "")
  }

  static basename(path) {
    const backslashes = /\\\\/g
    const query_string = /\?.*$/
    const everythingUntilLastSlash = /.*[\/\\]/
    return path
      .replace(query_string, "")
      .replace(backslashes, "/")
      .replace(everythingUntilLastSlash, "")
  }
}
