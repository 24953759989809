const $ = window.jQuery
const Pjax = window.Pjax

$.onmount('#succession-strategies-position-settings input[type="checkbox"]', function loader() {
  $(this).on("click", () => {
    const $el = $(this).closest("[data-pjax-url]")
    $.pjax({
      push: false,
      scrollTo: false,
      url: $el.data("pjax-url"),
      type: $el.data("pjax-type"),
      fragment: Pjax.targetContainer($el.data("pjax-selector")),
      container: Pjax.targetContainer($el.data("pjax-selector")),
    })
    $.pjax.xhr = null
  })
})
