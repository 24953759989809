/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// Search Autocomplete
$(".search .input").on(
  "autocomplete:selectSuggestion",
  (obj, $selected) => (document.location.href = "/people/" + $selected.data("id")),
)

$.onmount(".nav-search", function () {
  return $(this).click(function () {
    const navShrunk = $(".nav").hasClass("shrink-nav")
    if (navShrunk) {
      // fires logic handled in NavToggle.tsx
      $(".nav-toggle").click()
      return $(".nav-search .input").focus()
    }
  })
})

// Timer
$.onmount("[data-pjax-container=timer-button]", function () {
  const options = {
    url: $(this).data("pjax-render"),
    container: "[data-pjax-container=timer-button]",
    push: false,
    scrollTo: false,
  }

  return setInterval(() => $.pjax(options), 30000)
})
