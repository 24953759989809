const $ = window.jQuery

function disableBtns() {
  $(".next-stage-btn").attr("disabled", true)
  $("[data-action=disqualify]").attr("disabled", true)
  $("[data-action=create-vote]").attr("disabled", true)
  $("[data-action=update-vote]").attr("disabled", true)
}

function createVote(e) {
  const vote = e.currentTarget.dataset.value
  const $voteInput = $("[name='job_application_vote[vote]']")
  const $form = $(".new_job_application_vote")

  disableBtns()

  $voteInput.val(vote)

  $.ajax({
    type: "POST",
    url: $form.attr("action"),
    data: $form.serialize(),
    success: function () {
      $.pjax.reload("[data-pjax-container='jobs-show']", {
        fragment: "[data-pjax-container='jobs-show']",
      })
    },
  })

  return false
}

function updateVote(e) {
  const vote = e.currentTarget.dataset.value
  const $voteInput = $("[name='job_application_vote[vote]']")
  const $form = $(".edit_job_application_vote")

  if (vote !== $voteInput.val()) {
    disableBtns()

    $voteInput.val(vote)

    $.ajax({
      type: "PUT",
      url: $form.attr("action"),
      data: $form.serialize(),
      success: function () {
        $.pjax.reload("[data-pjax-container='jobs-show']", {
          fragment: "[data-pjax-container='jobs-show']",
        })
      },
    })
  }

  return false
}

function addEventListeners() {
  $("[data-action=create-vote]").click(createVote)
  $("[data-action=update-vote]").click(updateVote)
}

$.onmount(".recruiting-alert-actions", () => {
  addEventListeners()
})
