class PdfRenderManager {
  constructor(_at_file) {
    this.file = _at_file
    this.pageCount = this.file.numPages
    this.pagesRendered = 1
    this.$fileContainer = $("<div/>")
    this.promise = new $.Deferred()
  }

  setScale(page, width) {
    return (this.scale = width / page.getViewport(1.0).width)
  }

  buildCanvas(page, width) {
    var $el
    this.setScale(page, width)
    $el = $("<canvas/>")
    $el[0].width = width
    $el[0].height = page.getViewport(this.scale).height
    return $el
  }

  getPageRenderContext($el, page) {
    return {
      canvasContext: $el[0].getContext("2d"),
      viewport: page.getViewport(this.scale),
    }
  }

  addPage(page) {
    var $el
    $el = this.buildCanvas(page, 700)
    this.$fileContainer.append($el)
    return page.render(this.getPageRenderContext($el, page))
  }

  addForPrint() {
    if (this.pagesRendered <= this.pageCount) {
      return this.file.getPage(this.pagesRendered).then(
        (function (_this) {
          return function (page) {
            return _this.addPage(page).then(function () {
              _this.pagesRendered++
              if (_this.pagesRendered > _this.pageCount) {
                _this.promise.resolve(_this.$fileContainer)
              }
              return _this.addForPrint()
            })
          }
        })(this),
      )
    }
  }
}

export { PdfRenderManager }
