/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const { Modal } = window

const saveSignature = function (e, modal) {
  e.preventDefault()
  const signatureData = $(".draw-signature canvas:visible, canvas.active:visible")[0].toDataURL()

  $("input.signature-data").val(signatureData)
  const img = new Image()
  img.src = signatureData
  $(".signature").html(img)

  return modal.close()
}

const drawSignatureCanvas = () => $(".draw-signature canvas")

const resizeSignatureCanvas = function () {
  const signatureData = window.signature.toDataURL()

  // Set dimensions on the HTML element. If not present the mouse pointer
  // position and the actual drawing on the canvas will be offset
  const $canvas = drawSignatureCanvas()
  $canvas[0].width = $canvas.width()
  $canvas[0].height = $canvas.height()

  return window.signature.fromDataURL(signatureData)
}

const loadGoogleWebFonts = function () {
  window.WebFontConfig = {
    google: {
      families: [
        "Zeyada::latin",
        "Reenie+Beanie::latin",
        "Mrs+Sheppards::latin",
        "Damion::latin",
        "Rock+Salt::latin",
      ],
    },
  }

  const s = document.createElement("script")
  const http = document.location.protocol === "https:" ? "https" : "http"
  s.src = `${http}://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js`
  s.type = "text/javascript"
  s.async = "true"

  const s0 = document.getElementsByTagName("script")[0]
  s0.parentNode.insertBefore(s, s0)

  window.fontLoader = new MutationObserver(previewTypedSignature)
  return window.fontLoader.observe(document.documentElement, { attributes: true })
}

const switchToType = function (e) {
  e.preventDefault()

  $(".type-signature").show().find("[data-signature-text]").focus()
  $(".draw-signature").hide()
  previewTypedSignature()
  changeActiveTab(e)
  return $(window).off("resize")
}

const switchToDraw = function (e) {
  e.preventDefault()

  $(".type-signature").hide().find("[data-signature-text]").focus()
  $(".draw-signature").show()
  changeActiveTab(e)
  resizeSignatureCanvas()
  return $(window).resize(resizeSignatureCanvas)
}

var changeActiveTab = function (e) {
  $(".modal .tab").removeClass("active")
  return $(e.currentTarget).addClass("active")
}

const clearPad = function (e) {
  e.preventDefault()
  return window.signature.clear()
}

const drawSignatureFromText = function (canvas, text, font) {
  const height = $(canvas).height()
  const width = $(canvas).width()
  canvas.height = height
  canvas.width = width
  const sidePaddingProportion = 0.05
  const topPaddingProportion = 0.2
  const bottomPaddingProportion = 0.3
  const canvasTopPadding = topPaddingProportion * height
  const canvasBottomPadding = bottomPaddingProportion * height
  const canvasInnerWidth = width - width * (sidePaddingProportion * 2)
  const canvasInnerHeight = height - canvasBottomPadding - canvasTopPadding
  let fontSize = canvasInnerHeight
  const context = canvas.getContext("2d")
  canvas.textAlign = "left"
  context.font = `${fontSize}px ${font}`
  context.textBaseline = "middle"

  const fontDoesNotFitInCanvas = () => context.measureText(text).width > canvasInnerWidth

  while (fontDoesNotFitInCanvas()) {
    fontSize -= 1
    context.font = `${fontSize}px ${font}`
  }

  context.clearRect(0, 0, width, height)
  return context.fillText(text, width * sidePaddingProportion, height / 2, canvasInnerWidth)
}

var previewTypedSignature = function (e) {
  if (e && typeof e.preventDefault === "function") {
    e?.preventDefault()
  }

  if (!document.documentElement.classList.contains("wf-active")) {
    return
  }

  const text = $("[data-signature-text]").val()

  if (text.length) {
    _.each($(".type-signature canvas"), (canvas) => drawSignatureFromText(canvas, text, canvas.id))
  }

  if (window.fontLoader !== null) {
    window.fontLoader.disconnect()
    return (window.fontLoader = null)
  }
}

const selectFont = function (e) {
  e.preventDefault()

  $("canvas.active").removeClass("active")
  return $(e.currentTarget).addClass("active")
}

const preloadSignature = function (modal) {
  let personData, previewData
  let signature = null
  if ((previewData = $(".preview-container .signature-data").val())) {
    signature = previewData
  } else if ((personData = $(modal).find("[data-person-signature]"))) {
    signature = personData.attr("data-person-signature")
  }

  if (signature !== null) {
    return window.signature.fromDataURL(signature)
  }
}

$.onmount(Modal.selector("signature"), function () {
  loadGoogleWebFonts()

  window.signature = new SignaturePad(drawSignatureCanvas().get(0))

  preloadSignature(this)

  $("[data-action=clear-signature]").click(clearPad)
  $("[data-action=type-signature]").click(switchToType)
  $("[data-action=draw-signature]").click(switchToDraw)
  $("[data-signature-text]").keyup(previewTypedSignature)
  $("[data-action=select-font]").click(selectFont)

  $(this).on("modal:open", (_e, modal) =>
    modal.$el.find("[data-action=save]").click((e) => saveSignature(e, modal)),
  )

  return $(this).on("modal:close", () => delete window.signature)
})
