import * as d3 from "d3"

// TIME UTILS

export const timeFormat = {
  month: d3.timeFormat("%b"),
  fullMonth: d3.timeFormat("%B"),
  year: d3.timeFormat("%Y"),
  day: d3.timeFormat("%e"),
  quarter: d3.timeFormat("%q"),
  monthDay: d3.timeFormat("%b %e"),
  monthYear: d3.timeFormat("%B %Y"),
  smMonthYear: d3.timeFormat("%b %Y"),
  dayMonthYear: d3.timeFormat("%b %e %Y"),
}

// parse a date in yyyy-mm-dd format
export const parseDate = (input) => {
  const parts = input.split(/[-T ]+/)
  // new Date(year, month [, day [, hours[, minutes[, seconds[, ms]]]]])
  return new Date(parts[0], parts[1] - 1, parts[2]) // Note: months are 0-based
}

export const getStartAndEndDate = (date) => [parseDate(date.date_start), parseDate(date.date_end)]

const formatHeaderDate = (dateObj) => {
  const [start, finish] = getStartAndEndDate(dateObj)
  if (start.getYear() !== finish.getYear()) {
    return `${timeFormat.monthYear(start)} - ${timeFormat.monthYear(finish)}`
  }
  if (start.getMonth() === finish.getMonth()) {
    return `${timeFormat.month(finish)} ${timeFormat.year(finish)}`
  }
  return `${timeFormat.fullMonth(start)} - ${timeFormat.fullMonth(finish)}, ${timeFormat.year(
    finish,
  )}`
}

export const getYears = (date1, date2) => d3.timeYear.count(date1, date2)

export const isStartOfYear = (date) => {
  const [start, finish] = getStartAndEndDate(date)
  const year = d3.timeYear(finish)
  return start <= year && finish >= year
}

export const isStartOfMonth = (date) => {
  const [start, finish] = getStartAndEndDate(date)
  const month = d3.timeMonth(finish)
  return start <= month && finish >= month
}

// LINE CHART DATA UTILS

const addDummyPoints = (data) => {
  data.unshift({
    ...data[0],
    is_dummy: true,
  })
  data.push({
    ...data[0],
    is_dummy: true,
  })
}

export const getChartData = (data) => {
  // Grabs turnover rate for each time point
  const timeData = data.agg.time_data
  const cumulativeData = data.agg.cumulative_data
  if (timeData.length === 1) addDummyPoints(timeData)
  const dY = timeData.map((d) => parseFloat(d.turnover_rate))
  const dX = timeData.map((d) => parseDate(d.date_end))
  const dO = d3.map(timeData, (d) => d)
  const dI = d3.map(timeData, (_, i) => i)
  // Filters out dummy values, if any
  const I = dI.filter((i) => !dO[i].is_dummy)
  const Y = dY.filter((_, i) => !dO[i].is_dummy)
  const X = dX.filter((_, i) => !dO[i].is_dummy)
  const O = dO.filter((d) => !d.is_dummy)
  const nonNullIndex = Y.findIndex((el) => !Number.isNaN(el))
  const dateExtent = getStartAndEndDate(cumulativeData)
  return {
    X,
    Y,
    O,
    I,
    dX,
    dY,
    dO,
    dI,
    dateExtent,
    cumulativeData,
    nonNullIndex,
  }
}

export const getLineDomains = (X, Y) => {
  // Compute domains.
  let maxY = d3.max(Y)
  if (maxY === 0) {
    maxY = 1.0
  }
  const xDomain = [0, X.length - 1]
  const yDomain = [0, maxY]
  return { xDomain, yDomain }
}

export const getLineScales = (X, Y, xRange, yRange) => {
  const { xDomain, yDomain } = getLineDomains(X, Y)
  // Manual scale
  const xScale = d3.scaleLinear(xDomain, xRange)
  const yScale = d3.scaleLinear(yDomain, yRange).nice()
  return { xScale, yScale }
}

export const convertToFixed = (d) => parseFloat(d).toFixed(2)

// TOOLTIP UTILS

export const getChildTspans = (selection) => {
  const tspans = selection.selectAll("tspan")
  const tooltipSelections = new Map(
    tspans.nodes().map((d) => {
      const classes = [...d.classList]
      const name = classes.filter((d) => d.includes("tooltip"))[0]
      const sel = selection.select(`.${name}`)
      return [name, sel]
    }),
  )
  return Object.fromEntries(tooltipSelections)
}

export function insertLineBreaks() {
  const el = d3.select(this)
  const words = el.text().split(" ")
  el.text("")
  for (let i = 0; i < words.length; i += 1) {
    const tspan = el.append("tspan").text(words[i])
    if (i > 0) tspan.attr("x", 0).attr("dy", "12")
  }
}

// Tooltip + event handler functions
// Compute tooltip labels.
export const formatTooltipDate = (dateObj, resolution, dateRange = null) => {
  const finish = getStartAndEndDate(dateObj)[1]
  let text
  switch (resolution) {
    case "monthly":
      text = `${timeFormat.month(finish)} ${timeFormat.year(finish)}`
      break
    case "quarterly":
      text = `Q${timeFormat.quarter(finish)} ${timeFormat.year(finish)}`
      break
    case "annually":
      if (dateRange === "one_year") {
        text = formatHeaderDate(dateObj)
        break
      }
      text = timeFormat.year(finish)
      break
    default:
      text = timeFormat.month(finish)
      break
  }
  return text
}
