const $ = window.jQuery
const Pikaday = window.Pikaday

class PikadayInput {
  constructor(inputField, options = {}) {
    if (inputField instanceof $) {
      this.inputField = inputField.get(0)
    } else {
      this.inputField = inputField
    }

    this.useNativeDatePickerOnTouch = options.useNativeDatePickerOnTouch ?? false
  }

  create(options = {}) {
    if (this.inputField.picker) {
      return this.inputField.picker
    }

    if (this.isNativeDatePickerPreferred()) {
      this.inputField.type = "date"
      return this.inputField
    }

    const picker = new Pikaday(
      $.extend({ field: this.inputField }, PikadayInput.defaultOptions(), options),
    )
    this.inputField.picker = picker
    return picker
  }

  isNativeDatePickerPreferred() {
    const hasTouch = "ontouchstart" in window
    return hasTouch && PikadayInput.supportsDate() && this.useNativeDatePickerOnTouch
  }

  static defaultOptions() {
    return {
      yearRange: [1920, 2025],
      format: "YYYY-MM-DD",
    }
  }

  static supportsDate() {
    const input = document.createElement("input")
    input.setAttribute("type", "date")

    const notADateValue = "not-a-date"
    input.setAttribute("value", notADateValue)

    return input.value !== notADateValue
  }
}

window.PikadayInput = PikadayInput
