/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const keyMap = {
  9: "tab",
  27: "esc",
  37: "left",
  39: "right",
  13: "enter",
  38: "up",
  40: "down",
  91: "command",
  93: "command",
  16: "shift",
  17: "control",
  18: "alt",
}

class Keyboard {
  static keyMatchesName(code, name) {
    return keyMap[code] === name
  }

  static getKey(code) {
    return keyMap[code] || null
  }
}

window.Keyboard = Keyboard
