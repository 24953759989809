const { ButtonHelpers, moment, Pikaday, jQuery: $ } = window

class ProfileTimeSheets {
  constructor(container) {
    this.$container = container
    this.pjaxUrl = this.$container.attr("data-pjax-url")
    this.initSubmitButton()
    this.initSaveAsDraftButton()
    this.initJumpToWeek()
    this.initEntryToggle()
    this.initAddComment()
    this.$container.one("pjax:rerender", () => this.reloadPage())
  }

  get pjaxContainerId() {
    return `#${this.$container.attr("data-pjax-container")}`
  }
  get pjaxDefaultOptions() {
    return {
      container: this.pjaxContainerId,
      push: false,
      replace: true,
    }
  }

  reloadPage() {
    $.pjax.reload(this.pjaxContainerId, { fragment: this.pjaxContainerId })
  }

  saveEntryRequests() {
    const requests = []
    this.$container.find(".edit_time_entry, .new_time_entry").each(function entrySave() {
      requests.push(
        $.post({
          url: this.action,
          data: $(this).serialize(),
          dataType: "json",
        }),
      )
    })
    return requests
  }

  disablePageActions() {
    this.$container
      .find(".page-primary-actions input, .page-primary-actions button")
      .addClass("disabled")
  }

  saveAllEntries() {
    this.disablePageActions()
    return $.when(...this.saveEntryRequests())
  }

  postWithPjax(form, options = {}) {
    $.pjax(
      $.extend(
        {},
        this.pjaxDefaultOptions,
        {
          url: form.attr("action"),
          data: form.serialize(),
          type: "POST",
          replace: false,
        },
        options,
      ),
    )
  }

  initSubmitButton() {
    const self = this
    this.$container
      .find("[data-action=submit], [data-action=approve], [data-action=unapprove]")
      .click(function onClickSubmit() {
        ButtonHelpers.setButtonState(this, "disabled")
        const $form = $(this).parents("form")
        self.saveAllEntries().then(() => self.postWithPjax($form))
      })
  }

  initSaveAsDraftButton() {
    const self = this
    this.$container.find("[data-action=save]").click(function onSaveAsDraft() {
      ButtonHelpers.setButtonState(this, "disabled")
      self.saveAllEntries().then(() => self.reloadPage())
    })
  }

  initJumpToWeek() {
    const jumpButton = this.$container.find("[data-action=jump-week]")
    const self = this

    new Pikaday({
      field: jumpButton.get(0),
      yearRange: [1920, 2025],
      pickWholeWeek: true,
      setDefaultDate: true,
      defaultDate: moment(jumpButton.text(), "MMM D, YYYY").toDate(),
      minDate: moment(jumpButton.attr("data-min-date")).startOf("week").toDate(),
      onSelect: (date) => {
        const fDate = moment(date).format("YYYY-MM-DD")

        $.pjax($.extend({ url: `${self.pjaxUrl}?start=${fDate}` }, self.pjaxDefaultOptions))
      },
    })
  }

  initEntryToggle() {
    const self = this
    this.$container.find(".toggle-entries").click(function showEntries(e) {
      self.$container.find(".time-entries-holder").slideToggle()
      const $toggle = $(e.currentTarget)
      const $icon = $toggle.find(".svg-inline--fa")
      $icon.toggleClass("fa-chevron-down fa-chevron-right")
      if ($toggle.attr("data-settings-url") !== undefined) {
        $.ajax({
          contentType: "application/json",
          dataType: "json",
          type: "PUT",
          url: $toggle.attr("data-settings-url"),
          data: JSON.stringify({
            timesheets: { show_details: $icon.hasClass("fa-chevron-down") },
          }),
        })
      }
    })
  }

  initAddComment() {
    const self = this
    this.$container.find("[data-action=save-comment]").click(function addComment(e) {
      e.preventDefault()
      self.postWithPjax($(this).parents("form"), { scrollTo: true })
    })
  }
}

window.ProfileTimeSheets = ProfileTimeSheets
