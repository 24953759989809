const $ = window.jQuery

// Helper class to add items to a given container that are styled as a '.list-group'.
// Each list group item will have a hidden input HTML element and a link to remove itself from the
// list.
// See PTO add notification modal for usage example.
//
class SelectedListGroup {
  constructor(container, inputName) {
    this.$container = container
    this.inputName = inputName
  }

  static containerVisibleCss() {
    return "list-group mb-4"
  }

  static removeIcon() {
    return $("<i/>").addClass("far fa-times").attr("area-hidden", true)
  }

  static removeLink() {
    return $("<a/>").attr("data-action", "delete").append(SelectedListGroup.removeIcon())
  }

  hiddenInput(inputValue) {
    return $("<input/>").attr({ type: "hidden", name: this.inputName, value: inputValue })
  }

  checkPersonListCss() {
    if (this.$container.children().length > 0 && !this.$container.hasClass("list-group")) {
      this.$container.addClass(SelectedListGroup.containerVisibleCss())
    } else if (this.$container.children().length === 0) {
      this.$container.removeClass(SelectedListGroup.containerVisibleCss())
    }
  }

  listItem(listText, inputValue) {
    const $removeLink = SelectedListGroup.removeLink()
    const $listItem = $("<div/>")
      .addClass("list-group-item")
      .text(listText)
      .append($removeLink)
      .append(this.hiddenInput(inputValue))

    $removeLink.on("click", () => {
      $listItem.remove()
      this.checkPersonListCss()
    })

    return $listItem
  }

  addListItem(listText, inputValue) {
    if (this.findInput(inputValue).length === 0) {
      this.$container.append(this.listItem(listText, inputValue))
      this.checkPersonListCss()
    }
  }

  findInput(inputValue) {
    return this.$container.find(`input[type=hidden][value=${inputValue}]`)
  }
}

window.SelectedListGroup = SelectedListGroup
