/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS204: Change includes calls to have a more natural evaluation order
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
class Autocomplete {
  static initClass() {
    this.prototype.inputChanged = _.debounce(
      function (e) {
        this.selected = this.$highlighted = null

        this.$input.parent().find("img.prefix").remove()

        if (this.$input.val().length === 0 && !this.preloadSuggestions) {
          this.hide()
          this.$input.trigger("autocomplete:clearSelected")
        } else {
          this.getSuggestions()
        }
        this.$input.trigger("autocomplete:inputChanged")

        if (this.filterOptionsPresent && this.$input.val().length === 0) {
          this.showFilterOptions()
        } else if (this.filterOptionsPresent) {
          this.hideFilterOptions()
        }

        return this.toggleX()
      },
      window.gon?.rate_limit_search ? 1000 : 250,
    )
  }
  constructor(input) {
    this.clickSuggestion = this.clickSuggestion.bind(this)
    this.$input = $(input)
    this.$input.data("autocomplete-instance", this)
    this.autocompleteClass = this.$input.data("autocomplete-wrapper-class") || ""
    this.currentQuery = input.value
    this.remoteURL = this.$input.data("autocomplete-url")
    this.cachedResponse = {}
    this.suggestionsContainer = $(`\
<div \
class=\"autocomplete-container ${this.autocompleteClass}\" \
data-autocomplete-container=\"${this.$input.data("autocomplete")}\"> \
</div>\
`)
    this.$input.parent().append(this.suggestionsContainer)
    input.setAttribute("autocomplete", "off")
    this.$input.on(this.inputEvents())

    this.$searchFilters = this.$input.siblings(".search-filters")
    this.filterOptionsPresent = this.$searchFilters.length > 0
    if (this.filterOptionsPresent) {
      this.$searchFilters.find("a").on("click", (e) => this.handleFilterClick(e))
    }

    this.$input.siblings("[data-action=clear-search]").on("click", (e) => this.clearSearch(e))
    this.preloadSuggestions = this.$input.data("preload-suggestions") === true
  }

  inputEvents() {
    return {
      focus: () => this.focusInput(),
      blur: () => this.blurInput(),
      keydown: (e) => {
        const key = e.which
        switch (false) {
          case !Keyboard.keyMatchesName(key, "enter"):
            return this.onEnter(e)
          case !Keyboard.keyMatchesName(key, "esc"):
            return this.blurInput()
          case !Keyboard.keyMatchesName(key, "up"):
            return this.highlightPrevious()
          case !Keyboard.keyMatchesName(key, "down"):
            return this.highlightNext()
          case !Keyboard.keyMatchesName(key, "tab"):
            return this.onTabDown(e)
        }
      },
      keyup: (e) => {
        const key = e.which
        switch (false) {
          case !Keyboard.keyMatchesName(key, "enter"):
            return
          case !Keyboard.keyMatchesName(key, "up"):
            return
          case !Keyboard.keyMatchesName(key, "down"):
            return
          case !Keyboard.keyMatchesName(key, "left"):
            return
          case !Keyboard.keyMatchesName(key, "right"):
            return
          case !Keyboard.keyMatchesName(key, "tab"):
            return
          case !Keyboard.keyMatchesName(key, "shift"):
            return
          default:
            return this.inputChanged()
        }
      },
    }
  }

  blurInput(e) {
    return setTimeout(() => {
      if (this.cancelBlur) {
        return (this.cancelBlur = false)
      } else {
        return this.hide()
      }
    }, 300)
  }

  focusInput(e) {
    if (this.preloadSuggestions && this.$input.val() === "") {
      this.getSuggestions()
    } else {
      let needle
      if (((needle = this.$input.val()), !["", this.currentQuery].includes(needle))) {
        this.getSuggestions()
      }
    }

    if (this.filterOptionsPresent) {
      return this.showFilterOptions(e)
    }
  }

  onEnter(e) {
    e.preventDefault()
    if (window.rate_limit_search) {
      this.getSuggestions()
    }
    if (this.$highlighted && this.$highlighted.data("autocompleteIgnore")) {
      return
    }
    if (this.$highlighted && this.$highlighted.length > 0 && !this.selected) {
      return this.selectSuggestion(this.$highlighted, e)
    }
  }

  highlightPrevious() {
    if (this.$highlighted) {
      this.$highlighted.removeClass("highlight")
    }

    if (!this.$highlighted || this.$highlighted.is(":first-child")) {
      this.$highlighted = this.suggestionsList.children(":not(.disabled)").last()
    } else {
      this.$highlighted = this.$highlighted.prev(":not(.disabled)")
    }

    return this.$highlighted.addClass("highlight")
  }

  highlightNext() {
    if (this.$highlighted) {
      this.$highlighted.removeClass("highlight")
    }

    if (!this.$highlighted || this.$highlighted.is(":last-child")) {
      this.$highlighted = this.suggestionsList.children(":not(.empty-message)").first()
    } else {
      this.$highlighted = this.$highlighted.next(":not(.disabled)")
    }

    return this.$highlighted.addClass("highlight")
  }

  onTabDown(e) {
    if (!e.shiftKey) {
      e.preventDefault()
      if (this.$highlighted && this.$highlighted.data("autocompleteIgnore")) {
        return
      }

      if (this.$highlighted != null && this.$highlighted.length > 0) {
        return this.selectSuggestion(this.$highlighted, e)
      } else {
        const field = $(e.currentTarget)
        return this.selectNextField(field)
      }
    }
  }

  selectNextField(field) {
    const $visible_form_controls = $(":input:visible:not(.skip-tab)")
    const index = $visible_form_controls.index(field)
    const $next = $visible_form_controls.get(index + 1)
    if ($next) {
      return $next.focus()
    }
  }

  selectSuggestion($suggestion, evt) {
    this.selected = $suggestion
    if (this.selected.hasClass("disabled")) {
      return
    }
    if (this.selected != null) {
      const displayText =
        $suggestion.find(".list-group-item-title").text().trim() || $suggestion.text().trim()
      if (displayText) {
        this.$input.val(displayText)
        this.currentQuery = this.$input.val()
      }

      const receivedId = $suggestion.data("id")
      const autocompleteInputNameId = this.$input.data("id")
      $(`#${autocompleteInputNameId}`).val(receivedId)
      $(`#${autocompleteInputNameId}`).trigger("change")

      this.$input.trigger("autocomplete:selectSuggestion", [$suggestion, evt])
    }

    this.hide()
    return this.suggestionsContainer.empty()
  }

  getSuggestions() {
    let cacheKey
    this.currentQuery = this.$input.val()
    const q = encodeURIComponent(this.currentQuery)

    const { remoteURL } = this
    if (remoteURL.indexOf("?") > 0) {
      cacheKey = remoteURL + "&query=" + q
    } else {
      cacheKey = remoteURL + "?query=" + q
    }

    const exclude = encodeURIComponent(this.$input.data("exclude"))
    if (exclude && exclude !== "undefined") {
      cacheKey += `&exclude=${exclude}`
    }

    const job_id = encodeURIComponent(this.$input.data("job-id"))
    if (job_id !== "undefined") {
      cacheKey += `&job_id=${job_id}`
    }

    const chart_id = encodeURIComponent(this.$input.data("chart-id"))
    if (chart_id !== "undefined") {
      cacheKey += `&chart_id=${chart_id}`
    }

    const autocompleteParams = this.$input.data("autocomplete-params")
    if (autocompleteParams) {
      for (var key in autocompleteParams) {
        var val = autocompleteParams[key]
        cacheKey += `&${key}=${val}`
      }
    }

    const response = this.cachedResponse[cacheKey]

    if (response) {
      this.suggestions = response
      return this.renderSuggestions()
    } else {
      return $.ajax({
        url: cacheKey,
        type: "GET",
        contentType: "application/json",
      }).done((data) => {
        this.suggestions = data
        this.cachedResponse[cacheKey] = data
        return this.renderSuggestions()
      })
    }
  }

  renderSuggestions() {
    if (!this.$input.is(":focus")) {
      return
    }

    this.suggestionsContainer.empty()

    this.suggestionsContainer.append(this.suggestions)
    this.suggestionsList = this.suggestionsContainer.children(".autocomplete-list")
    this.highlightNext()
    this.suggestionsContainer.find(".list-group-item").mousedown(this, this.clickSuggestion)

    this.suggestionsContainer.show()
    this.$input.trigger("autocomplete:renderSuggestions")
    if (this.$input.val() !== "" || !this.preloadSuggestions) {
      return this.focusInput()
    }
  }

  clickSuggestion(e) {
    e.preventDefault()
    e.stopPropagation()
    return this.selectSuggestion($(e.currentTarget), e)
  }

  hide() {
    this.$highlighted = null
    this.suggestionsContainer.hide()
    if (this.filterOptionsPresent) {
      return this.hideFilterOptions()
    }
  }

  showFilterOptions(e) {
    if (this.$input.val() !== "") {
      return
    }
    return this.$searchFilters.show()
  }

  hideFilterOptions(e) {
    if (e && $(e.relatedTarget).parent().hasClass("search-filters")) {
      return
    }

    this.$searchFilters.hide()
    this.$searchFilters.find(".active").removeClass("active")
    return this.$searchFilters.find("a").first().addClass("active")
  }

  handleFilterClick(e) {
    e.preventDefault()
    this.cancelBlur = true
    this.$input.focus()

    const $clicked = $(e.currentTarget)

    if (this.$input.val() === "" && $clicked.text() !== "Everything".t("org_chart")) {
      this.$input.val($clicked.data("filter-text"))
    }

    this.hideFilterOptions()
    return this.toggleX()
  }

  clearSearch(e) {
    e.preventDefault()

    this.cancelBlur = true
    this.$input.val("")
    this.$input.focus()
    this.hide()
    this.showFilterOptions(e)
    return this.toggleX()
  }

  toggleX() {
    if (this.$input.val() === "") {
      return this.$input.siblings(".suffix").css("display", "none")
    } else {
      return this.$input.siblings(".suffix").css("display", "flex")
    }
  }
}
Autocomplete.initClass()

$.onmount("[data-autocomplete]", function () {
  return new Autocomplete(this)
})
