/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount(".permissions-person", function () {
  $(this).on("autocomplete:selectSuggestion", function (obj, $selected) {
    const personId = $selected.data("id")
    const personFullName = $selected.find(".list-group-item-title").text().trim()

    const $input = $("#person_name")

    $input.data("person-id", personId)
    $input.data("person-full-name", personFullName)

    return $("#person_id").val(personId)
  })

  return $(this).on("autocomplete:inputChanged", function (e) {
    const $input = $("#person_name")
    const inputValue = $input.val()
    const personFullName = $input.data("person-full-name")

    if (inputValue !== personFullName) {
      $("#person_id").val("")

      $input.removeData("person-id")
      return $input.removeData("person-full-name")
    }
  })
})
