const $ = window.jQuery

/**
 * Simple carousel for handling onboarding tips for ADP
 * first-time marketplace users.
 */
class TipsCarousel {
  constructor($el) {
    this.$carousel = $el
    this.tipsCollection = $el.find(".onboarding-tip")
  }

  init() {
    this.$carousel.find(".prev").on("click", (e) => {
      this.navigatePrev.call(this, e)
    })
    this.$carousel.find(".next").on("click", (e) => {
      this.navigateNext.call(this, e)
    })
    let activeTipOffset = localStorage.getItem("active-onboarding-tip")
    if (
      isNaN(activeTipOffset) ||
      activeTipOffset > this.tipsCollection.length - 1 ||
      activeTipOffset < 0
    ) {
      activeTipOffset = 0
    }
    this.activateTip(activeTipOffset)
  }

  navigateNext(e) {
    e.preventDefault()
    const activeOffset = this.activeTipIndex()
    let newOffset = activeOffset + 1

    if (newOffset > this.tipsCollection.length - 1 || newOffset === -1) {
      newOffset = 0
    }

    this.activateTip(newOffset)
  }

  navigatePrev(e) {
    e.preventDefault()
    const activeOffset = this.activeTipIndex()
    let newOffset = activeOffset - 1

    if (newOffset < 0) {
      newOffset = this.tipsCollection.length - 1
    }

    this.activateTip(newOffset)
  }

  activateTip(index) {
    this.tipsCollection.removeClass("active").addClass("hidden")
    this.$carousel.find(".onboarding-tip").eq(index).removeClass("hidden").addClass("active")
    localStorage.setItem("active-onboarding-tip", index)
  }

  activeTipIndex() {
    return Array.from(this.$carousel.find(".onboarding-tip")).findIndex((element) =>
      $(element).hasClass("active"),
    )
  }
}

$.onmount(".tips-carousel", function onMount() {
  const tipsCarousel = new TipsCarousel($(this))
  tipsCarousel.init()
})
