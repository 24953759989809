/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
;(function ($) {
  let MaskedInputsHelper
  $.fn.maskAllInputs = function () {
    return this.find("input[data-maskedinput]").each(function () {
      return $(this).maskInputs()
    })
  }

  $.fn.maskInputs = function (options) {
    const helper = new MaskedInputsHelper(options)

    const $el = $(this)
    const maskingType = $el.data("maskedinput")

    if (typeof helper[maskingType] === "function") {
      helper[maskingType]($el)
    } else {
      $.error(`unknown masking type: ${maskingType}`)
    }

    $el.on("keypress", function (e) {
      if (Keyboard.keyMatchesName(e.which, "enter" && $el.val().indexOf("_") !== -1)) {
        return e.preventDefault()
      }
    })

    return $(this)
  }

  return (MaskedInputsHelper = class MaskedInputsHelper {
    constructor(options) {
      this.options = options
      this.options ||= {}
    }

    closeDatePicker(el) {
      return el?.[0]?.picker?.hide()
    }

    phone($el) {
      return $el.mask("(999) 999-9999", this.options)
    }

    phoneext($el) {
      return $el.mask("(999) 999-9999? ext. 999999", this.options)
    }

    ssn($el) {
      return $el.mask("999-99-9999", this.options)
    }

    date($el) {
      const mask = "YYYY-MM-DD"
      const self = this
      $.extend(this.options, {
        placeholder: mask,
        completed() {
          return self.closeDatePicker(this)
        },
      })
      return $el.mask("9999-99-99", this.options)
    }

    monthday($el) {
      const self = this
      $.extend(this.options, {
        placeholder: "Jan 01",
        completed() {
          return self.closeDatePicker(this)
        },
      })
      return $el.mask("aaa 99", this.options)
    }

    time($el) {
      $.mask.definitions["H"] = "[0-1]"
      $.mask.definitions["h"] = "[0-9]"
      $.mask.definitions["M"] = "[0-5]"
      $.mask.definitions["m"] = "[0-9]"
      $.mask.definitions["P"] = "[AaPp]"
      $.mask.definitions["p"] = "[Mm]"

      return $el.mask("Hh:Mm Pp", this.options)
    }

    zip($el) {
      return $el.mask("99999", this.options)
    }

    fullzip($el) {
      return $el.mask("99999?-9999", this.options)
    }

    state($el) {
      return $el.mask("aa", this.options)
    }

    cc_exp($el) {
      return $el.mask("99/99", this.options)
    }
  })
})(jQuery)
