import { easeCubicInOut, scaleLinear, select } from "d3"
import { defaults, pick } from "lodash"

import pickChartOptionsOfNode from "../utils/pickChartOptionsOfNode"
import pickConfiguredDimensions from "../utils/pickConfiguredDimensions"
import useOrAppendSVG from "../utils/useOrAppendSVG"

const COLOR_NEUTRAL_8_SOLID = "rgb(237, 237, 242)"
const COLOR_PRIMARY_80_SOLID = "rgb(072, 129, 255)"

const MinMaxAverageChartBaseConfig = Object.freeze({
  disableAnimation: false,
  entry: {},
  avgLabel: null,
  minLabel: null,
  maxLabel: null,
  radiusOfAverage: 5,
  tickWidth: 2,
  tickHeight: 10,
})

const OptionKeys = Object.freeze([
  ...Object.keys(MinMaxAverageChartBaseConfig),
  "aggMin",
  "aggMax",
  "leftMargin",
  "rightMargin",
])

/** @private */
function configure(node, options) {
  const partialBase = defaults(
    pickConfiguredDimensions(options, node),
    pick(options, OptionKeys),
    pick(pickChartOptionsOfNode(node), OptionKeys),
    MinMaxAverageChartBaseConfig,
    { leftMargin: 50, rightMargin: 50 },
  )

  const entry = partialBase.entry || {}
  const base = defaults(partialBase, { aggMin: entry.min, aggMax: entry.max })
  const xOf = scaleLinear()
    .domain([base.aggMin, base.aggMax])
    .range([base.leftMargin, base.width - base.rightMargin])

  return {
    ...base,
    xOf,
    tick: { width: base.tickWidth, height: base.tickHeight },
    widthOf: ({ max, min }) => xOf(max) - xOf(min),
  }
}

/** @private */
// eslint-disable-next-line no-unused-vars
function appendChartGroup(selection, _config) {
  selection.append("g").attr("transform", "translate(0, 4)")
}

/** @private */
function appendPrimaryAxis(selection, { entry, height, widthOf, xOf }) {
  const yCenter = height / 2

  selection
    .select("g")
    .append("rect")
    .attr("x", xOf(entry.min))
    .attr("y", yCenter - 4)
    .attr("height", "8px")
    .attr("width", `${widthOf(entry)}px`)
    .attr("data-part", "primary-axis")
    .style("fill", COLOR_NEUTRAL_8_SOLID)
}

/** @private */
function appendMean(selection, { avgLabel, entry, height, radiusOfAverage, xOf }) {
  const yCenter = height / 2

  // Dot
  selection
    .select("g")
    .append("circle")
    .attr("cx", xOf(entry.avg))
    .attr("cy", yCenter)
    .attr("r", radiusOfAverage)
    .attr("data-part", "mean")
    .style("fill", COLOR_PRIMARY_80_SOLID)

  selection
    .select("g")
    .append("text")
    .attr("class", "f-10")
    .attr("data-part", "mean")
    .attr("x", xOf(entry.avg))
    .attr("y", yCenter - 12)
    .attr("text-anchor", "middle")
    .attr("dominant-baseline", "middle")
    .text(avgLabel)
}

/** @private */
function appendMinMax(
  selection,
  { entry, height, tick, radiusOfAverage, minLabel, maxLabel, xOf, widthOf },
) {
  const yCenter = height / 2
  const diameterOfAverageDot = 2 * radiusOfAverage

  // Do not render the min/max bar when they would collide with the dot.
  if (widthOf(entry) <= diameterOfAverageDot) {
    return
  }

  // Left label tick
  selection
    .select("g")
    .append("rect")
    .attr("x", xOf(entry.min))
    .attr("y", yCenter - 5)
    .attr("data-part", "mix-tick")
    .attr("width", `${tick.width}px`)
    .attr("height", `${tick.height}px`)
    .style("fill", "#7A7A7A")

  // Left label text
  selection
    .select("g")
    .append("text")
    .attr("class", "f-10")
    .attr("x", xOf(entry.min) - 5)
    .attr("y", yCenter)
    .attr("data-part", "min-tick-text")
    .attr("text-anchor", "end")
    .attr("alignment-baseline", "middle")
    .text(minLabel)
    .style("alignment-baseline", "middle")
    .style("dominant-baseline", "middle")
    .style("fill", "#909090")

  // Right label tick
  selection
    .select("g")
    .append("rect")
    .attr("x", xOf(entry.max))
    .attr("y", yCenter - 5)
    .attr("data-part", "max-tick")
    .attr("height", `${tick.height}px`)
    .attr("width", `${tick.width}px`)
    .style("fill", "#7A7A7A")

  // Right label text
  selection
    .select("g")
    .append("text")
    .attr("class", "f-10")
    .attr("x", xOf(entry.max) + 5)
    .attr("y", yCenter)
    .attr("data-part", "max-tick-text")
    .text(maxLabel)
    .style("alignment-baseline", "middle")
    .style("dominant-baseline", "middle")
    .style("fill", "#909090")
    .style("alignment-baseline", "middle")
    .style("text-anchor", "start")
}

/** @private */
function maybeTransitionChart(
  selection,
  { aggMin, aggMax, disableAnimation, entry, widthOf, xOf },
) {
  if (disableAnimation) {
    return
  }

  const durationOf = scaleLinear().domain([aggMin, aggMax]).range([600, 0])

  selection
    .selectAll("[data-part=primary-axis]")
    .attr("width", 0)
    .transition()
    .duration(durationOf(entry.min))
    .delay(600 - durationOf(entry.min))
    .ease(easeCubicInOut)
    .attr("width", widthOf(entry))

  selection
    .selectAll("[data-part=mean]")
    .style("opacity", 0)
    .transition()
    .delay(400)
    .duration(200)
    .ease(easeCubicInOut)
    .style("opacity", 1)

  selection
    .selectAll("[data-part=max-tick]")
    .attr("x", xOf(entry.min))
    .transition()
    .duration(durationOf(entry.min))
    .delay(600 - durationOf(entry.min))
    .ease(easeCubicInOut)
    .attr("x", xOf(entry.max))

  selection
    .selectAll("[data-part=max-tick-text]")
    .attr("x", xOf(entry.min) + 5)
    .transition()
    .duration(durationOf(entry.min))
    .delay(600 - durationOf(entry.min))
    .ease(easeCubicInOut)
    .attr("x", xOf(entry.max) + 5)
}

/**
 * Displays the min, max, and average of a dataset on top of a bar that is
 * arranged and scaled relative to an aggregation.
 *
 * While this only renders a single bar, it is meant to be used as a larger set
 * of charts. Provide the `data-agg-min` and `data-agg-max` values so your chart
 * can be positioned accurately relative to other charts.
 *
 *     MinMaxAverageChart('#some-dom-node-for-my-chart', {
 *       data: { min: 3, max: 5, avg: 4 },
 *       aggMin: 1,
 *       aggMax: 10,
 *     });
 *
 * When aggregate values aren't given, the rows min and max values are promoted
 * and treated as aggregate values.
 *
 * @example
 *
 *     // Mount aggregate
 *     MinMaxAverageChart('#agg-node', { data: { min: 1, max: 10, avg: 5 }})
 *
 *     // Mount individual rules.
 *     each([
 *       { label: "SLC", min: 3, max: 6, avg: 5 },
 *       { label: "DEN", min: 1, max: 2, avg: 2 },
 *       { label: "NYC", min: 8, max: 10, avg: 9 },
 *     ], data => MinMaxAverageChart('...', { data, aggMin: 1, aggMax: 10 }));
 *
 * That would result in:
 *
 *     *AGG* |  1 |----*-----| 10  |
 *      SLC  |    3 |--*-| 6       |
 *      DEN  |  1 |-*| 2           |
 *      NYC  |         8 |-*-| 10  |
 *
 * @param {string | HTMLElement | Node} nodeOrSelector
 * @param {object} options
 * @returns {d3.Selection} The root d3 selection of your node or selector.
 */
function MinMaxAverageChart(nodeOrSelector, options = {}) {
  const selection = select(nodeOrSelector)
  const config = configure(selection.node(), options)

  return useOrAppendSVG(selection, config)
    .call(appendChartGroup, config)
    .call(appendPrimaryAxis, config)
    .call(appendMean, config)
    .call(appendMinMax, config)
    .call(maybeTransitionChart, config)
}

export default MinMaxAverageChart
