const Pjax = window.Pjax
const moment = window.moment
const PersonSchedule = window.PersonSchedule
const PikadayInput = window.PikadayInput
const $ = window.jQuery

class TimeOffValidator {
  constructor($form, personSchedule) {
    this.$form = $form
    this.$formErrorDiv = this.$form.find(".form-error-messages").eq(0)
    this.$submitForm = this.$form.find("[type=submit]")
    this.initTimeProperties()
    this.personSchedule = new PersonSchedule(personSchedule)
    this.newRequest = TimeOffValidator.isNewRequest($form.attr("id"))
    this.hoursUrl = this.$form.find(Pjax.targetContainer("calculate-hours")).data("hours-url")
  }

  static isNewRequest(formId) {
    return /^new_.*/.test(formId)
  }

  initTimeProperties() {
    this.interval = this.$form.find("input[name=pto_request_interval]").val()
    this.dayIncrements = ["full_day", "half_day"].includes(this.interval)
    this.overtimePolicy = this.$form.find("input[name=overtime_policy]").val() === "true"
    this.processed_days = this.$form.find("input[name=processed_days]").val()

    this.$startDate = this.$form.find("input#pto_request_start_date")
    this.$startTime = this.$form.find("#pto_request_start_time")
    this.$endDate = this.$form.find("input#pto_request_end_date")
    this.$endTime = this.$form.find("#pto_request_end_time")

    if (this.$startDate.val().length === 0 && this.$endDate.val().length === 0) {
      this.$submitForm.prop("disabled", true).addClass("btn-disabled")
    }

    this.initPicker()
    if (this.interval === "half_day") {
      this.updateHalfDayOptions()
    }
    this.addListeners()
  }

  initPicker() {
    this.startDatePicker = new PikadayInput(this.$startDate).create({
      // Pikaday does not pass an event to onClose callbacks.
      onClose: () => {
        this.checkDateAndTime(new Event("picker:close"))
      },
    })
    const options = {
      onClose: () => {
        this.checkDateAndTime(new Event("picker:close"))
      },
    }
    if (this.processed_days >= 0) {
      options.minDate = moment(this.$startDate.val()).add(this.processed_days, "days").toDate()
    }
    this.endDatePicker = new PikadayInput(this.$endDate).create(options)
  }

  addListeners() {
    this.$startTime.change((e) => {
      this.checkDateAndTime(e)
    })
    this.$endTime.change((e) => {
      this.checkDateAndTime(e)
    })

    const self = this
    $("[data-pjax-container=modal-content]").on("pjax:beforeSend", (event, xhr, settings) => {
      settings.data.set("pto_request[start_time]", self.startTime())
      settings.data.set("pto_request[end_time]", self.endTime())
    })
  }

  static loader() {
    return '<span class="pulser"><span/></span>'
  }

  hoursDiv() {
    return this.$form.find("#hours-available, #hours-requested")
  }

  showError(message) {
    this.$formErrorDiv.text("")
    if (message !== null) {
      this.$formErrorDiv.text(message)
      this.$formErrorDiv.addClass("active")
      this.$submitForm.prop("disabled", true).addClass("btn-disabled")
    }
  }

  clearError() {
    this.$formErrorDiv.text("")
    this.$formErrorDiv.removeClass("active")
    this.$submitForm.removeAttr("disabled").removeClass("btn-disabled")
  }

  static firstHalfOption() {
    return new Option("First Half".t("profile", "pto_request"), "first_half")
  }

  // Must set picker values when the values are typed in rather than selected via the picker.
  syncPickerDateValues() {
    if (this.$startDate.val() !== this.startDatePicker.toString()) {
      this.startDatePicker.setMoment(moment(this.$startDate.val()))
    }
    if (this.$endDate.val() !== this.endDatePicker.toString()) {
      this.endDatePicker.setMoment(moment(this.$endDate.val()))
    }
  }

  invalidDateEntries() {
    this.syncPickerDateValues()
    if (this.$startDate.val().length && this.$endDate.val().length) {
      return !this.endDateAfterStartDate()
    }
    return true
  }

  singleDayRequest() {
    return this.startDatePicker.toString() === this.endDatePicker.toString()
  }

  endDateAfterStartDate() {
    if (this.endDatePicker.getDate() < this.startDatePicker.getDate()) {
      this.showError("end_date_after_start_date".t("profile", "pto_request"))
      this.endDatePicker.show()
      this.hoursDiv().empty()
      return false
    }
    return true
  }

  endTimeAfterStartTime() {
    let valid = true
    if (this.dayIncrements) return valid
    if (this.singleDayRequest() && this.endTime() < this.startTime()) {
      valid = false
      this.showError("end_time_after_start_time".t("profile", "pto_request"))
      this.$endTime.focus()
    }
    return valid
  }

  missingTime() {
    if (this.$startTime.val() === "" || this.$endTime.val() === "") {
      this.showError("start_and_end_time_required".t("profile", "pto_request"))

      if (this.$startTime.val() === "") {
        this.$startTime.focus()
      } else {
        this.$endTime.focus()
      }

      return false
    }
    return true
  }

  initStartAndEndTime() {
    const startDate = this.startDatePicker.getDate()
    if (!startDate) return

    let workDayTime = this.personSchedule.getWorkdayTimes(startDate.getDay())
    let startTime = workDayTime.startTime

    const endDate = this.endDatePicker.getDate()
    workDayTime = this.personSchedule.getWorkdayTimes(endDate.getDay())
    let endTime = workDayTime.endTime

    const overnight = workDayTime.startTime > workDayTime.endTime

    if (this.overtimePolicy) {
      if (overnight) {
        endTime = startTime
        startTime -= 1
      } else {
        startTime = endTime
        endTime += 1
      }
    } else if (this.$startDate.val() === this.$endDate.val() && overnight) {
      endTime = 24.0
    }

    if (this.interval === "one_minute" || this.overtimePolicy) {
      startTime = moment(startDate).add(startTime, "hours").format("hh:mm a")
      endTime = moment(endDate).add(endTime, "hours").format("hh:mm a")
    } else {
      if (Number.isInteger(startTime)) startTime = startTime.toFixed(1)
      if (Number.isInteger(endTime)) endTime = endTime.toFixed(1)
    }

    this.$startTime.val(startTime)
    this.$endTime.val(endTime)
  }

  updateHalfDayOptions() {
    if (this.singleDayRequest()) {
      this.$startTime.find("[value=first_half]").show()
      this.$endTime.parents(".col").hide()
    } else {
      this.$startTime.find("[value=first_half]").hide()
      this.$endTime.parents(".col").show()
    }
  }

  startTime() {
    if (this.interval === "one_minute") {
      const midnight = moment().startOf("day")
      const startTime = moment(this.$startTime.val(), "hh:mm a")
      return startTime.diff(midnight, "minutes") / 60.0
    }
    if (this.dayIncrements) {
      return this.$startTime.val()
    }

    return parseFloat(this.$startTime.val())
  }

  endTime() {
    if (this.interval === "one_minute") {
      const midnight = moment().startOf("day")
      const endTime = moment(this.$endTime.val(), "hh:mm a")
      return endTime.diff(midnight, "minutes") / 60.0
    }
    if (this.dayIncrements) {
      return this.$endTime.val()
    }

    return parseFloat(this.$endTime.val())
  }

  calculateHours() {
    this.$form.find(".date-row").removeClass("hidden")
    this.hoursDiv().html(TimeOffValidator.loader())

    const formData = {
      start_date: this.startDatePicker.toString(),
      start_time: this.startTime(),
      end_date: this.endDatePicker.toString(),
      end_time: this.endTime(),
    }

    if (Object.values(formData).length === 4) {
      if (this.calculateHoursRequest) this.calculateHoursRequest.abort()
      this.calculateHoursRequest = $.get({
        url: this.hoursUrl,
        data: formData,
      }).done((data) => {
        this.$form.find(Pjax.targetContainer("calculate-hours")).html(data)
        this.calculateHoursRequest = null
        const validationError = $(data).filter("#pto-validation-error")
        if (validationError.length > 0) {
          this.showError(validationError.data("error-message"))
        } else {
          this.clearError()
        }
      })
    }
  }

  checkDateAndTime() {
    if (this.startDatePicker.isVisible() || this.endDatePicker.isVisible()) {
      return
    }

    if (this.newRequest) {
      if (this.$startDate.val().length && this.$endDate.val().length === 0) {
        this.endDatePicker.setMoment(moment(this.$startDate.val()), true)
      }

      if (this.interval !== "half_day" && this.$form.find("#pto-times").hasClass("hidden")) {
        this.initStartAndEndTime()
      }

      if (this.interval !== "full_day" && this.$form.find("#pto-times").hasClass("hidden")) {
        this.$form.find("#pto-times").removeClass("hidden")
      }
    }

    if (this.interval === "half_day" && !this.$form.find("#pto-times").hasClass("hidden")) {
      this.updateHalfDayOptions()
    }

    if (this.interval === "one_minute" && !this.missingTime()) return
    if (this.invalidDateEntries()) return
    if (!this.endTimeAfterStartTime()) return
    this.calculateHours()
  }
}

window.TimeOffValidator = TimeOffValidator
