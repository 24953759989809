/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
const { Subform } = window

$(document).on("append-subform", function (_e, item, type) {
  if ($(item).is(Subform.selector("link"))) {
    const constants = {
      $item: $(item),
      $linkContainer: $(item).closest(".link-container"),
      $showLinkTemplate: $("#show-link-template"),
      $fileLinkTemplate: $("#form-link-template"),
      $linkTitle() {
        return $(item).find('[name="benefit_plan[links][][title]"]').last()
      },
      $linkUrl() {
        return $(item).find('[name="benefit_plan[links][][url]"]').last()
      },
      $linkUpdatedAt() {
        return $(item).find('[name="benefit_plan[links][][updated_at]"]')
      },
      $listGroupItem() {
        return $(item).find(".list-group-item")
      },
    }

    var functions = {
      removeSubformHandlers() {
        constants.$item.off("subform:close")
        return constants.$item.off("subform:save")
      },

      initSubform() {
        constants.$item.on("subform:close", functions.close)
        return constants.$item.on("subform:save", functions.submit)
      },

      close() {
        if (constants.$item.hasClass("new")) {
          constants.$item.remove()
          functions.showBtn()
          return constants.$item.removeClass("new")
        } else {
          constants.$listGroupItem().empty()
          const title = constants.$linkTitle().val()
          const url = constants.$linkUrl().val()
          constants
            .$listGroupItem()
            .append(_.template(constants.$showLinkTemplate.html())({ title, url }))

          const $dropdownLinks = constants.$item.find(".dropdown .dropdown-link")
          $dropdownLinks.each(function () {
            return $(this).on("click", (e) => DropdownMenu.handleClick(e))
          })
          DropdownMenu.handleVisibility(constants.$item.closest(".modal"))
          constants.$item.find("[data-action=edit-link]").on("click", functions.edit)
          constants.$item.find("[data-action=delete-link]").on("click", functions.delete)

          return functions.removeSubformHandlers()
        }
      },

      showBtn() {
        return constants.$linkContainer.find(".add-btn").show()
      },

      hideBtn() {
        return constants.$linkContainer.find(".add-btn").hide()
      },

      edit() {
        const title = constants.$linkTitle().val()
        const url = constants.$linkUrl().val()
        constants.$listGroupItem().empty()
        constants.$listGroupItem().append(_.template(constants.$fileLinkTemplate.html()))
        constants.$linkTitle().val(title)
        constants.$linkUrl().val(url)
        functions.initSubform()
        return constants.$item.trigger("subform:open")
      },

      delete() {
        return constants.$item.remove()
      },

      submit() {
        const title = constants.$linkTitle().val()
        const url = constants.$linkUrl().val()
        constants.$listGroupItem().empty()
        constants
          .$listGroupItem()
          .append(_.template(constants.$showLinkTemplate.html())({ title, url }))
        constants.$linkTitle().val(title)
        constants.$linkUrl().val(url)
        constants.$linkUpdatedAt().val(moment().format("M/DD/YYYY"))

        const $dropdownLinks = constants.$item.find(".dropdown .dropdown-link")
        $dropdownLinks.each(function () {
          return $(this).on("click", (e) => DropdownMenu.handleClick(e))
        })
        DropdownMenu.handleVisibility(constants.$item.closest(".modal"))
        constants.$item.find("[data-action=edit-link]").on("click", functions.edit)
        constants.$item.find("[data-action=delete-link]").on("click", functions.delete)

        functions.showBtn()
        constants.$item.removeClass("new")
        return functions.removeSubformHandlers()
      },
    }

    functions.initSubform()
    if (type === "new") {
      functions.hideBtn()
      constants.$item.addClass("new")
      return constants.$item.trigger("subform:open")
    } else if (type === "exist") {
      constants.$item.find("[data-action=edit-link]").on("click", functions.edit)
      return constants.$item.find("[data-action=delete-link]").on("click", functions.delete)
    }
  }
})
