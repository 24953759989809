/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount("form[id*='profile-settings']", function () {
  const constants = {
    $this: $(this),
    $enabled: $(this).find('[name="company[settings][profile_change_notifications][enabled]"]'),
    $recipientsItem: $(this).find(
      '[name="company[settings][profile_change_notifications][recipients][]"]',
    ),
    $recipients: $(this).find(".recipients"),
  }

  const functions = {
    toggleEnabled() {
      const enabled =
        constants.$this
          .find('[name="company[settings][profile_change_notifications][enabled]"]:checked')
          .val() === "true"
      if (enabled) {
        constants.$recipients.removeClass("disabled")
        return constants.$recipientsItem.prop("disabled", false)
      } else {
        constants.$recipientsItem.prop("checked", false)
        constants.$recipientsItem.prop("disabled", true)
        return constants.$recipients.addClass("disabled")
      }
    },
  }

  return constants.$enabled.on("change", functions.toggleEnabled)
})
