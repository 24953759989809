/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount("form.new_pto_type", function () {
  const functions = {
    setAllBalances() {
      const globalBalance = $("#global-value").val()
      const $balanceFields = $("[name$=\\[balance\\]]")

      return $balanceFields.val(globalBalance)
    },
  }

  return $(this).find("[data-action=set]").on("click", functions.setAllBalances)
})
