import { createPopper } from "@popperjs/core"
import * as d3 from "d3"

const { $ } = window

/**
 * Takes d3 selection of the div with the data-use-popper-bar-tooltip attr
 * Remounts the tooltip to the outermost widget div
 * Creates a popper tooltip
 * @param {*} selection
 * @returns
 */
const createBarTooltip = (selection) => {
  const tooltipId = selection.attr("data-use-popper-bar-tooltip")
  let tooltipDiv = selection.select(tooltipId)

  if (tooltipDiv.empty()) {
    tooltipDiv = d3.select(tooltipId)
  } else {
    // Remove element if it exists in new parent
    const newParent = $(tooltipId).parents(".modal, .content")
    newParent.children(tooltipId).remove()
    // Tooltip needs to be moved outside of the widget so that it doesn't get cut off
    $(tooltipId).appendTo(newParent)
  }

  const popper = createPopper(selection.node(), tooltipDiv.node(), {
    placement: "left-start",
    modifiers: [
      {
        name: "offset",
        options: { offset: [0, 5] },
      },
      {
        name: "flip",
        options: {
          fallbackPlacements: [
            "left",
            "left-end",
            "right",
            "right-start",
            "right-end",
            "top",
            "top-start",
            "top-end",
            "bottom",
            "bottom-start",
            "bottom-end",
          ],
        },
      },
    ],
  })

  return { tooltipDiv, popper }
}

export default createBarTooltip
