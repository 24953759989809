import { scaleLinear, select } from "d3"
import { defaults, isNil, pick } from "lodash"

import pickChartOptionsOfNode from "../utils/pickChartOptionsOfNode"

const OptionKeys = Object.freeze(["disableAnimation", "entries", "total"])
const COLOR_NEUTRAL_8_SOLID = "rgb(237, 237, 242)"
const COLOR_PRIMARY_80_SOLID = "rgb(072, 129, 255)"

/** @private */
function configure(node, options) {
  const base = defaults(pick(options, OptionKeys), pickChartOptionsOfNode(node))

  // Simple index, only exposed via the getter and setter. Getter returns a copy
  // so calls that mutate don't mess with the internal state.
  //
  // Keys are chart section IDs. Values are array of children IDs.
  //
  //     { 1: [2, 3], 4: [5, 6], ... }
  const indexOfChildrenIds = {}
  const getEntryInternal = (parentId) => indexOfChildrenIds[parentId] || []

  return {
    ...base,
    childrenIdsIndex: {
      appendTo: (parentId, id) => {
        if (isNil(id)) {
          return
        }

        indexOfChildrenIds[parentId] = getEntryInternal(parentId)
        indexOfChildrenIds[parentId].push(id)
      },
      getChildrenIdsFor: (parentId) => [...getEntryInternal(parentId)],
      hasEntryFor: (parentId) => getEntryInternal(parentId).length > 0,
    },
    scaleWidthByMaxTotal: scaleLinear().domain([0, base.total]).range([0, 100]),
  }
}

/** @private */
function appendRow(
  selection,
  config,
  { children, depth, id, label, filled, open, parent_id: parentId, total },
) {
  const { childrenIdsIndex, scaleWidthByMaxTotal } = config
  childrenIdsIndex.appendTo(parentId, id)

  // When the entry has children, we'll subtract 0.75 and prepend an icon that
  // takes the space. We don't use the children index at this point since we
  // won't have traversed descendant nodes yet.
  const hasChildren = (children || []).length > 0
  const padLeft = hasChildren ? ((depth || 0) + 1) * 0.75 - 0.75 : ((depth || 0) + 1) * 0.75

  // Capture a reference to the left-most column. Since we use CSS grid, we rely
  // on knowing the column size of the row (for styling purposes, and so on).
  // Maybe there's a better way?
  const lhsColumn = selection
    .append("dt")
    .attr("class", "f-11 flex widget-chart-expander stacked-row")
    .style("width", "12rem")
    .attr("id", `stacked-label-${id}`)

  lhsColumn
    .classed("show", !parentId)
    .classed("hide", parentId)
    .append("span")
    .style("padding-left", `${padLeft}rem`)

  if (hasChildren) {
    lhsColumn
      .append("span.flex.items-center.justify-center")
      .style("width", "0.75rem")
      .append("i.far.fa-chevron-right")
      .style("font-size", "0.5rem")
  }

  lhsColumn.append("span").style("padding-right", "3rem").text(label)

  selection
    .append("dd")
    .attr("class", "f-11 justify-end text-right")
    .attr("data-part", "chart-left-label")
    .text(open)

  const selectionOfMinBarMaxContainer = selection.append("dd").attr("class", "flex")

  selection
    .append("dd")
    .attr("class", "f-11")
    .style("text-align", "right")
    .style("font-weight", "bold")
    .style("padding-left", "3rem")
    .style("padding-right", "0.75rem")
    .attr("data-part", "chart-total")
    .text(total)

  // Populate the chart container with the chart itself, *and* a count of the
  // filled positions.
  const selectionOfBarContainer = selectionOfMinBarMaxContainer
    .append("div")
    .attr("data-part", "bar-wrapper")
    .style("display", "flex")
    .style("flex-direction", "row-reverse")
    .style("overflow", "hidden")
    .style("width", `${scaleWidthByMaxTotal(total)}%`)
    .style("height", "0.75rem")
    .style("margin-left", "0.25rem")
    .style("margin-right", "0.25rem")
    .style("border-radius", "0.25rem")
    .style("background-color", COLOR_NEUTRAL_8_SOLID)

  selectionOfBarContainer
    .append("div")
    .style(
      "width",
      `${
        // Percentage of bar (0 - 100) to color in.
        scaleLinear().domain([0, total]).range([0, 100])(filled)
      }%`,
    )
    .style("height", "0.75rem")
    .style("background-color", COLOR_PRIMARY_80_SOLID)

  selectionOfMinBarMaxContainer.append("span").attr("class", "f-11").text(filled)

  // eslint-disable-next-line no-use-before-define
  selection.call(bindAndAppendRows, { ...config, entries: children || [] })

  if (!hasChildren) {
    return
  }

  lhsColumn.on("click", () => {
    const willExpand = !lhsColumn.classed("expanded")
    lhsColumn.classed("expanded", willExpand)

    const nextNodes = childrenIdsIndex.getChildrenIdsFor(id)
    while (nextNodes.length > 0) {
      // Consume the next node id. Toggle its visibility based on whether we're
      // expanding or collapsing. When collapsing, further descends the tree to
      // clean up nested descendants.
      //
      // This is necessary since we render this as a flat list in the DOM, and
      // grand-children (and on) may still show otherwise.
      const id = nextNodes.shift()
      selection
        .select(`#stacked-label-${id}`)
        .classed("show", willExpand)
        .classed("hide", !willExpand)

      if (!willExpand && childrenIdsIndex.hasEntryFor(id)) {
        selection.select(`#stacked-label-${id}`).classed("expanded", false)

        nextNodes.push(...childrenIdsIndex.getChildrenIdsFor(id))
      }
    }
  })
}

/** @private */
function bindAndAppendRows(selection, { entries, ...config }) {
  selection
    .selectAll(null)
    .data(entries)
    .enter()
    .each((entry, index, nodes) =>
      select(nodes[index]).call(appendRow, { ...config, entries }, entry),
    )
}

/** @private */
function maybeTransitionBars(selection, { disableAnimation }) {
  if (disableAnimation) {
    return
  }

  selection.selectAll("[data-part=bar-wrapper]").each((_entry, index, nodes) => {
    const selectionOfBar = select(nodes[index])
    const width = select(nodes[index]).style("width")

    selectionOfBar.style("width", "0%").transition().duration(500).style("width", width)
  })
}

/**
 * Mounts a horizontal bar chart, as a table, on the node or the first match
 * of the selector.
 *
 * For now, the only option that you can provide is `entries`.
 *
 *     StackedHorizontalBarChart('#my-chart', {
 *       entries: [
 *         { label: 'HR', total: 8, open: 3, filled: 5 },
 *         { label: 'Dev', total: 5, open: 1, filled: 4 },
 *       ]
 *     })
 *
 * That will render a chart that looks a little like:
 *
 *         |   +---+-----+     |
 *     HR  | 3 |   |     | 5   | 8
 *         |   +---+-----+     |
 *         |   +-+----+        |
 *     Dev | 1 | |    | 4      | 5
 *         |   +-+----+        |
 *
 * @param {string | HTMLElement | Node} nodeOrSelector
 * @param {object} options
 * @returns {d3.Selection} The selection wrapping your node, or the node we
 *   matched to your selector.
 */
function StackedHorizontalBarChart(nodeOrSelector, options = {}) {
  const selection = select(nodeOrSelector)
  const config = configure(selection.node(), options)

  return selection
    .selectAll("dl.widget-grid.widget-grid--col4.mt-0")
    .data([null])
    .enter()
    .append("dl")
    .attr("class", "widget-grid widget-grid--col4 mt-0")
    .call(bindAndAppendRows, config)
    .call(maybeTransitionBars, config)
}

export default StackedHorizontalBarChart
