const $ = window.jQuery
const Pjax = window.Pjax

function reload(url) {
  $.pjax.reload(Pjax.targetContainer("jobs-show"), {
    url: `${url}`,
    fragment: Pjax.targetContainer("jobs-show"),
  })
}

function submitForm(e) {
  const action = e.currentTarget.dataset.action
  const jobId = e.currentTarget.dataset.jobId
  const $form = $(e.currentTarget).parent()

  $(".next-stage-btn").attr("disabled", true)
  $("[data-action=disqualify]").attr("disabled", true)
  $("[data-action=create-vote]").attr("disabled", true)
  $("[data-action=update-vote]").attr("disabled", true)

  $.ajax({
    type: "PUT",
    url: $form.attr("action"),
    data: $form.serialize(),
    success: function () {
      switch (action) {
        case "disqualify":
          const nextApplicantId = JobApplication.nextApplicantId()
          const stageId = e.currentTarget.dataset.stageId

          if (nextApplicantId !== null) {
            reload(`/jobs/${jobId}/applicants/${nextApplicantId}`)
          } else if (stageId === "null") {
            reload(`/jobs/${jobId}`)
          } else {
            reload(`/jobs/${jobId}/stages/${stageId}`)
          }
          break

        case "move-applicant":
          const applicationId = e.currentTarget.dataset.applicationId

          reload(`/jobs/${jobId}/applicants/${applicationId}`)
          break

        default:
          break
      }

      return false
    },
  })
}

function addEventListeners() {
  $("[data-action=disqualify]").click(submitForm)
  $("[data-action=move-applicant]").click(submitForm)
}

function enableStageBtns() {
  $(".next-stage-btn").attr("disabled", false)
  $("[data-action=create-vote]").attr("disabled", false)
  $("[data-action=update-vote]").attr("disabled", false)
  $("[data-action=disqualify]").attr("disabled", false)
}

$.onmount(".recruiting-header-actions", () => {
  addEventListeners()
})

$(document).ready(enableStageBtns)
$(document).on("pjax:end", enableStageBtns)

class JobApplication {
  static nextApplicantId() {
    const applicantInList = $(".applicants-list .active")
    const nextApplicant = applicantInList.next()
    if (nextApplicant.length > 0) {
      return nextApplicant.data().id
    }

    return null
  }
}

window.JobApplcation = JobApplication
