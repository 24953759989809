/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount(Modal.selector("person-set-default-email"), function () {
  var constants = {
    $modal: $(this),
    $provideEmail: $(this).find("button[data-action=provide-email]"),
    $provideEmailSelect: $(this).find("#provide-email-select"),
    $saveBtn: $(this).find("[data-action=save]"),
    $emailValue() {
      return constants.$modal.find("[name='person[work_email]'], [name='person[personal_email]']")
    },
  }

  var functions = {
    emailTypeChanged() {
      const selectedInput = $(this).val()
      const inputName = `person[${selectedInput}_email]`
      const inputId = `person_${selectedInput}_email`
      return constants.$emailValue().attr("name", inputName).attr("id", inputId)
    },

    provideEmailClick() {
      constants.$provideEmailSelect.removeClass("hidden")
      constants.$provideEmail.remove()
      constants.$emailValue().focus()
      constants.$saveBtn.removeClass("disabled")
      return constants.$saveBtn.attr("disabled", false)
    },

    modalSave(_e, modal) {
      functions.loadNextModal(modal)
      functions.reloadProfileHeader(modal)
      return functions.reloadPersonContactInfo()
    },

    loadNextModal(modal) {
      $.pjax({
        url: modal.data.modalAfterUrl,
        container: Pjax.targetContainer("modal"),
        push: false,
        scrollTo: false,
      })
      return ($.pjax.xhr = null)
    },

    reloadProfileHeader(modal) {
      Profile.reloadProfileHeader(modal.$form)
      return ($.pjax.xhr = null)
    },

    reloadPersonContactInfo() {
      let containerSelector = Pjax.targetContainer("person-work-contact-info")
      if ($(containerSelector).length === 0) {
        containerSelector = Pjax.targetContainer("person-contact-info")
      }

      if ($(containerSelector).length > 0) {
        $.pjax.reload(containerSelector, { fragment: containerSelector })
        return ($.pjax.xhr = null)
      }
    },
  }

  $(this).on("change", "[name='person[email_type]']", functions.emailTypeChanged)
  $(this).on("click", "[data-action=provide-email]", functions.provideEmailClick)
  return $(this).on("modal:save", functions.modalSave)
})
