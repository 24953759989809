/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount("form.pto-type-form", function () {
  const functions = {
    toggleApproverTypeLabel() {
      const $approverTypeLabel = $(".approver-type-label")
      const $chartIdLabel = $(".chart-id-label")
      if ($(this).val() === "true") {
        $approverTypeLabel.text($approverTypeLabel.data().requests)
        return $chartIdLabel.text($chartIdLabel.data().requests)
      } else {
        $approverTypeLabel.text($approverTypeLabel.data().notifications)
        return $chartIdLabel.text($chartIdLabel.data().notifications)
      }
    },
    toggleChartVisibility() {
      if ($("#pto_type_approver_type").val() === "manager") {
        return $("#chart-select").slideDown("normal")
      } else {
        return $("#chart-select").slideUp("normal")
      }
    },
  }

  const $datepicker = $(this).find("[data-widget=monthdaypicker]")

  this.picker = new Pikaday({
    field: $datepicker[0],
    format: "MMM DD",
    defaultDate: $datepicker.val(),
    setDefaultDate: true,
    theme: "hide-year",
  })

  $datepicker.prop("picker", this.picker)

  $('[name="pto_type[approval_required]"]').on("change", functions.toggleApproverTypeLabel)
  return $('[name="pto_type[approver_type]"]').on("change", functions.toggleChartVisibility)
})
