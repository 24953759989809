/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$(document).ready(function () {
  // CSS3 Animation bug fix
  $("body").removeClass("preload")

  // Initialize onmount
  return $.onmount()
})
