/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$.onmount("form[id=new_company_date]", function () {
  const $form = $(this)
  const $submitBtn = $form.find("input[type=submit]")

  const functions = {
    typeChanged() {
      if ($form.find("#company_date_type_ptoblackoutday")[0].checked) {
        return $submitBtn.val($submitBtn.data().blackout)
      } else {
        return $submitBtn.val($submitBtn.data().holiday)
      }
    },
  }

  return $form.find('[name="company_date[type]"]').on("change", functions.typeChanged)
})
