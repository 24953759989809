const $ = window.jQuery
const Modal = window.Modal
const Pjax = window.Pjax

function disableSubmitButton(e) {
  const title = $(":input[name='job[title]']").val()
  const description = $(":input[name='job[description]']").val()

  if (title.length === 0 || description.length === 0) {
    e.preventDefault()
    $(".form-error-messages").addClass("active")
  }
}

$.onmount("[data-action='next_step']", function () {
  $("[data-action=close]").click(window.currentModal.close)
  $(".form-error-messages").removeClass("active")
  $(".modal").removeClass("modal-lg").addClass("modal-md")

  $(this).click((event) => {
    const step = $("input[name='job[step]']")

    if (step.val() === "3") {
      step.val(null)
    }

    if ($(event.currentTarget).data().step === 1) {
      disableSubmitButton(event)
    }
  })
})

$.onmount("[data-action='change-step']", function () {
  $(this).click((event) => {
    const currentStep = parseInt($(".job-post-stage.active")[0].dataset.step, 10)
    const targetStep = parseInt(event.target.dataset.step, 10)

    if (currentStep === targetStep || currentStep < targetStep) {
      return
    }

    $(".previous-job").eq(0).show()
    $(".posting-info-wrapper").eq(0).show()
    $(".posting-settings-wrapper").eq(0).show()
    $(".define-workflow-wrapper").eq(0).hide()

    $(".job-post-stage.active").eq(0).removeClass("active")
    $("[data-step=1]").eq(0).addClass("active")

    $("input.btn--large.btn--primary").val("Next: Define Workflow")
    $("input[name='job[step]']").val("1")
  })
})

$.onmount("[data-action='save']", function () {
  if ($("[data-step]").data("step") !== 3) {
    return null
  }

  $(".modal").removeClass("modal-md").addClass("modal-lg")
  $(this).click(() => {
    const save = $("input[name='job[for_save]']")

    if (save) {
      save.val(true)

      $(this.form).attr("data-action", null)
    }
  })
})

function toggleEditor(editor, valueFor) {
  let newIcon
  let oldIcon

  switch (editor.hasClass("remove")) {
    case true: {
      $(`#wrapper-${valueFor}`).hide()
      $(`[data-toggle-wrapper=${valueFor}]`).val("")
      editor.find("span").text("Add")
      newIcon = "fa-plus"
      oldIcon = "fa-times"
      break
    }
    case false: {
      editor.find("span").text("Remove")
      newIcon = "fa-times"
      oldIcon = "fa-plus"
      $(`#wrapper-${valueFor}`).show()
      break
    }
    default: {
      break
    }
  }
  editor.toggleClass("remove add")
  editor.find(`.${oldIcon}`).hide()
  editor.find(`.${newIcon}`).show()
}

function resolveUrl(action, currentJobId, previousJobId) {
  switch (action) {
    case "new":
      return `/jobs/new?previous_job_id=${previousJobId}`
    case "create":
      return `/jobs/new?previous_job_id=${previousJobId}`
    case "edit":
      return `/jobs/${currentJobId}/edit?previous_job_id=${previousJobId}`
    case "update":
      return `/jobs/${currentJobId}/edit?previous_job_id=${previousJobId}`
    default: {
      return ""
    }
  }
}

$.onmount("select[name='copy_job']", function () {
  $(this).change(function () {
    const actionName = this.dataset.actionName
    const currentJobId = $("input#job_job_id").val()
    const previousJobId = parseInt($(this).find(":selected").val(), 10)

    return $.pjax({
      url: resolveUrl(actionName, currentJobId, previousJobId),
      container: Pjax.targetContainer("modal-body"),
      fragment: Pjax.targetContainer("modal-body"),
      data: "refresh-data",
      push: false,
    })
  })
})

$.onmount("[data-action='toggle-editor']", function () {
  $(this).click(function () {
    const editor = $(this)
    const valueFor = editor.parents("label").attr("for")
    toggleEditor(editor, valueFor)
  })
  $(".far.hidden").hide()
})

$.onmount(Modal.selector("job-create"), function () {
  $(this).on("modal:save", (e, modal) => {
    const containerName = modal.$el.data("modal-reload")
    const reloadURL = $(".modal form").data("reload-url")
    const containerSelector = Pjax.targetContainer(containerName)

    if (reloadURL) {
      return $.pjax({
        url: reloadURL,
        container: containerSelector,
        replace: true,
      })
    }

    return $.pjax.reload(containerSelector, {
      fragment: containerSelector,
    })
  })
})
