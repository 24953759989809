const $ = window.jQuery
const Modal = window.Modal
const PhoneNumbers = window.PhoneNumbers
const Pjax = window.Pjax

$.onmount(Modal.selector("job-application-edit"), function () {
  PhoneNumbers.initIntlTelInputs(this)

  $(this).on("modal:save", (_e, modal) => {
    const containerName = modal.$el.data("modal-reload")
    const reloadURL = $(".modal form").data("reload-url")
    const containerSelector = Pjax.targetContainer(containerName)

    if (reloadURL) {
      return $.pjax({
        url: reloadURL,
        container: containerSelector,
        replace: true,
      })
    }

    return $.pjax.reload(containerSelector, {
      fragment: containerSelector,
    })
  })

  $(this).on("pjax:end", () => {
    const modal = $(Modal.selector("job-application-edit"))
    if (modal.find(".form-error, .form-error-message").length) {
      PhoneNumbers.initIntlTelInputs(modal)
    }
  })
})
