/**
 * Returns the selection if it wraps a SVG; otherwise, appends a SVG to the
 * selection and returns a new selector wrapping that SVG.
 *
 * @param {d3.Selection} selection
 * @param {object} config Chart config; must hold height and width.
 * @returns {d3.Selection} A selection wrapping a single SVG element.
 */
function useOrAppendSVG(selection, config) {
  if ((selection.node() || {}).tagName === "svg") {
    return selection
  }

  return selection.append("svg").attr("height", config.height).attr("width", config.width)
}

export default useOrAppendSVG
