import { curry, flow, lowerFirst, mapKeys, mapValues, pickBy } from "lodash"

import parseIfNonEmptyOrReturn from "./parseIfNonEmptyOrReturn"

/**
 * Picks all chart data attributes from a dataset, removes the `chart` prefix,
 * and attempts to parse each value.
 *
 * @example
 *
 *     <div
 *       id="chart"
 *       data-use-chart="SomeChart"
 *       data-chart-colors="[\"red\", \"blue\"]"
 *       data-chart-debug="true"
 *       data-chart-margins="{ \"left\": 33 }"
 *       data-different-namespace-id="33">
 *     </div>
 *
 *     pickChartOptionsOfNode(document.getElementById('#chart'));
 *     //=> { colors: ['red', 'blue'], debug: true, margins: { left: 33 } }
 *
 * @param {HTMLElement | Node} node Individual element or node to pull data
 *   attributes from.
 * @returns {object} A plain object holding all `data-chart-*` attributes in
 *   camel-case form.
 */
function pickChartOptionsOfNode(node) {
  const dataset = (node && node.dataset) || {}
  const _ = curry.placeholder
  const hasChartPrefix = (_val, key) => String(key).startsWith("chart")
  const toNormalizedKey = (_val, key) => lowerFirst(key.replace(/^chart/, ""))
  const tryParseOrReturnString = (val) => {
    try {
      return parseIfNonEmptyOrReturn(val, val)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(`Failed parsing ${val}, returning as a string.`, err)
      return val
    }
  }

  return flow(
    curry(pickBy)(_, hasChartPrefix),
    curry(mapKeys)(_, toNormalizedKey),
    curry(mapValues)(_, tryParseOrReturnString),
  )(dataset)
}

export default pickChartOptionsOfNode
