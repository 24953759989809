import * as d3 from "d3"

import parseLabel from "../widgets/positions/utils/parse_label"

function legendList(data, element, props) {
  // Select and clear element to mount legend list
  const legendSelection = d3.select(element)
  legendSelection.selectAll("*").remove()
  const scrollBarWidth = 17

  const { color } = props
  const categories = data.Z

  const legend = legendSelection
    .append("dl")
    .style("overflow-x", "hidden")
    .attr("class", "widget-grid")

  const legendWidth = legend.node().getBoundingClientRect().width - scrollBarWidth

  const listRow = legend
    .selectAll("dt")
    .data(categories)
    .join("dt")
    .style("width", `${legendWidth}px`)
    .style("border", "none")

  listRow
    .append("div")
    .attr("class", "widget-legend-item-color")
    .style("background-color", (d) => color(d))
  listRow
    .append("div")
    .attr("class", "grow")
    .style("overflow", "hidden")
    .style("white-space", "nowrap")
    .style("text-overflow", "ellipsis")
    .text((d) => parseLabel(d))
}

export default legendList
