/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// Relies on cropbox.js.  See https://github.com/hongkhanh/cropbox
class EditImageModal extends Modal {
  constructor(el) {
    super(el)
    this.close = this.close.bind(this)
    this.renderImageEditor = this.renderImageEditor.bind(this)
    this.rotateLeft = this.rotateLeft.bind(this)
    this.rotateRight = this.rotateRight.bind(this)
    this.zoomIn = this.zoomIn.bind(this)
    this.zoomOut = this.zoomOut.bind(this)
    this.save = this.save.bind(this)
    this.$uploaderForm = $(`[data-uploader=${this.$el.data("modal-image-uploader")}] form`)
    this.renderImageEditor()
  }

  addEvents() {
    super.addEvents()

    this.$el.find("[data-action=rotate-left]").click((e) => this.rotateLeft(e))
    this.$el.find("[data-action=rotate-right]").click((e) => this.rotateRight(e))
    this.$el.find("[data-action=zoom-in]").click((e) => this.zoomIn(e))
    this.$el.find("[data-action=zoom-out]").click((e) => this.zoomOut(e))
    return this.$el.find("[data-action=upload-image]").click((e) => this.save(e))
  }

  open() {
    $("body").append(this.$overlay)
    $("body").addClass("overflow-hidden")
    $("body").addClass("modal-active")
    this.addEvents()
    this.$overlay.fadeIn(() => {
      return this.$el.trigger("modal:open")
    })
    return this
  }

  close(e) {
    super.close(e)
    $("body").removeClass("overflow-hidden")
    this.$uploaderForm[0].reset()
    return this
  }

  renderImageEditor() {
    const imageBlob = this.$uploaderForm.find("[type=file]")[0].files[0]

    const reader = new FileReader()
    reader.onload = (e) => {
      const image = this.$el.find("#cropper-image")[0]
      image.src = e.currentTarget.result

      return (this.cropper = new Cropper(image, { aspectRatio: 1, autoCropArea: 1 }))
    }

    return reader.readAsDataURL(imageBlob)
  }

  rotateLeft() {
    return this.cropper.rotate(-90)
  }

  rotateRight() {
    return this.cropper.rotate(90)
  }

  zoomIn() {
    return this.cropper.zoom(0.1)
  }

  zoomOut() {
    return this.cropper.zoom(-0.1)
  }

  save(e) {
    e.preventDefault()

    const cropped_canvas = this.cropper.getCroppedCanvas()
    cropped_canvas.toBlob((blob) => {
      return $.when(this.$el.trigger("image:set", blob, cropped_canvas.toDataURL())).done(() =>
        this.close(),
      )
    })
    return this
  }
}

window.EditImageModal = EditImageModal

$.onmount("[data-modal-image]", function () {
  return new EditImageModal(this)
})
